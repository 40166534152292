<div class="modal-header">
  <h4 class="modal-title">Claims for job {{jobNumber}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>
  <!-- <dx-load-indicator *ngIf="loading" id="large-indicator" height="80" [width]="80" class="m-auto"></dx-load-indicator> -->

  <button *ngIf="!loading && !claimsExist" class="btn btn-primary mb-1" (click)="openGenerateModal()">Generate
    Claims</button>

  <div *ngIf="!loading" class="m-auto">
    <dx-data-grid [dataSource]="dataSource" columnResizingMode="widget" [allowColumnReordering]="false" [allowColumnResizing]="true"
      [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
      (onToolbarPreparing)="gridService.addRefreshBtnToToolbar($event)" (onInitNewRow)="initNewRow($event)"
      (onEditorPreparing)="editorPreparing($event)">

      <dxo-sorting mode="none"></dxo-sorting>
      <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-editing mode="row" [allowAdding]="jobsAdminPermission" [allowUpdating]="jobsAdminPermission"
        [allowDeleting]="jobsAdminPermission" [useIcons]="true" refreshMode="full">
      </dxo-editing>
      <dxo-row-dragging [allowReordering]="true" [onReorder]="reorder"></dxo-row-dragging>
      <dxo-paging [enabled]="false"></dxo-paging>

      <div *dxTemplate="let data of 'refreshTemplate'">
        <div class="m-l-10">
          <dx-button icon="refresh" (onClick)="refresh()"></dx-button>
        </div>
      </div>

      <dxi-column dataField="description" caption="Claim" Width="150">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="claimTriggerId" caption="Trigger Type" dataType="number" Width="140" [setCellValue]="setTriggerCellValue">
        <dxo-lookup [dataSource]="claimTriggers" valueExpr="id" displayExpr="description">
        </dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="companyActivityId" caption="Activity" Width="130">
        <dxo-lookup [dataSource]="activities" valueExpr="id" displayExpr="description">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="raiseAtDate" dataType="date" [width]="100" caption="Date" 
        [setCellValue]="setDateCellValue" [allowEditing]="true" alignment="center" format="d-M-yy">
      </dxi-column>

      <dxi-column *ngIf="lenderInformationEnabled" dataField="invoice" Width="130"
        [calculateCellValue]="calculateInvoiceToValue" [setCellValue]="setInvoiceToCellValue">
        <dxo-lookup [dataSource]="invoiceTos" valueExpr="description" displayExpr="description"> </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="percent" dataType="number" [width]="50" caption="%" [setCellValue]="setPercentCellValue"
        [calculateCellValue]="calculatePercentValue" format="#.#" alignment="center">
      </dxi-column>

      <dxi-column dataField="amount" dataType="number" [width]="115" format="#,##0.00"
        [setCellValue]="setAmountCellValue">
      </dxi-column>

      <dxi-column dataField="adjustedAmount" dataType="number" caption="Amount + Variation Splits" [width]="115"
        [calculateCellValue]='calculateAdjustedAmount' [allowEditing]="false" format="#,##0.00">
      </dxi-column>

      <dxi-column dataField="daysToPay" caption="Days to Pay (Leave blank for default)" dataType="number" width="130"
        alignment="center">
      </dxi-column>

      <dxi-column *ngIf="isPurchaseOrdersActive" dataField="dateInvoiced" dataType="date" [width]="90"
        [allowEditing]="false" alignment="center" format="d-MMM-yy" [calculateCellValue]='calcInvoiceDate'>
      </dxi-column>

      <dxi-column *ngIf="isPurchaseOrdersActive" dataField="totalPaid" dataType="number" [width]="90"
        [allowEditing]="false" format="#,##0.00" [calculateCellValue]='calcTotalPaid'>
      </dxi-column>

      <dxi-column *ngIf="isPurchaseOrdersActive" dataField="lastPaid" dataType="date" [width]="90"
        [allowEditing]="false" alignment="center" format="d-MMM-yy" [calculateCellValue]='calcLastPaidDate'>
      </dxi-column>

      <dxi-column type="buttons" [width]="90" fixed="true">
        <dxi-button [visible]="isButtonIconVisible" name="edit"></dxi-button>
        <dxi-button [visible]="isButtonIconVisible" name="delete"></dxi-button>
      </dxi-column>

      <dxo-summary [calculateCustomSummary]="calculateTotal" [recalculateWhileEditing]="true">
        <dxi-total-item name="gridTotal" summaryType="custom" valueFormat="#,##0.00" displayFormat="{0}"
          showInColumn="amount">
        </dxi-total-item>
        <dxi-total-item column="adjustedAmount" summaryType="sum" valueFormat="#,##0.00" displayFormat="{0}"
          showInColumn="adjustedAmount">
        </dxi-total-item>
      </dxo-summary>

    </dx-data-grid>

    <div *ngIf="!loading && contractValueGST" class="totalLine">
      Contract Value Ex GST: <span class="valueClass">{{ contractValueExGST | number : '1.2-2' }}</span>
    </div>

    <div *ngIf="!loading && contractValueGST" class="totalLine">
      GST: <span class="valueClass">{{ contractValueGST | number : '1.2-2' }}</span>
    </div>

    <div *ngIf="!loading" class="totalLine">
      Contract Value: <div class="valueClass">{{ contractValue | number : '1.2-2' }}</div>
    </div>

    <div *ngIf="!loading" class="totalLine">
      Unallocated: <span class="valueClass">{{ outstandingAmount | number : '1.2-2' }}</span>
    </div>

    <div *ngIf="!loading" class="m-auto">
      <dx-data-grid [dataSource]="dataSourceVariations" [allowColumnReordering]="false" [allowColumnResizing]="true"
        [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true">

        <dxo-sorting mode="none"></dxo-sorting>
        <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
        <dxo-scrolling mode="standard"></dxo-scrolling>
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false" [useIcons]="true"
          refreshMode="full">
        </dxo-editing>
        <dxo-row-dragging [allowReordering]="false"></dxo-row-dragging>
        <dxo-paging [enabled]="false"></dxo-paging>

        <dxi-column dataField="variationNumber" caption="Variation/Split" [width]="120">
        </dxi-column>

        <dxi-column dataField="title">
        </dxi-column>

        <dxi-column dataField="variationDate" dataType="date" alignment="center" format="d-MMM-yy" [width]="120">
        </dxi-column>

        <dxi-column dataField="variationTotal" dataType="number" [width]="120" format="#,##0.00">
        </dxi-column>

        <dxi-column dataField="totalPaid" dataType="number" [width]="120" format="#,##0.00">
        </dxi-column>

        <dxi-column dataField="lastPaidDate" caption="Last Paid" dataType="date" alignment="center" format="d-MMM-yy"
          [width]="120">
        </dxi-column>

      </dx-data-grid>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button *ngIf="!loading && claimsExist && isPurchaseOrdersActive" class="btn btn-secondary"
    (click)="printClaims()">Statement</button>
  <button class="btn btn-primary" (click)="close()">Close</button>
</div>

<dx-popup class="popup" [width]="450" [height]="200" [showTitle]="true" title="Generate Claims" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="generatePopupVisible">

  <js-loading-spinner *ngIf="modalLoading"></js-loading-spinner>
  <!-- <dx-load-indicator *ngIf="modalLoading" id="medium-indicator" height="40" width="40"></dx-load-indicator> -->

  <div *ngIf="!modalLoading">
    <div class="dx-field">
      <div class="dx-field-label">Template</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="claimMasters" displayExpr="description" valueExpr="id"
          (onValueChanged)="claimMasterSelected($event)"></dx-select-box>
      </div>

      <!-- <dx-button class="generateButton" text="Generate" type="default" (onClick)="generate()"></dx-button> -->
      <button class="btn btn-primary generateButton" (click)="generate()" [disabled]="!claimMasterId">Generate</button>
    </div>
  </div>
</dx-popup>
