<div class="modal-header">
  <h4 class="modal-title">Calculate Base Price</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal(false)" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <mat-expansion-panel [(expanded)]="feesSectionExpanded" (opened)="setHeight()" (closed)="setHeight()">
    <mat-expansion-panel-header>
      <mat-panel-title class="titleClass">Mark-Ups</mat-panel-title>
    </mat-expansion-panel-header>

    <form *ngIf="!loading" class="form formClass" novalidate [formGroup]="optionsForm">
      <fieldset>
        <div class="mt-1 mb-1 mx-0 panelClass">
          <!-- Mark-Up -->
          <label class="form-label marginLabel text-end mt-1" for="jobMargin">Mark-Up%</label>

          <dx-number-box [disabled]="!allowUpdate" [width]="80" formControlName="jobMargin" [showSpinButtons]="true"
            [class.redBackground]="!optionsForm.get('jobMargin').value" class="ms-2" (onBlur)="setMarginCheck()"
            (onEnterKey)="setMarginCheck()">
          </dx-number-box>

          <!-- Fees Margin -->
          <label *ngIf="estimatingPreContractFeesMargin" class="form-label feesLabel text-end mt-1"
            for="feesMargin">Insurances & Fees%
          </label>

          <dx-number-box *ngIf="estimatingPreContractFeesMargin" [disabled]="!allowUpdate" [width]="60"
            formControlName="feesMargin" [showSpinButtons]="true" class="ms-2" (onBlur)="setFeesMarginInJob()">
          </dx-number-box>

          <!-- note blur used so button doesn't do anything but helps user intuitiveness -->
          <button *ngIf="allowUpdate" class="btn btn-sm btn-primary">Set</button>

          <!-- GST Applicable? -->
          <label class="form-label gstLabel text-end me-1 mt-1" for="isGSTFree">GST Free?</label>

          <mat-checkbox class="mt-1" formControlName="isGSTFree" (click)="onGSTFreeClick()" tabindex="-1"
            [disabled]="!allowUpdate">
          </mat-checkbox>

          <!-- GST -->
          <label class="form-label gstLabel2 text-end mt-1" for="gstRate">GST%</label>

          <dx-number-box [disabled]="!allowUpdate || optionsForm.get('isGSTFree').value" [width]="70"
            formControlName="gstRate" [showSpinButtons]="true" class="ms-2" (onBlur)="onGSTChange()">
          </dx-number-box>

          <!-- Distict and Costing Date -->
          <span class='mt-1 ms-4'>Price Book: <b>{{setBook}}</b>
            <span class='ms-3'>
              Prices as at:
              <b *ngIf="estimatingCostingDate">{{estimatingCostingDate | date}}</b>
              <b *ngIf="!estimatingCostingDate" class="red">Date NOT Set</b>
            </span>
          </span>

          <button *ngIf="allowUpdate" class="btn btn-sm btn-primary ms-2" (click)="selectPriceFile()">
            Change / Revalue
          </button>

          <b class='mt-1 ms-4' *ngIf="!allowUpdate">Job not active or locked</b>
          <button *ngIf="!allowUpdate && estimatingAdmin" class="btn btn-sm btn-primary ms-2" (click)="unlock()">
            Unlock
          </button>
        </div>
      </fieldset>
    </form>
  </mat-expansion-panel>

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid #dataGrid *ngIf="!loading && estimatingCostingDate && marginSet" id="recipeGrid"
    [dataSource]="dataSource" [allowColumnReordering]="true" [showColumnLines]="true" [showRowLines]="true"
    [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true" [rowAlternationEnabled]="false"
    [wordWrapEnabled]="true" (onInitNewRow)="initNewRow($event)" (onEditorPreparing)="onEditorPreparing($event)"
    (onRowInserted)="$event.component.navigateToRow($event.key)" (onRowPrepared)="onRowPrepared($event)"
    (onFocusedCellChanging)="onFocusedCellChanging($event)" (onCellPrepared)="onCellPrepared($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)" [ngStyle]="{'height': 'calc(' + gridHeight + 'px)'}">

    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>

    <dxo-keyboard-navigation [editOnKeyPress]="editOnkeyPress" [enterKeyAction]="enterKeyAction"
      [enterKeyDirection]="enterKeyDirection">
    </dxo-keyboard-navigation>

    <dxo-editing mode="batch" refreshMode="reshape" [allowAdding]="allowUpdate" [allowUpdating]="allowUpdate"
      [allowDeleting]="allowUpdate" [useIcons]="true" startEditAction="click">
    </dxo-editing>

    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-sorting mode="single"></dxo-sorting>

    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
    </dxo-grouping>

    <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="" [groupIndex]="0" [width]="200"
      groupCellTemplate="groupCellTemplate">
    </dxi-column>

    <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="1" [width]="220"
      groupCellTemplate="groupCellTemplate">
    </dxi-column>

    <dxi-column caption="" [width]="44" cellTemplate="explodeTemplate"></dxi-column>
    <div *dxTemplate="let data of 'explodeTemplate'" class="text-center">
      <dx-button id="explodeFieldButton" hint="explode" icon="share" [visible]="isExplodeIconVisible(data)"
        (onClick)="explodeAsk(data)">
      </dx-button>
    </div>

    <dxi-column dataField="id" [visible]="false"></dxi-column>

    <dxi-column dataField="recipeCode" caption="Code" [width]="130" editCellTemplate="codeDropDown"
      [setCellValue]="setRecipeCellValue" sortOrder="asc">
    </dxi-column>

    <!-- <dxi-column dataField="description"> </dxi-column> -->

    <dxi-column dataField="description" dataType="string" [minWidth]="100" cellTemplate="descriptionTemplate">
    </dxi-column>
    <div *dxTemplate="let cell of 'descriptionTemplate'">
      <div id="wrapComment" class='mx-0 px-0'>{{cell.value}}</div>
    </div>

    <dxi-column dataField="unitOfMeasure" caption="Units" [width]="100" [setCellValue]="setUnitOfMeasureCellValue">
      <dxo-lookup [dataSource]="unitOfMeasures" valueExpr="description" displayExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="quantityString" caption="Qty" [width]="200" [setCellValue]="setQuantityStringCellValue">
    </dxi-column>

    <dxi-column dataField="quantity" caption="=Qty" dataType="number" [width]="70" [allowEditing]="false"
      format="#,###.####">
    </dxi-column>

    <dxi-column dataField="rate" dataType="number" format="#,###.00" [width]="90" [setCellValue]="setRateCellValue"
      [calculateCellValue]="getRate">
    </dxi-column>

    <dxi-column dataField="costTotal" caption="Cost" dataType="number" [width]="90" format="#,###"
      [allowEditing]="false" [calculateCellValue]="calculateCostTotal">
    </dxi-column>

    <dxi-column dataField="margin" caption="Mark Up%" dataType="number" [width]="100"
      [setCellValue]="setMarginCellValue">
    </dxi-column>

    <dxi-column dataField="lineTotal" caption="Total" dataType="number" [width]="90" format="#,###"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="isTBA" caption="TBA" dataType="boolean" [width]="75">
    </dxi-column>

    <dxi-column type="buttons" [width]="44" [fixed]="true">
      <dxi-button name="delete"></dxi-button>
    </dxi-column>

    <dxo-summary [calculateCustomSummary]="calculateTotal" [recalculateWhileEditing]="true">
      <dxi-total-item column="costTotal" summaryType="sum" [valueFormat]="{ type: 'fixedPoint', precision: 0 }"
        displayFormat="{0}">
      </dxi-total-item>

      <dxi-total-item name="marginTotal" summaryType="custom" displayFormat="Total:" showInColumn="margin">
      </dxi-total-item>

      <dxi-total-item name="gridTotal" summaryType="custom" valueFormat="#,##0" displayFormat="{0}"
        showInColumn="Total">
      </dxi-total-item>
    </dxo-summary>

    <div *dxTemplate="let cellInfo of 'codeDropDown'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="recipeData" [(value)]="cellInfo.value"
        displayExpr="recipeCode" valueExpr="recipeCode" contentTemplate="contentTemplate">
        <!-- Note we have to use standard scrolling so the list will "autoNavigateToFocusedRow" if the item is not near the top of the list -->
        <div *dxTemplate="let e of 'contentTemplate'" class="dropdownClass">
          <dx-data-grid [dataSource]="recipeData" [remoteOperations]="false" height="99%" [showBorders]="true"
            [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value" [autoNavigateToFocusedRow]="true"
            [wordWrapEnabled]="true" [selectedRowKeys]="[cellInfo.value]" [hoverStateEnabled]="true"
            [autoNavigateToFocusedRow]="true" (onSelectionChanged)="onSelectionChanged(cellInfo, e.component, $event)">

            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling mode="virtual" [preloadEnabled]="true"></dxo-scrolling>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-search-panel [visible]="true" [width]="758"></dxo-search-panel>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
            </dxo-grouping>

            <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="" [groupIndex]="0" [width]="200"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="1" [width]="220"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="recipeCode" caption="Code" [width]="110"></dxi-column>

            <dxi-column dataField="description"></dxi-column>
            <dxi-column dataField="rate" dataType="number" [width]="100" format="#,###.00"></dxi-column>
            <dxi-column dataField="scale" [visible]="false"></dxi-column>

            <div *dxTemplate="let data of 'groupCellTemplate'">
              {{ getRecipeGroupTitle(data) }}
            </div>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      {{ getGroupTitle(data) }}
    </div>
  </dx-data-grid>

  <div *ngIf="!loading && estimatingCostingDate && marginSet" class="totalLine">
    <div class="totalClass">
      <span *ngIf="isTBA">TBA</span>
      <span *ngIf="!isTBA">{{ itemTotal | number }}</span>
    </div>
    <div class="totalLabel">
      Total +GST<span *ngIf="estimatingPreContractFeesMargin"> +Fees</span>:
    </div>
  </div>
</div>

<div *ngIf="estimatingCostingDate && marginSet && !loading" class="modal-footer">
  <div class="justifyLeft">
    <div *ngIf="allowUpdate" class="class500 floatLeft">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="recipeData" [(value)]="selectedRecipes"
        [(opened)]="isDropDownBoxOpened" displayExpr="recipeCode" valueExpr="recipeCode"
        placeholder="Select recipes to load" [showClearButton]="true">
        <div *dxTemplate="let data of 'content'">
          <dx-data-grid #lookupdataGrid id="lookupdataGrid" [dataSource]="recipeData" [remoteOperations]="false"
            [columnAutoWidth]="false" height="99%" [showBorders]="true" [focusedRowEnabled]="true"
            [(selectedRowKeys)]="selectedRecipes" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
            (onRowClick)="onMultiRowClick($event)">

            <dxo-selection [allowSelectAll]="false" showCheckBoxesMode='always' mode="multiple">
            </dxo-selection>
            <dxo-paging [enabled]="false"> </dxo-paging>
            <dxo-scrolling mode="virtual" [preloadEnabled]="true"></dxo-scrolling>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-search-panel [visible]="true" [width]="758"></dxo-search-panel>
            <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
            </dxo-grouping>

            <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="" [groupIndex]="0" [width]="200"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="1" [width]="220"
              groupCellTemplate="groupCellTemplate">
            </dxi-column>

            <dxi-column dataField="recipeCode" caption="Code" [width]="110"></dxi-column>
            <dxi-column dataField="description"></dxi-column>
            <dxi-column dataField="rate" dataType="number" [width]="100" format="#,###.00"></dxi-column>
            <dxi-column dataField="scale" [visible]="false"></dxi-column>

            <div *dxTemplate="let data of 'groupCellTemplate'">
              {{ getRecipeGroupTitle(data) }}
            </div>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>
    <button *ngIf="allowUpdate" class="btn btn-secondary floatLeft ms-2" (click)="addRecipesFromList()"
      [disabled]="loading || !selectedRecipes || !selectedRecipes.length">Load</button>

    <button *ngIf="allowUpdate" class="btn btn-secondary floatLeft ms-2" (click)="refreshRecipes()"
      [disabled]="loading">Refresh</button>

    <button *ngIf="allowUpdate" class="btn btn-primary floatRight" (click)="closeModal(true)" [disabled]="loading">
      <span *ngIf="isJobSoldOrFromFuelGauge">Ok</span>
      <span *ngIf="!isJobSoldOrFromFuelGauge">Close & Update Base Price</span>
    </button>
    <button *ngIf="!isJobSoldOrFromFuelGauge" class="btn btn-secondary floatRight me-2" (click)="closeModal(false)"
      [disabled]="loading">Close</button>
  </div>
</div>

<!-- <dx-popup [width]="250" [height]="150" [showTitle]="true" title="Explode Recipe?" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="explodeRecipePopupVisible">
  <div id="explodeButton" *dxTemplate="let data of 'content'">
    <dx-button id="button" text="Explode" type="success" (onClick)="explode()"></dx-button>
  </div>
</dx-popup> -->

<dx-popup [width]="350" [height]="310" [showTitle]="true" title="Set Price File" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="setPriceFilePopupVisible">
  <div id="setPriceFile" *dxTemplate="let data of 'content'">
    <div class='mt-2 ms-3 me-2'>
      <span class='me-1 w-100'>Book:</span>
      <dx-select-box class="costingDateClass" [dataSource]="districtsDataSource" displayExpr="description"
        valueExpr="id" [(value)]="newPriceFileBookId">
      </dx-select-box>
    </div>
    <div class='mt-2 ms-3 me-2'>
      <span class='me-1 w-100'>As at:</span>
      <dx-date-box class="costingDateClass" [(value)]="newEstimatingCostingDate" type="date"></dx-date-box>
    </div>
    <div class='mt-2 ms-3 me-2'>
      <span class="me-2">revalue existing items?</span>
      <dx-check-box [(value)]="isRevalueItems" [width]="30"> </dx-check-box>
    </div>
    <br>
    <div class="mt-2 d-flex justify-content-end">
      <dx-button class='ms-3 me-3' id="button" text="Set/Revalue" type="success" (onClick)="updateJobExtra()">
      </dx-button>
    </div>
  </div>
</dx-popup>

<dx-popup [width]="630" [height]="150" [showTitle]="true" title="Update Items?" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="updateMarginsPopupVisible" (onHidden)="setMarginInJob(false)">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="button" text="Update all items with markup equal to the previous job markup to the new job markup?"
      type="success" (onClick)="setMarginInJob(true)">
    </dx-button>
  </div>
</dx-popup>

<dx-popup [width]="285" [height]="200" [showTitle]="true" title="Delete All?" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="deleteAllPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button text="Delete ALL lines in this estimate?" type="danger" (onClick)="deleteAllItems(false)">
    </dx-button>

    <br>

    <dx-button *ngIf="deleteAllConfirm" text="Confirm Delete of ALL lines?" type="danger"
      (onClick)="deleteAllItems(true)" class="mt-4">
    </dx-button>
  </div>
</dx-popup>

<dx-popup class="popup" [width]="400" [height]="170" [showTitle]="true" [title]="explodeTitle" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="explodeRecipePopupVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-button id="explodeLineButton2" text="Open - View Only" type="success" (onClick)="openRecipe()">
    </dx-button>
    <br>
    <dx-button id="explodeLineButton2" text="Explode" type="danger" (onClick)="explode()"></dx-button>
  </div>
</dx-popup>