import { AuthService } from './../../services/auth.service';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { JobVarItemService } from '../../services/felixApi/job-var-item.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TreeComponent } from '@circlon/angular-tree-component';
import { ItemTypeEnum } from '../../dtos/item-type.enum';
import { JobVarTypeEnum } from '../../dtos/job-var-type.enum';
import { ChangeTypeEnum } from '../../dtos/change-type.enum';
import { IJobItem } from '../../dtos/job-item';
import { OptionListService } from '../../services/felixApi/option-list.service';
import { SelectedJobItems } from '../../dtos/selectedJobItemIds';
import { IJob } from '../../dtos/job';
import { JobService } from '../../services/felixApi/job.service';
import { JobItemService } from '../../services/felixApi/job-item.service';
import { CostTypeEnum } from '../../dtos/cost-type.enum';
import { VariationTypeEnum } from '../../dtos/variation-type.enum';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { IJobVarItem } from '../../dtos/job-var-item';
import { IJobItemWithChildren } from '../../dtos/job-item-with-children';
import { NotificationService } from '../../services/notification.service';
import { PriceTypeEnum } from '../../dtos/price-type.enum';
import { IOptionListHeader } from '../../dtos/option-list-header';
import { CategoryService } from '../../services/felixApi/category.service';
import { JobItemCategory } from '../../dtos/job-item-category';
import { Subscription } from 'rxjs';
import { SelectedOptions } from '../../dtos/selectedOptions';
import { UtilsService } from '../../services/utils.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';

@Component({
  selector: 'js-variation-add-modal',
  templateUrl: './variation-add-modal.component.html',
  styleUrls: ['./variation-add-modal.component.scss']
})
export class VariationAddModalComponent implements OnInit, OnDestroy {
  @Input() jobitem: IJobItem;
  // @Input() jobItemParentTypeId: number;
  @Input() jobItemAboveId: number;
  @Input() parentConnectedItemId: number;
  @Input() variationNumber: number;
  @Input() variationId: number;
  @Input() indexNo: number;
  @Input() firstRecord: number;
  @Input() selectionsAdmin: boolean;
  @Input() variationType: number;
  @Input() clickedAddToEnd: boolean;
  @Input() headingTypeId: number;
  @Input() headingItemId: number;

  @ViewChild('tree3') tree: TreeComponent;

  COMPONENT_NAME = 'variation-add-modal';
  subscriptions: Subscription[] = [];

  colours = [
    { name: 'Standard', id: 0 },
    { name: 'Red', id: 1 },
    { name: 'Green', id: 2 }
  ];
  currentColour = 'Standard';

  modalHeading: string;
  changeTypeEnum = ChangeTypeEnum;
  itemTypeEnum = ItemTypeEnum;
  jobVarTypeEnum = JobVarTypeEnum;
  costTypeEnum = CostTypeEnum;
  costTypeDropdown: any[];
  addForm: UntypedFormGroup;
  updatedItem: any;
  canAddAbove: boolean;
  addAboveOrAfter: string;
  selectedOptionListId: number;
  loading = true;
  loadingHouseOptions = false;
  updating = false;

  treeOptionNodes: IOptionListHeader[] = [];
  tmpHouseOptionNodes: IOptionListHeader[] = [];
  treeHouseOptionNodes: IOptionListHeader[] = [];
  treeOptionOptions = {};
  selectedOptionValues: IJobVarItem = new IJobVarItem;
  jobItemCategories: JobItemCategory[] = [];
  updatedJobVarItems: IJobVarItem[] = [];

  // copy job variables
  jobToCopy: IJob;
  // jobNumberToCopyForm: FormGroup;
  jobNumber: string; // for copy
  itemsToCopy: any[] = [];
  itemsToCopyDto: SelectedJobItems;
  selectedNodes: SelectedOptions[] = [];
  copyTreeOptions = {};
  treeJobItemNodes: any[] = [];
  oneNode: IJobItemWithChildren = new IJobItemWithChildren;
  treeNodes: IJobItemWithChildren[] = [];
  treeJobItemNodesSetup = false;
  // treeJobItemOptions = {};
  copyComplete = true;
  orderNo: number;
  jobVarTypeId: number;
  itemChangedId: number;
  // jobItemAboveTypeId: number;
  variationTypeEnum = VariationTypeEnum;
  jobSearchTypeEnum = JobSearchTypeEnum;
  addAttachment = false;
  priceTypeEnum = PriceTypeEnum;

  isQtyRequired = false; // we know this if we use an option list where the parent specifies this
  showPrice = true; // we start with showing the price as the default is priced
  costTypeDesc: string;
  houseOptionsSelected = 0; // when selecting house options we need to count them
  foundNode: any; // used to find a selected node when getting house options
  optionListId: any;
  addAndEstimate: boolean;
  isInDoNotPrintSection = false;
  // load items
  value: any[] = [];
  isAdmin: boolean;
  addendumName: string;

  userCanChangePrices = true; // default to true as we are using this to turn off in a specific scenario for sales
  isVariationOnlyTextShown = false;
  noteHeight: number;
  isEstimatingWrite = false;

  constructor(private _globalService: GlobalService,
    private _jobService: JobService,
    private authService: AuthService,
    private _jobItemService: JobItemService,
    private _jobVarItemService: JobVarItemService,
    private _optionListService: OptionListService,
    private _categoryService: CategoryService,
    private notiService: NotificationService,
    private utilsService: UtilsService,
    private _formBuilder: UntypedFormBuilder,
    private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.isAdmin = this.authService.isAdminOrSuperUser();
    this.addendumName = this._globalService.getAddendumName();
    this.noteHeight = window.innerHeight - 600;
    this.noteHeight = this.noteHeight > 300 ? 300 : this.noteHeight;

    this.isEstimatingWrite = this.isAdmin || this.authService.getSelectionsPermissions('Estimating') === 'Write' || this.authService.getSelectionsPermissions('Estimating') === 'Admin';

    // can this user change prices - sales variations only
    if (!this.selectionsAdmin
      && (this._jobItemService.currentVariation.variationType > 10 && this._jobItemService.currentVariation.variationType < 20)
      && this._globalService.getCompanyConfigValue(ConfigurationEnum.CanSalesVariationItemsBeUnChecked) === 2) {
      const permission = this.authService.getSelectionsPermissions('Estimating');
      if (!permission || permission === 'Read' || permission === 'none') {
        this.userCanChangePrices = false;
      }
    }

    if (this.jobitem) {
      if (this._jobItemService.isInNotPrintedSection(this.jobitem.jobItemAboveTypeId, this.jobitem.jobItemAboveId)) {
        this.isInDoNotPrintSection = true;
      }

      const description = this.jobitem.itemDescription === null ? '' : this.jobitem.itemDescription;
      if (this.indexNo === -1) {
        // no items so creating the first
        this.addAboveOrAfter = 'Below';
        this.modalHeading = description === '' ? 'Add' : 'Add Below ' + description;
        this.canAddAbove = false;
      } else if (this.indexNo === this.firstRecord) {
        // can add above
        this.canAddAbove = true;
        this.addAboveOrAfter = 'After';
        this.modalHeading = 'Add Above/Below ' + description;
      } else {
        this.modalHeading = description === '' ? 'Add' : 'Add Below ' + description;
        this.canAddAbove = false;
        this.addAboveOrAfter = 'After';
      }
    } else {
      // at the top level as jobitem is null
      this.modalHeading = 'Add to end';
      this.canAddAbove = false;
      this.addAboveOrAfter = 'After';
    }

    this.addForm = this._formBuilder.group({
      addAboveOrAfter: this.addAboveOrAfter,
      itemTypeId: null,
      itemDescription: '',
      selection: '',
      price: null,
      isHiddenFromMaster: false,
      noteColour: 0,
      isBoldNote: false,
      isItalicNote: false,
      isDoNotPrint: false,
      isChecked: false,
      hideFixedPSum: false,
      quantity: 0,
      costTypeId: this.costTypeEnum.Priced,
      variationOnlyComment: ''
    });

    // read the categories and then the option lists
    this.getCategories();

    // set up for copy items
    this.copyJobSetup();

    this.setupCostTypeDropdown();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setupCostTypeDropdown() {
    this.costTypeDropdown = [
      { name: 'Priced', id: 0 },
      { name: 'Note', id: 1 },
      { name: 'No Charge', id: 2 },
      { name: 'EO Standard', id: 3 },
      { name: 'Provisional Sum', id: 4 },
      { name: 'By Owner', id: 6 },
      { name: 'Included', id: 7 }
    ];

    if (this.variationType < 20 && this.variationType > 10) {
      this.costTypeDropdown = this.costTypeDropdown.concat(
        { name: 'Promotion', id: 8 },
        { name: 'Bonus Item', id: 9 }
      );
    }

    this.costTypeDropdown = this.costTypeDropdown.concat(
      { name: 'Excluded', id: 10 }
    );

    this.costTypeDropdown.forEach(element => {
      if (element.id === this.addForm.get('costTypeId').value) {
        this.costTypeDesc = element.name;
      }
    });
  }

  getCategories() {
    // we now filter the house option nodes for matching categories if we have any

    if (this.jobitem?.jobItemAboveId) {
      // first read any categoris for the section we are in. find the section id first
      const sectionid = this.findSectionId(this.jobitem.jobItemAboveTypeId, this.jobitem.jobItemAboveId);

      // get the section categories
      this.subscriptions = this.subscriptions.concat(
        this._categoryService.getJobItemCategories(sectionid)
          .subscribe({
            next: (jobItemCategories) => {
              this.jobItemCategories = jobItemCategories;

              // read options in case user want to get from the list
              this.setupOptionNodes();
              this.setupHouseOptionNodes();
            },
            error: (err) => {
              this.notiService.notify(err);

              // read options in case user want to get from the list
              this.setupOptionNodes();
              this.setupHouseOptionNodes();
            }
          })
      );
    } else {
      // read options in case user want to get from the list
      this.setupOptionNodes();
      this.setupHouseOptionNodes();
    }
  }

  // set up the tree nodes of job items to allow linking
  setupOptionNodes() {
    this.treeOptionOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };

    if (this._optionListService.getCurrentOptionNodes() === null) {
      this.subscriptions = this.subscriptions.concat(
        this._optionListService.getOptionListChildren(0, true, false)
          .subscribe({
            next: (treeOptionNodes) => {
              this._optionListService.setCurrentOptionNodes(treeOptionNodes);
              this.treeOptionNodes = this.getJobItemCategories(this._optionListService.getCurrentOptionNodes());
              this.loading = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    } else {
      this.treeOptionNodes = this.getJobItemCategories(this._optionListService.getCurrentOptionNodes());
      this.loading = false;
    }
  }

  // set up the tree nodes for house Options
  setupHouseOptionNodes() {
    this.loadingHouseOptions = true;
    // console.log('setupHouseOptionNodes');
    this.treeOptionOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };
    this.tmpHouseOptionNodes = this._optionListService.getCurrentHouseOptionNodes(this._globalService.getCurrentJob());
    if (!this.tmpHouseOptionNodes) {
      this.subscriptions = this.subscriptions.concat(
        this._optionListService.getOptionListChildren(0, true, true, this._jobService.currentJob.houseTypeId)
          .subscribe({
            next: (treeOptionNodes) => {
              this.tmpHouseOptionNodes = treeOptionNodes;
              this._optionListService.setCurrentHouseOptionNodes(this._globalService.getCurrentJob(), this.tmpHouseOptionNodes);
              this.treeHouseOptionNodes = this.getJobItemCategories(this.tmpHouseOptionNodes);
              this.clearTreeHouseOptionNodes();
              this.loadingHouseOptions = false;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loadingHouseOptions = false;
            }
          })
      );
    } else {
      this.treeHouseOptionNodes = this.getJobItemCategories(this.tmpHouseOptionNodes);
      this.clearTreeHouseOptionNodes();
      this.loadingHouseOptions = false;
    }
  }

  clearTreeHouseOptionNodes() {
    // console.log('clearTreeHouseOptionNodes');
    // clear checked field in case already used
    this.treeHouseOptionNodes.forEach(element => {
      element.checked = false;
      if (element.children) {
        element.children.forEach(child => {
          this.clearHouseOptionNodeChildren(child);
        });
      }
    });
  }

  clearHouseOptionNodeChildren(node: IOptionListHeader) {
    // console.log('clearHouseOptionNodeChildren for ' + JSON.stringify(node));
    node.checked = false;
    if (node.children) {
      node.children.forEach(child => {
        this.clearHouseOptionNodeChildren(child);
      });
    }
  }

  getJobItemCategories(nodeList: IOptionListHeader[]): IOptionListHeader[] {
    // we now filter the house option nodes for matching categories if we have any

    let tempHouseOptionNode: IOptionListHeader;
    let newList: IOptionListHeader[] = [];

    // get the section categories
    if (nodeList && this.jobItemCategories && this.jobItemCategories.length) {
      nodeList.forEach(optionNode => {
        tempHouseOptionNode = this.filterHouseOptionNodes(optionNode);
        if (tempHouseOptionNode) {
          newList = newList.concat(tempHouseOptionNode);
        }
      });
    } else {
      // no categories so get all
      newList = nodeList;
    }

    return newList;
  }

  findSectionId(parentTypeId: number, currentId: number): number {
    // find the parent id of this id
    // console.log('findSectionId for currentId=' + currentId + ' parentTypeId=' + parentTypeId);
    let parentId = currentId;
    if (currentId) {
      const foundParent = this._jobItemService.currentJobItems.find(i => i.originalItemTypeId === parentTypeId && i.id === currentId);
      if (foundParent?.jobItemAboveId) {
        parentId = this.findSectionId(foundParent.jobItemAboveTypeId, foundParent.jobItemAboveId);
      }
      // this._jobItemService.currentJobItems.forEach(item => {
      //   if (item.id === currentId) {
      //     if (item.jobItemAboveId) {
      //       parentId = this.findSectionId(item.jobItemAboveId);
      //     }
      //   }
      // });
    }
    return parentId;
  }

  filterHouseOptionNodes(optionNode: IOptionListHeader): IOptionListHeader {
    // now we can filter the house option list as we have he categories
    let newOption: IOptionListHeader;
    let foundOption = false;
    // console.log('filterHouseOptionNodes node: ' + optionNode.description);

    if (optionNode.optionListCategories && optionNode.optionListCategories.length) {
      // this option has categories so we must check them
      // console.log('checking categories for: ' + JSON.stringify(optionNode.optionListCategories));
      optionNode.optionListCategories.forEach(category => {
        this.jobItemCategories.forEach(jobItemCategory => {
          if (jobItemCategory.category.id === category.categoryId) {
            newOption = optionNode; // found a match so return the whole child tree.
            foundOption = true;
          }
        });
      });
    }

    if (!foundOption) {
      // we check the children to see if we need this option record
      // otherwise we've reached the leaf of the tree and haven't found a match so we ignore
      // console.log('checking children for: optionNode');
      if (optionNode.children) {
        let childNodes: IOptionListHeader[] = [];
        optionNode.children.forEach(optionChild => {
          const returnedNodes = this.filterHouseOptionNodes(optionChild);
          if (returnedNodes) {
            childNodes = childNodes.concat(returnedNodes);
          }
        });

        // console.log('child nodes: ' + JSON.stringify(childNodes));
        if (childNodes && childNodes.length) {
          // newOption = optionNode;
          // needs to be copied so as to not upset the original list
          newOption = {
            id: optionNode.id,
            optionListIdAbove: optionNode.optionListIdAbove,
            description: optionNode.description,
            warningNote: optionNode.warningNote,
            productCode: optionNode.productCode,
            optionColour: optionNode.optionColour,
            orderNo: optionNode.orderNo,
            isActive: optionNode.isActive,
            notSelectable: optionNode.notSelectable,
            isBoldText: optionNode.isBoldText,
            attachmentId: optionNode.attachmentId,
            optionImageId: optionNode.optionImageId,
            children: childNodes,
            defaultDescription: optionNode.defaultDescription,
            houseTypeId: optionNode.houseTypeId,
            salesPriceIfAdded: optionNode.salesPriceIfAdded,
            salesPriceTypeIfAddedId: optionNode.salesPriceTypeIfAddedId,
            salesPriceIfChangedInSameList: optionNode.salesPriceIfChangedInSameList,
            salesPriceTypeIfChangedInSameListId: optionNode.salesPriceTypeIfChangedInSameListId,
            costToCompanyIfAdded: optionNode.costToCompanyIfAdded,
            costToCompanyIfChangedInSameList: optionNode.costToCompanyIfChangedInSameList,
            lastPriceUpdate: optionNode.lastPriceUpdate,
            lastPriceUpdatedByUserId: optionNode.lastPriceUpdatedByUserId,
            isQtyRequired: optionNode.isQtyRequired,
            optionTypeId: optionNode.optionTypeId,
            optionListCategories: null,
            childItemSelectableByClient: optionNode.childItemSelectableByClient,
            printedDescription: optionNode.printedDescription,
            childSelectionWarning: optionNode.childSelectionWarning,
            hideQuantity: optionNode.hideQuantity,
            checked: false,
            // colSpan: 1,
            // rowSpan: 1
          };
        }
      }
    }
    return newOption;
  }

  onOptionTreeInit(tree: TreeComponent) {
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
  }

  onHouseOptionTreeInit(tree: TreeComponent) {
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);

    // expand all base nodes
    this.tree.treeModel.roots.forEach(rootNode => {
      rootNode.expand();

      // next level
      if (rootNode.children?.length && rootNode.children?.length <= 2) {
        rootNode.children.forEach(childNode => {
          childNode.expand();
        });
      }
    });
  }

  // when the job item tree is initialised set the focused node to the one already selected.
  onItemTreeInit(tree: TreeComponent) {
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
    this.loading = false;
  }

  // set the linked option list when clicked on from tree
  setOptionList(node) {
    this.selectedOptionListId = node.data.id; // selected option id
    node.expand(); // expand next level if possible
    if (node.parent) {
      this.isQtyRequired = node.parent.data.isQtyRequired;
    } else {
      this.isQtyRequired = false;
    }
    this.addForm.patchValue({
      selection: node.data.description,
      noteColour: node.data.optionColour,
      isBoldNote: node.data.isBoldText
    });

    if (node.data.defaultDescription && node.data.defaultDescription !== '') {
      this.addForm.patchValue({
        itemDescription: node.data.defaultDescription
      });
    }

    this.setOptionPrice(node);
  }

  setOptionPrice(node) {
    if (this._jobItemService.currentVariation.variationType !== this.variationTypeEnum.Office
      && this._jobItemService.currentVariation.variationType !== this.variationTypeEnum.PreContractExFromContract) {
      if (!node.data.salesPriceTypeIfAddedId && node.data.salesPriceIfAdded && node.data.salesPriceIfAdded !== 0) {
        this.addForm.patchValue({
          price: node.data.salesPriceIfAdded,
          isChecked: true
        });
        this.setCostType(this.costTypeEnum.Priced);

      } else if (!node.data.salesPriceTypeIfAddedId) {
        this.addForm.patchValue({
          price: null,
          isChecked: false
        });
        this.setCostType(this.costTypeEnum.Priced);

      } else if (node.data.salesPriceTypeIfAddedId === this.priceTypeEnum.Note) {
        this.addForm.patchValue({
          price: null,
          isChecked: true
        });
        this.setCostType(this.costTypeEnum.Note);

      } else if (node.data.salesPriceTypeIfAddedId === this.priceTypeEnum.NoCharge) {
        this.addForm.patchValue({
          price: null,
          isChecked: true
        });
        this.setCostType(this.costTypeEnum.NoCharge);

      } else {
        if (node.data.salesPriceIfAdded) {
          this.addForm.patchValue({
            price: node.data.salesPriceIfAdded,
            isChecked: true
          });
        } else {
          this.addForm.patchValue({
            price: null,
            isChecked: true
          });
        }

        this.setCostType(node.data.salesPriceTypeIfAddedId);
      }

      this.selectedOptionValues.id = node.data.id;
      this.selectedOptionValues.price = this.addForm.get('price').value;
      this.selectedOptionValues.costTypeId = this.addForm.get('costTypeId').value;
    }
  }

  // setSelectedOptionValues(node) {
  //   this.selectedOptionValues.id = node.data.id;
  //   this.selectedOptionValues.price = this.addForm.get('price').value;
  //   this.selectedOptionValues.costTypeId = this.addForm.get('costTypeId').value;
  // }

  isCheckedClicked() {
    if (this._jobItemService.currentVariation.variationType !== this.variationTypeEnum.Office
      && this._jobItemService.currentVariation.variationType !== this.variationTypeEnum.PreContractExFromContract) {
      if ((!this.addForm.get('costTypeId').value
        || this.addForm.get('costTypeId').value === this.costTypeEnum.Priced
        || this.addForm.get('costTypeId').value === this.costTypeEnum.EOStandard
        || this.addForm.get('costTypeId').value === this.costTypeEnum.ProvisionalSum)
        && (this.addForm.get('price').value === null || this.addForm.get('price').value === 0)) {
        this.notiService.showWarning('Cannot mark an unpriced / TBA item as checked. Please change the price type.');
        this.addForm.patchValue({
          isChecked: false
        });
      }
    }
  }

  addVarItem() {
    if (this.addForm.get('itemTypeId').value === this.itemTypeEnum.HouseOption) {
      // add each item selected
      this.getItemsToCopy();
      // move selected nodes to the selectedNodes array for processing so we can hide the tree
      this.itemsToCopy.forEach(element => {
        let isQtyRequired = false;
        const parentOption = this.tree.treeModel.getNodeById(element).parent;
        if (parentOption) {
          isQtyRequired = parentOption.data.isQtyRequired;
        }
        this.selectedNodes =
          this.selectedNodes.concat({ selectedOption: this.tree.treeModel.getNodeById(element), isQtyRequired: isQtyRequired });
      });
      this.addHouseOption();
    } else {
      this.addOneVarItem();
    }
  }

  addHouseOption() {
    const thisOption = this.selectedNodes.pop();
    this.selectedOptionListId = thisOption.selectedOption.data.id; // selected option id
    this.isQtyRequired = thisOption.isQtyRequired;
    this.optionListId = thisOption.selectedOption.data.optionListAboveId;

    this.addForm.patchValue({
      selection: thisOption.selectedOption.data.description,
      noteColour: thisOption.selectedOption.data.optionColour,
      isBoldNote: thisOption.selectedOption.data.isBoldText,
      itemDescription: thisOption.selectedOption.data.defaultDescription,
      quantity: this.isQtyRequired ? 1 : 0
    });

    this.setOptionPrice(thisOption.selectedOption);

    // provisional sums are not checked when adding house options
    if (thisOption.selectedOption.data.salesPriceTypeIfAddedId === this.costTypeEnum.ProvisionalSum) {
      this.addForm.patchValue({
        isChecked: false
      });
    }
    this.addOneVarItem();
  }

  addOneVarItem() {
    this.updating = true;

    // check if default values have changed and 'uncheck' if no permission
    if (!this.selectionsAdmin) {
      if (this.selectedOptionValues.price !== this.addForm.get('price').value
        || this.selectedOptionValues.costTypeId !== this.addForm.get('costTypeId').value
        || this.isQtyRequired) {
        this.addForm.patchValue({
          isChecked: false
        });
      }
    }

    if (this._jobItemService.currentVariation.variationType !== this.variationTypeEnum.Office
      && this._jobItemService.currentVariation.variationType !== this.variationTypeEnum.PreContractExFromContract) {
      this.setCostType(this.addForm.get('costTypeId').value); // ensure isChecked and price is correct
    }

    // round qty to max 4 decimal places
    if (this.addForm.get('quantity').value) {
      this.addForm.patchValue({
        quantity: +(this.addForm.get('quantity').value.toFixed(4))
      });
    }

    let price = this.addForm.get('price').value;

    if (this.isQtyRequired && price !== null) {
      price = this.utilsService.roundEven(price * this.addForm.get('quantity').value);
    }

    if (this.addForm.get('itemTypeId').value === this.itemTypeEnum.Heading) {
      this.addForm.patchValue({
        selection: null
      });
      price = null;
    } else if (this.addForm.get('price').value === 0) {
      price = null;
    }

    // ensure cost type is not checked for headings and detail items
    if (this.addForm.get('itemTypeId').value === this.itemTypeEnum.Heading) {
      this.addForm.patchValue({
        costTypeId: this.costTypeEnum.Priced
      });
    }

    if (this.jobitem) {
      if (this.addForm.get('addAboveOrAfter').value === 'After') {
        // add to the same job item above (heading) below this this.jobitem
        if (this.jobitem.changedByJobVarId === 0) {
          // add with previous item = JobItem
          this.updatedItem = {
            jobVarTypeId: this.jobitem.originalItemTypeId,
            itemChangedId: this.jobitem.id,
            jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
            jobItemAboveId: this.jobitem.jobItemAboveId,
            orderNo: 1
          };
        } else if (this.jobitem.changedByVOId === this.variationId) {
          // add with previous item = JobVarItem and insert below current one
          if (this.jobitem.changeTypeId === ChangeTypeEnum.Add) {
            this.updatedItem = {
              jobVarTypeId: this.jobitem.jobVarTypeId,
              itemChangedId: this.jobitem.itemChangedId,
              jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
              jobItemAboveId: this.jobitem.jobItemAboveId,
              orderNo: this.jobitem.orderNo + 1
            };
          } else {
            this.updatedItem = {
              jobVarTypeId: this.jobitem.originalItemTypeId,
              itemChangedId: this.jobitem.id,
              jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
              jobItemAboveId: this.jobitem.jobItemAboveId,
              orderNo: 1
            };
          }
        } else {
          // add with previous item = JobVarItem
          // 2/12 - was jobVarTypeId: this.jobVarTypeEnum.JobVarItem, should be jobVarTypeId: this.jobitem.originalItemTypeId,
          this.updatedItem = {
            jobVarTypeId: this.jobitem.originalItemTypeId,
            itemChangedId: this.jobitem.id,
            jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
            jobItemAboveId: this.jobitem.jobItemAboveId,
            orderNo: 1
          };
        }
      } else if (this.addForm.get('addAboveOrAfter').value === 'Above') {
        // adding 'above' the first row
        this.updatedItem = {
          jobVarTypeId: 0,
          itemChangedId: 0,
          jobItemAboveTypeId: this.jobitem.jobItemAboveTypeId,
          jobItemAboveId: this.jobitem.jobItemAboveId,
          orderNo: 1
        };
      } else {
        // adding 'Under' a heading - which will be the first item
        this.updatedItem = {
          jobVarTypeId: 0,
          itemChangedId: 0,
          jobItemAboveTypeId: this.jobitem.originalItemTypeId,
          jobItemAboveId: this.jobitem.id,
          orderNo: 1
        };
      }
    } else {
      // jobitem is null so adding to the top level to the end
      // adding 'Under' a heading - which will be the first item
      this.updatedItem = {
        jobVarTypeId: 0,
        itemChangedId: 0,
        jobItemAboveTypeId: this.jobVarTypeEnum.JobItem,
        jobItemAboveId: null,
        orderNo: 1
      };
    }
    // console.log('adding with ' + JSON.stringify(this.updatedItem));

    if (this.addForm.get('itemTypeId').value === this.itemTypeEnum.Detail
      || this.addForm.get('itemTypeId').value === this.itemTypeEnum.HouseOption) {
      this.updatedItem.selectedOptionId = this.selectedOptionListId;
    }

    this.updatedItem.jobVariationId = this.variationId;
    this.updatedItem.changeTypeId = this.changeTypeEnum.Add;
    if (this.addForm.get('itemTypeId').value === this.itemTypeEnum.HouseOption) {
      if (this.isQtyRequired) {
        this.updatedItem.itemTypeId = this.itemTypeEnum.Detail;
      } else {
        this.updatedItem.itemTypeId = this.itemTypeEnum.Note;
      }
      this.updatedItem.optionListId = this.optionListId;
    } else {
      this.updatedItem.itemTypeId = this.addForm.get('itemTypeId').value;
    }
    this.updatedItem.itemDescription = this.addForm.get('itemDescription').value;
    this.updatedItem.selection = this.addForm.get('selection').value;
    this.updatedItem.price = this.utilsService.roundEven(price);
    this.updatedItem.isHiddenFromMaster = this.addForm.get('isHiddenFromMaster').value;
    this.updatedItem.noteColour = this.addForm.get('noteColour').value;
    this.updatedItem.isBoldNote = this.addForm.get('isBoldNote').value;
    this.updatedItem.isItalicNote = this.addForm.get('isItalicNote').value;
    this.updatedItem.isDoNotPrint = this.addForm.get('isDoNotPrint').value;
    this.updatedItem.isChecked = this.addForm.get('isChecked').value;
    this.updatedItem.connectedItemId = this.parentConnectedItemId; // use the heading above's connectedItemId

    if (this.addForm.get('costTypeId').value === this.costTypeEnum.Priced) {
      this.updatedItem.costTypeId = null;
    } else {
      this.updatedItem.costTypeId = this.addForm.get('costTypeId').value;
    }

    // new items are for option number 1
    this.updatedItem.optionNumber = 1;
    this.updatedItem.hideFixedPSum = this.addForm.get('hideFixedPSum').value;
    this.updatedItem.quantity = this.addForm.get('quantity').value;
    this.updatedItem.itemNotTaken = false;
    this.updatedItem.variationOnlyComment = this.addForm.get('variationOnlyComment').value;

    // if we using a qty we need to send the job var calc records as well ------------
    if (this.isQtyRequired) {
      this.updatedItem.addJobVarCalcDto = [
        {
          optionListId: null,
          quantity: 0,
          costTypeId: 0,
          itemRate: 0
        },
        {
          optionListId: this.updatedItem.selectedOptionId,
          quantity: this.addForm.get('quantity').value,
          costTypeId: this.addForm.get('costTypeId').value,
          itemRate: this.addForm.get('price').value === null ? 0 : this.addForm.get('price').value
        }
      ];
    }

    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.addJobVarItem(this.variationNumber, this.updatedItem).subscribe({
        next: (updatedJobVarItems) => {
          this.updatedJobVarItems = updatedJobVarItems;
          if (price) {
            this._jobItemService.variationTotal += price;
          }

          // if we have a variation comment we add to the jobvaritemextra
          this._jobItemService.updateJobVarItemExtraStore(this.updatedJobVarItems[0].id, this.addForm.get('variationOnlyComment').value);

          if (this.addForm.get('itemTypeId').value !== this.itemTypeEnum.HouseOption || this.houseOptionsSelected === 1) {
            if (!this.addAttachment && !this.addAndEstimate) {
              this._jobItemService.readJobItems();
            }
            this.close(this.updatedJobVarItems[0]);
          } else {
            this.houseOptionsSelected--; // count down
            this.addHouseOption();
          }
        },
        error: (err) => {
          this.notiService.notify(err);
          this.updating = false;
        }
      })
    );
  }

  addPlusAttachment() {
    this.addAttachment = true;
    this.addVarItem();
  }

  close(addedItem: IJobVarItem) {
    this.updatedItem.cancel = false;
    this.updatedItem.addAttachment = this.addAttachment;
    this.updatedItem.addAndEstimate = this.addAndEstimate;
    this.updatedItem.addedItemId = addedItem.id;
    this.updatedItem.itemDescription = addedItem.itemDescription;
    this.updatedItem.selection = addedItem.selection;
    this.updatedItem.itemTypeId = addedItem.itemTypeId;
    this.updatedItem.isQtyRequired = this.isQtyRequired;
    this._activeModal.close(this.updatedItem);
  }

  cancel() {
    this.updatedItem = { cancel: true };
    this._activeModal.close(this.updatedItem);
  }

  copyJobSetup() {
    this.jobNumber = '';

    this.copyTreeOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };
  }

  // changeJobToCopyNum() {
  //   // key pressed so clear to force GO
  //   this.jobToCopy = null;
  // }

  // getJobToCopy() {
  //   this.jobToCopy = null;
  //   this.subscriptions = this.subscriptions.concat(
  //     this._jobService.getJob(this.jobNumber)
  //       .subscribe(
  //         jobToCopy => {
  //           this.jobToCopy = jobToCopy;
  //           this.setupJobItemNodes(this.jobNumber);
  //         },
  //         err => {
  //           this.notiService.notify(err);
  //         })
  //   );
  // }

  // set up the tree nodes for the add/edit item
  setupJobItemNodes(JobNumber) {
    // this.treeJobItemOptions = {
    //   idField: 'id',
    //   displayField: 'itemDescription',
    //   childrenField: 'children',
    //   useVirtualScroll: true,
    //   nodeHeight: 22
    // };
    // start at item 0 to get all lines with children = true and get hidden lines = true
    // only used in setup so don't send to get any variations - i.e. don't send isLocked
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.getJobItems(JobNumber, 1, 0, true, true, false, 0, false, false)
        .subscribe({
          next: (treeJobItemNodes) => {
            this.treeJobItemNodes = treeJobItemNodes;
            this.treeNodes = [];
            this.treeJobItemNodes.forEach(item => {
              if (item.itemTypeId === this.itemTypeEnum.Heading) {
                this.oneNode.children = this.setupJobItemNodesChildren(item);
              } else {
                this.oneNode.children = [];
              }
              if (!item.itemDescription || item.itemDescription === '') {
                this.oneNode.description = item.selection;
              } else if (item.selection) {
                this.oneNode.description = item.itemDescription + ' - ' + item.selection;
              } else {
                this.oneNode.description = item.itemDescription;
              }

              this.treeNodes.push({
                id: item.id,
                itemTypeId: item.itemTypeId,
                changeTypeId: item.changeTypeId,
                jobVarTypeId: item.jobVarTypeId,
                itemChangedId: item.itemChangedId,
                description: this.oneNode.description,
                originalItemTypeId: item.originalItemTypeId,
                originalItemId: item.id,
                connectedItemId: item.connectedItemId,
                children: this.oneNode.children,
                parentId: null,
                changedByVOId: item.changedByVOId,
                changedByJobVarId: item.changedByJobVarId,
                masterItemId: item.masterItemId,
                hasLinkedItems: item.hasLinkedItems,
                optionNumber: item.optionNumber,
                checked: false,
                indeterminate: false,
                isChecked: item.isChecked,
                variationItemNumber: item.variationItemNumber
              });
            });
            this.treeJobItemNodesSetup = true;
          },
          error: (err) => {
            this.treeJobItemNodesSetup = true;
            this.notiService.notify(err);
          }
        })
    );
  }

  setupJobItemNodesChildren(node): IJobItemWithChildren[] {
    // recursively check the children

    const childrenNodes: IJobItemWithChildren[] = [];
    const childNode: IJobItemWithChildren = new IJobItemWithChildren;
    node.children.forEach(element => {
      if (element.itemTypeId === this.itemTypeEnum.Heading) {
        childNode.children = this.setupJobItemNodesChildren(element);
      } else {
        childNode.children = [];
      }
      if (!element.itemDescription || element.itemDescription === '') {
        childNode.description = element.selection;
      } else if (element.selection) {
        childNode.description = element.itemDescription + ' - ' + element.selection;
      } else {
        childNode.description = element.itemDescription;
      }

      // add manually as the point will not work.
      childrenNodes.push({
        id: element.id,
        itemTypeId: element.itemTypeId,
        changeTypeId: element.changeTypeId,
        jobVarTypeId: element.jobVarTypeId,
        itemChangedId: element.itemChangedId,
        description: childNode.description,
        originalItemTypeId: element.originalItemTypeId,
        originalItemId: element.id,
        connectedItemId: element.connectedItemId,
        children: childNode.children,
        parentId: node.id,
        changedByVOId: element.changedByVOId,
        changedByJobVarId: element.changedByJobVarId,
        masterItemId: element.masterItemId,
        hasLinkedItems: element.hasLinkedItems,
        optionNumber: element.optionNumber,
        checked: false,
        indeterminate: false,
        isChecked: element.isChecked,
        variationItemNumber: element.variationItemNumber
      });
    });
    return childrenNodes;
  }

  // set the linked job item when clicked on from tree
  copyTreeNodeClicked(tree: TreeComponent, node) {
    // console.log(this.COMPONENT_NAME + '-$event:' + JSON.stringify($event) + ' target:' + JSON.stringify($event.target.checked));
    // if ($event.target.checked && node.children) {
    node.data.checked = !node.data.checked;
    if (node.children) {
      node.children.forEach((child) => this.checkAllChildren(child, node.data.checked));
    }

    if (node.data.masterItemId !== null || node.data.hasLinkedItems) {
      // if we have linked items we keep together so tick/untick them automatically
      tree.treeModel.roots.forEach(item => {
        if (node.data.parentId === item.data.parentId) {
          // must be siblings
          if (node.data.masterItemId === item.data.id
            || node.data.id === item.data.masterItemId
            || (node.data.masterItemId && node.data.masterItemId === item.data.masterItemId)) {
            item.data.checked = node.data.checked;
          }
        } else if (item.data.itemTypeId === this.itemTypeEnum.Heading) {
          this.checkChildrenToCheck(item, node);
        }
      });
    }
    tree.treeModel.update();
  }

  checkChildrenToCheck(parent, node) {
    parent.children.forEach(item => {
      if (node.data.parentId === item.data.parentId) {
        // must be siblings
        if (node.data.masterItemId === item.data.id
          || node.data.id === item.data.masterItemId
          || (node.data.masterItemId && node.data.masterItemId === item.data.masterItemId)) {
          item.data.checked = node.data.checked;
        }
      } else if (item.data.itemTypeId === this.itemTypeEnum.Heading) {
        this.checkChildrenToCheck(item, node);
      }
    });
  }

  checkAllChildren(node, checkValue) {
    node.data.checked = checkValue;
    if (node.children) {
      node.children.forEach((child) => this.checkAllChildren(child, checkValue));
    }
  }

  onCopyTreeInit(tree: TreeComponent) {
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
    this.loading = false;
  }

  getItemsToCopy() {
    // get the items in the right order
    this.itemsToCopy = [];
    for (let i = 0; i < this.tree.treeModel.nodes.length; i++) {
      if (this.tree.treeModel.getNodeById(this.tree.treeModel.nodes[i].id).data.checked === true) {
        this.itemsToCopy = this.itemsToCopy.concat(+this.tree.treeModel.nodes[i].id);
      }
      if (this.tree.treeModel.nodes[i].children) {
        this.tree.treeModel.nodes[i].children.forEach((child) => this.checkAddItemsToCopy(child));
      }
    }
  }

  runCopy() {
    this.getItemsToCopy();
    this.itemsToCopyDto = { selectedJobItemIds: this.itemsToCopy };
    this.copyComplete = false;
    let jobItemAboveTypeId = 1;
    let jobItemAboveId = 1;

    if (this.jobitem) {
      if (this.addForm.get('addAboveOrAfter').value === 'After') {
        // add to the same job item above (heading) below this this.jobitem
        if (!this.jobitem.changedByJobVarId || this.jobitem.changedByJobVarId === 0) {
          // add with previous item = JobItem
          this.jobVarTypeId = this.jobitem.originalItemTypeId;
          this.itemChangedId = this.jobitem.id;
          jobItemAboveTypeId = this.jobitem.jobItemAboveTypeId;
          jobItemAboveId = this.jobitem.jobItemAboveId;
          this.orderNo = 1;
        } else if (this.jobitem.changedByVOId === this.variationId) {
          // add with previous item = JobVarItem and insert below current one
          if (this.jobitem.changeTypeId === ChangeTypeEnum.Add) {
            this.jobVarTypeId = this.jobitem.jobVarTypeId;
            this.itemChangedId = this.jobitem.itemChangedId;
            jobItemAboveTypeId = this.jobitem.jobItemAboveTypeId;
            jobItemAboveId = this.jobitem.jobItemAboveId;
            this.orderNo = this.jobitem.orderNo + 1;
          } else {
            this.jobVarTypeId = this.jobitem.originalItemTypeId;
            this.itemChangedId = this.jobitem.id;
            jobItemAboveTypeId = this.jobitem.jobItemAboveTypeId;
            jobItemAboveId = this.jobitem.jobItemAboveId;
            this.orderNo = 1;
          }
        } else {
          // add with previous item = JobVarItem
          this.jobVarTypeId = this.jobitem.originalItemTypeId; // was this.jobVarTypeEnum.JobVarItem;
          this.itemChangedId = this.jobitem.id;
          jobItemAboveTypeId = this.jobitem.jobItemAboveTypeId;
          jobItemAboveId = this.jobitem.jobItemAboveId;
          this.orderNo = 1;
        }
      } else if (this.addForm.get('addAboveOrAfter').value === 'Above') {
        // adding 'above' the first row
        this.jobVarTypeId = 0;
        this.itemChangedId = 0;
        jobItemAboveTypeId = this.jobitem.jobItemAboveTypeId;
        jobItemAboveId = this.jobitem.jobItemAboveId;
        this.orderNo = 1;
      } else {
        // adding 'Under' a heading - which will be the first item
        this.jobVarTypeId = 0;
        this.itemChangedId = 0;
        jobItemAboveTypeId = this.jobitem.originalItemTypeId;
        jobItemAboveId = this.jobitem.id;
        this.orderNo = 1;
      }
    } else {
      // jobitem is null so adding to the top level to the end
      this.jobVarTypeId = 0;
      this.itemChangedId = 0;
      jobItemAboveTypeId = this.jobVarTypeEnum.JobItem;
      jobItemAboveId = null;
      this.orderNo = 1;
    }

    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.copyJobItemSet(jobItemAboveTypeId, jobItemAboveId, this.orderNo,
        this.itemsToCopyDto, this.variationId, this.jobVarTypeId, this.itemChangedId).subscribe({
          next: () => {
            this._jobItemService.readJobItems();
            this.copyComplete = true;
            this.updatedItem = { cancel: false };
            this._activeModal.close(this.updatedItem);
          },
          error: (err) => {
            this.copyComplete = true;
            this.notiService.notify(err);
          }
        })
    );
  }

  checkAddItemsToCopy(node) {
    if (this.tree.treeModel.getNodeById(node.id).data.checked === true) {
      this.itemsToCopy = this.itemsToCopy.concat(+node.id);
    }
    if (node.children) {
      node.children.forEach((child) => this.checkAddItemsToCopy(child));
    }
  }

  // jobSearch() {
  //   // this.router.navigate(['jobsearch/selections']);
  //   const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-1000' });
  //   modalRef.componentInstance.searchType = this.jobSearchTypeEnum.Templates;

  //   modalRef.result.then((jobNumber) => {
  //     if (jobNumber) {
  //       this.treeJobItemNodes = [];
  //       this.treeJobItemNodesSetup = false;
  //       this.jobNumberToCopyForm.patchValue({
  //         jobNumber: jobNumber
  //       });
  //       this.getJobToCopy();
  //     }
  //   }, (reason) => {
  //   });
  // }

  clearWhenHeading() {
    this.addForm.patchValue({
      selection: '',
      quantity: null,
      price: null
    });
    this.isQtyRequired = false;
  }

  clearWhenNote() {
    this.addForm.patchValue({
      quantity: null
    });
    this.isQtyRequired = false;
  }

  selectAllNodesForCopy() {
    this.tree.treeModel.roots.forEach((child) => this.checkAllChildren(child, true));
    this.tree.treeModel.update();
  }

  setCostType(costTypeId: number) {
    if ((costTypeId === this.costTypeEnum.Note) || (costTypeId === this.costTypeEnum.NoCharge)
      || (costTypeId === this.costTypeEnum.ByOwner)) {
      this.showPrice = false;
    } else {
      this.showPrice = true;
    }

    this.addForm.patchValue({
      costTypeId: costTypeId
    });

    if ((!costTypeId || costTypeId === this.costTypeEnum.Priced
      || costTypeId === this.costTypeEnum.EOStandard
      || costTypeId === this.costTypeEnum.ProvisionalSum)
      && (!this.addForm.get('price').value || this.addForm.get('price').value === 0)) {
      this.addForm.patchValue({
        isChecked: false
      });
    } else if (costTypeId === this.costTypeEnum.Note
      || costTypeId === this.costTypeEnum.NoCharge
      || costTypeId === this.costTypeEnum.ByOwner) {
      this.addForm.patchValue({
        price: null
      });
    }

    if (this.isQtyRequired
      && (!costTypeId || costTypeId === this.costTypeEnum.Priced
        || costTypeId === this.costTypeEnum.EOStandard
        || costTypeId === this.costTypeEnum.ProvisionalSum)
      && (!this.addForm.get('quantity').value || this.addForm.get('quantity').value === 0)) {
      this.addForm.patchValue({
        isChecked: false
      });
    }

    this.costTypeDropdown.forEach(element => {
      if (element.id === this.addForm.get('costTypeId').value) {
        this.costTypeDesc = element.name;
      }
    });
  }

  setColour(colour: number) {
    this.currentColour = this.colours[colour].name;

    this.addForm.patchValue({
      noteColour: colour
    });
  }

  countHouseOptionsSelected(node) {
    node.data.checked = !node.data.checked;

    if (node.data.checked) {
      this.houseOptionsSelected += 1;

      if (node.data.warningNote && node.data.warningNote !== '') {
        this.notiService.showInfo(node.data.warningNote);
      }
    } else {
      this.houseOptionsSelected -= 1;
    }
  }

  expandNode(node) {
    // so expand if possible
    node.expand();
  }

  checkNoCents() {
    // we don't allow cents in the price - reports round
    if (this.addForm.get('price').value && !this.isQtyRequired) {
      const val = this.addForm.get('price').value % 1;
      if (val !== 0) {
        this.notiService.showInfo('No cents allowed');
        this.addForm.patchValue({
          price: this.addForm.get('price').value - val
        });
      }
    }
  }

  estimatePrice() {
    this.addAndEstimate = true;
    this.addVarItem();
  }

  runUpload() {
    // load the file
    if (this.value && this.value.length) {
      this.updating = true;
      const formData: FormData = new FormData();
      formData.append('image', this.value[0], this.value[0].name);

      const itemChangedId = this.jobitem.id === this.headingItemId
        ? 0 : this.jobitem.jobVariationId === this.variationId
          ? this.jobitem.itemChangedId : this.jobitem.id;

      const jobVarTypeId = this.jobitem.id === this.headingItemId
        ? 0 : this.jobitem.jobVariationId === this.variationId
          ? this.jobVarTypeId : this.jobitem.originalItemTypeId ? this.jobitem.originalItemTypeId : 1;

      this.subscriptions.push(
        this._jobVarItemService.postUploadtemplate(this._globalService.getCurrentJob(), this.variationId,
          this.headingTypeId, this.headingItemId, jobVarTypeId, itemChangedId, formData).subscribe({
            next: () => {
              this._jobItemService.readJobItems();
              this.updatedItem = { cancel: false };
              this._activeModal.close(this.updatedItem);
            }, error: (err) => {
              this.notiService.notify(err);
              this.value = [];
              this.updating = false;
            }
          })
      );
    }
  }

  refreshJobNumber(jobNumber: string) {
    this.jobNumber = jobNumber;
    this.treeJobItemNodes = [];
    this.treeJobItemNodesSetup = false;

    if (this.jobNumber && this.jobNumber !== '') {
      const currentJob = this._jobService.jobs?.find(i => i.jobNumber === jobNumber);
      if (currentJob) {
        this.jobToCopy = currentJob;
        this.setupJobItemNodes(this.jobNumber);
      }
    }
  }

  getRoundedPrice(x: number) {
    return this.utilsService.roundEven(x);
  }
}
