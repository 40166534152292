import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* PACKAGES */
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TreeModule } from '@circlon/angular-tree-component';

/* MATERIAL */
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';

/* DX */
import {
  DxButtonModule, DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxDropDownBoxModule,
  DxLoadIndicatorModule, DxLookupModule, DxPopupModule, DxFileUploaderModule,
  DxScrollViewModule, DxSelectBoxModule, DxTemplateModule, DxTextBoxModule, DxTextAreaModule, DxTreeListModule,
  DxListModule, DxNumberBoxModule, DxLoadPanelModule, DxTabPanelModule, DxRadioGroupModule, DxTooltipModule, 
  DxFormModule, DxAccordionModule
} from 'devextreme-angular';

/* PROJECT */
import { ShowImageComponent } from './show-image.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ShowPdfComponent } from './show-pdf.component';
import { BackgroundLogoComponent } from './background-logo.component';
import { ErrorPopupComponent } from './error-popup/error-popup.component';
import { HelpComponent, HelpActivateComponent, HelpModalComponent } from './help.component';
import { ImageWindowComponent } from './image-window.component';
import { EmailLookupComponent } from './email-lookup/email-lookup.component';
import { FocusElementDirective } from './focus-element.directive';
import { DebounceClickDirective } from './debounce-click';
import { GenericFilterPipe } from './genericFilter.pipe';
import { NgxImageCompressService } from 'ngx-image-compress';
import { JobRolesComponent } from './job-roles/job-roles.component';
import { SaveDocCopyComponent } from './save-doc-copy/save-doc-copy.component';
import { SubRecipeComponent } from './sub-recipe/sub-recipe.component';
import { RecipeLinesComponent } from './recipe-lines/recipe-lines.component';


@NgModule({
    imports: [
        CommonModule,
        NgxExtendedPdfViewerModule,
        TreeModule,
        DxButtonModule,
        DxDataGridModule,
        DxPopupModule,
        DxTreeListModule,
        DxCheckBoxModule,
        DxScrollViewModule,
        DxFileUploaderModule,
        DxSelectBoxModule,
        DxDropDownBoxModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxTextAreaModule,
        DxTemplateModule,
        DxLookupModule,
        DxLoadIndicatorModule,
        DxDateBoxModule,
        DxListModule,
        DxNumberBoxModule,
        DxLoadPanelModule,
        MatExpansionModule,
        DxTabPanelModule,
        DxRadioGroupModule,
        DxTooltipModule,
        DxFormModule,
        DxAccordionModule
    ],
    declarations: [
        HelpComponent,
        HelpActivateComponent,
        HelpModalComponent,
        ShowImageComponent,
        LoadingSpinnerComponent,
        ShowPdfComponent,
        ErrorPopupComponent,
        BackgroundLogoComponent,
        ImageWindowComponent,
        EmailLookupComponent,
        DebounceClickDirective,
        GenericFilterPipe,
        FocusElementDirective,
        JobRolesComponent,
        SaveDocCopyComponent,
        SubRecipeComponent,
        RecipeLinesComponent
    ],
    exports: [
        CommonModule,
        TreeModule,
        /* NG */
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        EmailLookupComponent,
        /* MAT */
        MatSelectModule,
        MatTooltipModule,
        MatTableModule,
        MatTabsModule,
        MatCheckboxModule,
        MatGridListModule,
        MatExpansionModule,
        /* PACKAGE */
        CurrencyMaskModule,
        DxButtonModule,
        DxDataGridModule,
        DxPopupModule,
        DxTreeListModule,
        DxCheckBoxModule,
        DxScrollViewModule,
        DxFileUploaderModule,
        DxSelectBoxModule,
        DxDropDownBoxModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxTextAreaModule,
        DxTemplateModule,
        DxLookupModule,
        DxLoadIndicatorModule,
        DxDateBoxModule,
        DxListModule,
        DxNumberBoxModule,
        DxLoadPanelModule,
        DxTabPanelModule,
        DxRadioGroupModule,
        DxTooltipModule,
        DxFormModule,
        DxAccordionModule,
        /* PROJECT */
        HelpComponent,
        HelpActivateComponent,
        HelpModalComponent,
        ShowImageComponent,
        LoadingSpinnerComponent,
        ErrorPopupComponent,
        BackgroundLogoComponent,
        DebounceClickDirective,
        GenericFilterPipe,
        FocusElementDirective
    ],
    providers: [NgxImageCompressService]
})
export class SharedModule { }
