export const APP_NAME = 'TruthEngineMain';
export const APP_ID = 1;

export const MAJOR_VERSION = '4.10';
export const PATCH_VERSION = '.8a'; // not stored in DB or checked in refresh guard

/// B2C / MSAL ///
export const B2C_AUTHORITY = 'https://truthengine.b2clogin.com/truthengine.onmicrosoft.com/B2C_1_signup-signin-prod';
export const B2C_AUTHORITY_PROD = 'https://truthengine.b2clogin.com/truthengine.onmicrosoft.com/B2C_1_signup-signin-production';
export const B2C_AUTHORITY_DEV = 'https://truthengine.b2clogin.com/truthengine.onmicrosoft.com/B2C_1_signup-signin-dev';
export const B2C_AUTHORITY_DOMAIN = 'truthengine.b2clogin.com';

export const MAX_OPTION_IMAGE_SIZE_MB = 0.2; // Uploaded imgs compressed to this size
