import { AuthService } from './../../../services/auth.service';
import { VariationSplitsService } from './../../../services/felixApi/variation-splits.service';
import { GlobalService } from './../../../services/global.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridService } from '../../../services/grid.service';
import { NotificationService } from '../../../services/notification.service';
import { ClaimsService } from '../../../services/felixApi/claims.service';
import { VariationService } from '../../../services/felixApi/variation.service';
import { Subscription } from 'rxjs';
import { JobService } from '../../../services/felixApi/job.service';
import { EstimatingService } from '../../../services/felixApi/estimating.service';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { CompanyService } from '../../../services/felixApi/company.service';
import { CompanyActivity } from '../../../dtos/company-activity';
import { ClaimMaster } from '../../../dtos/claim-master';
import { PDFReports } from '../../../dtos/pdf-report';
import { UtilsService } from '../../../services/utils.service';
import { ClaimJobLine } from '../../../dtos/claim-job-line';
import { ConfigurationEnum } from '../../../dtos/configuration-enum';
import { StatementComponent } from '../statement/statement.component';

@Component({
  selector: 'js-job-claims',
  templateUrl: './job-claims.component.html',
  styleUrls: ['./job-claims.component.scss']
})
export class JobClaimsComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  claimMasterId: number;
  loading = true;
  refreshMode: string;
  dropDownOptions: { width: number; };
  enterKeyActions: string[];
  enterKeyDirections: string[];
  editOnkeyPress: boolean;
  enterKeyAction: string;
  enterKeyDirection: string;
  dataSource: CustomStore;
  dataSourceVariations: CustomStore;
  activities: CompanyActivity[];
  generatePopupVisible = false;
  claimsExist = false; // we start with true
  claimTotal: number;
  contractValue = 0;
  contractValueGST = 0;
  contractValueExGST = 0;
  deposit = 0;
  outstandingAmount = 0;
  modalLoading: boolean;
  claimMasters: ClaimMaster[];
  pdfReport: PDFReports;
  isPurchaseOrdersActive: boolean;
  claimJobLines: ClaimJobLine[] = [];
  claimTriggers = [
    { id: 1, description: 'By Activity' },
    { id: 2, description: 'Raised Immediately' },
    { id: 6, description: 'Raise At Date' }
  ];
  invoiceTos = [
    { id: 2, description: 'From Loan' },
    { id: 3, description: 'Direct to Client' }
  ];
  lenderInformationEnabled: boolean;
  jobsAdminPermission = false;
  jobNumber: string;

  constructor(
    public activeModal: NgbActiveModal,
    private _authService: AuthService,
    private claimsService: ClaimsService,
    private variationService: VariationService,
    private notiService: NotificationService,
    public gridService: GridService,
    private jobService: JobService,
    private estimatingService: EstimatingService,
    private companyService: CompanyService,
    private modalService: NgbModal,
    private utilsService: UtilsService,
    private globalService: GlobalService,
    private variationSplitsService: VariationSplitsService
  ) {
    this.reorder = this.reorder.bind(this);
    this.calcInvoiceDate = this.calcInvoiceDate.bind(this);
    this.calcTotalPaid = this.calcTotalPaid.bind(this);
    this.calcLastPaidDate = this.calcLastPaidDate.bind(this);
    this.calculateAdjustedAmount = this.calculateAdjustedAmount.bind(this);
    this.setPercentCellValue = this.setPercentCellValue.bind(this);
    this.setAmountCellValue = this.setAmountCellValue.bind(this);
    this.setDateCellValue = this.setDateCellValue.bind(this);
    this.calculatePercentValue = this.calculatePercentValue.bind(this);
    
    this.refreshMode = 'reshape'; // ['full', 'reshape', 'repaint']
    this.dropDownOptions = { width: 800 };

    this.enterKeyActions = this.estimatingService.getEnterKeyActions();
    this.enterKeyDirections = this.estimatingService.getEnterKeyDirections();
    this.editOnkeyPress = true;
    this.enterKeyAction = 'moveFocus';
    this.enterKeyDirection = 'column';
  }

  ngOnInit() {
    this.isPurchaseOrdersActive = this.globalService.isPurchaseOrderSystemActive;
    this.jobNumber = this.jobService.currentJob.jobNumber;

    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.LenderInformationEnabled) === 1) {
      this.lenderInformationEnabled = true;
    }

    const permissionLevel = this._authService.getSelectionsPermissions('Jobs');
    if (permissionLevel === 'Admin' || this._authService.isAdminOrSuperUser()) {
      this.jobsAdminPermission = true;
    }

    this.getContractValue();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getContractValue() {
    // get the total contract value
    this.deposit = this.jobService.currentJob.depositAmount ? this.jobService.currentJob.depositAmount : 0;

    this.subscriptions = this.subscriptions.concat(
      this.variationSplitsService.getClaimsData(this.jobService.currentJob.id).subscribe({
        next: (contractValue) => {
          this.contractValue = contractValue;
          this.activities = this.companyService.activities;

          if (!this.jobService.currentJob.isGSTFree) {
            this.contractValueGST = !contractValue ? 0 : this.utilsService.roundEven(contractValue / 11);
            this.contractValueExGST = contractValue - this.contractValueGST;
          }

          this.setUpDataSets();
          this.loading = false;
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  setUpDataSets() {
    this.dataSourceVariations = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.variationService.getVariationInvoiceSummary(this.jobService.currentJob.id).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });

    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.claimsService.getClaimsData(this.jobService.currentJob.id).subscribe({
            next: (res) => {
              if (res && res.length) {
                this.claimsExist = true;
              } else {
                this.claimsExist = false;
              }
              this.claimJobLines = [];
              if (this.deposit) {
                this.claimJobLines.push(new ClaimJobLine(this.deposit));
              }
              this.claimJobLines = this.claimJobLines.concat(res);
              return resolve(this.claimJobLines);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.claimsService.postJobClaim(this.jobService.currentJob.id, values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.claimsService.updateJobClaim(this.jobService.currentJob.id, encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.claimsService.deleteJobClaim(this.jobService.currentJob.id, encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  calculateTotal = (options) => {
    if (options.name === 'gridTotal') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
        this.claimTotal = 0;

      } else if (options.summaryProcess === 'calculate') {
        options.totalValue += options.value.amount ? options.value.amount : 0;
        this.claimTotal = options.totalValue;

      } else if (options.summaryProcess === 'finalize') {
        this.outstandingAmount = this.contractValue - this.claimTotal;
      }
    }
  }

  openGenerateModal() {
    this.generatePopupVisible = true;
    this.modalLoading = true;
    this.claimMasters = [];

    this.subscriptions = this.subscriptions.concat(
      this.claimsService.getClaimMasters().subscribe({
        next: (claimMasters) => {
          this.claimMasters = claimMasters;
          this.modalLoading = false;
        },
        error: (err) => {
          this.notiService.notify(err);
          this.modalLoading = false;
        }
      })
    );
  }

  claimMasterSelected(data) {
    this.claimMasterId = data.value;
  }

  generate() {
    // Generate claims
    this.loading = true;
    this.generatePopupVisible = false;

    this.subscriptions = this.subscriptions.concat(
      this.claimsService.generateJobClaimItem(this.jobService.currentJob.id, this.claimMasterId).subscribe({
        next: () => {
          this.loading = false;
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  refresh() {
    this.grid.instance.refresh();
  }

  printClaims() {
    const modalRef1 = this.modalService.open(StatementComponent, { windowClass: 'modal-1000' });

    modalRef1.result.then(() => {
    }, () => {
    });
  }

  close() {
    if (this.grid.instance.hasEditData()) {
      this.notiService.showInfo('Please Save or Cancel the edited data (or saving is in progress)');
    } else {
      this.activeModal.close();
    }
  }

  reorder(e) {
    // console.log(e);
    if (!e.itemData.id) {
      this.notiService.showInfo('Cannot move the initial deposit');
    } else {
      const visibleRows = e.component.getVisibleRows();
      const newOrderIndex = visibleRows[e.toIndex].data.orderNo;
      if (!newOrderIndex) {
        this.notiService.showInfo('Cannot move above the initial deposit');
      } else {
        this.grid.instance.beginCustomLoading('Re-ordering');

        this.subscriptions.push(
          this.claimsService.moveJobClaim(this.jobService.currentJob.id, e.itemData.id, newOrderIndex).subscribe({
            next: () => {
              this.grid.instance.endCustomLoading();
              e.component.refresh();
            }, error: (err) => {
              this.notiService.notify(err);
              this.grid.instance.endCustomLoading();
            }
          })
        );
      }
    }
  }

  calcInvoiceDate(data) {
    if (data.id === 0) {
      // if deposit - look for income invoice type 1
      const incomeInvoiceFirstDate = this.claimsService.claimInvoices?.reduce((min, obj) => {
        if (obj.incomeInvoiceTypeId === 1 && obj.invoiceDate !== null) {
          const currentDate = new Date(obj.invoiceDate.substring(0, 10));
          if (min === null) {
            return obj;
          }
          return new Date(min.invoiceDate.substring(0, 10)) < currentDate ? min : obj;
        }
          return min;
      }, null);
      if (incomeInvoiceFirstDate) {
        return new Date(incomeInvoiceFirstDate.invoiceDate.substring(0, 10));
      }
      else
        return null;
    }
    const incomeInvoice = this.claimsService.claimInvoices?.find(i => i.claimJobLineId === data.id);
    if (incomeInvoice) {
      return new Date(incomeInvoice.invoiceDate.substring(0, 10));
    } else {
      return null;
    }
  }

  calcTotalPaid(data) {
    if (data.id === 0) {
      // if deposit - look for income invoice type 1
      const incomeInvoiceTotalPaid = this.claimsService.claimInvoices?.reduce((acc, obj) => acc + (obj.incomeInvoiceTypeId === 1 ? obj.totalPaid : 0), 0);
      return incomeInvoiceTotalPaid;
    }
    const incomeInvoice = this.claimsService.claimInvoices?.find(i => i.claimJobLineId === data.id);
    return incomeInvoice?.totalPaid;
  }

  calcLastPaidDate(data) {
    if (data.id === 0) {
      // if deposit - look for income invoice type 1
      const incomeInvoiceTotalPaid = this.claimsService.claimInvoices?.reduce((acc, obj) => acc + (obj.incomeInvoiceTypeId === 1 ? obj.totalPaid : 0), 0);
      if (incomeInvoiceTotalPaid === this.deposit) {
        const incomeInvoiceLastPaid = this.claimsService.claimInvoices?.reduce((max, obj) => {
          if (obj.incomeInvoiceTypeId === 1 && new Date(obj.lastPaidDate) > max) {
            return obj.lastPaidDate;
          }
          return max;
        }, new Date(null));
        return new Date(incomeInvoiceLastPaid);
      }
      else
        return null;
    }
    const incomeInvoice = this.claimsService.claimInvoices?.find(i => i.claimJobLineId === data.id);
    if (incomeInvoice && incomeInvoice.lastPaidDate) {
      return new Date(incomeInvoice.lastPaidDate);
    } else {
      return null;
    }
  }

  calculateAdjustedAmount(data) {
    const variationSplits = this.variationSplitsService.variationSplitsForJob.filter(i => i.jobLineId === data.id);
    let adjustedAmount = data.amount;
    if (variationSplits && variationSplits.length) {
      variationSplits.forEach(variationSplit => {
        adjustedAmount += variationSplit.amount;
      });
    }
    return adjustedAmount;
  }

  isButtonIconVisible = (e) => {
    if (this.jobsAdminPermission && e.row.data.id) {
      return true;
    }
    return false;
  }

  initNewRow = (e) => {
    e.data.isInvoiceToClient = false;
  }
  
  editorPreparing = (e) => {
      if((e.dataField==='raiseAtDate' ) && e.row.data.claimTriggerId!==6){
        e.editorOptions.disabled = true;
    }
  }

  setTriggerCellValue(rowData, value) {
    if (value) {
      rowData.claimTriggerId = value;
      let now = new Date();
      let year = now.getFullYear();
      let month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      let day = String(now.getDate()).padStart(2, '0');
      let fixedDate = `${year}-${month}-${day}`;
      rowData.raiseAtDate = value === 6  ? fixedDate : null; 
    }
  }

  setDateCellValue(rowData, value) {
   let newDate = value;
   let year = newDate.getFullYear();
   let month = String(newDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
   let day = String(newDate.getDate()).padStart(2, '0');
   let fixedDate = `${year}-${month}-${day}`;
   rowData.raiseAtDate = fixedDate;
  }

  calculateInvoiceToValue(data) {
    return data.variationSplitTypeId === 1 ? 'As per Claim' : data.isInvoiceToClient ? 'Direct to Client' : 'From Loan';
  }

  setInvoiceToCellValue(rowData, value, originalData) {
    rowData.isInvoiceToClient = value === 'Direct to Client';
  }

  setPercentCellValue(rowData, value) {
    rowData.percent = value;
    rowData.amount = value * this.contractValue / 100;
  }

  setAmountCellValue(rowData, value) {
    rowData.percent = Math.round(value / this.contractValue * 1000) / 10;
    rowData.amount = value;
  }

  calculatePercentValue(data) {
    return Math.round(data.amount / this.contractValue * 1000) / 10;
  }
}
