<!-- modal for delete -->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h2 class="modal-title">{{modalHeading}}</h2>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form (ngSubmit)="c('Close click')" #changeNameForm="ngForm" novalidate>
      <div class="row">
        <label class="form-label col-sm-2" for="optDescription">Description:</label>
        <input type="text" class="form-control-sm col-sm-7 marginleft15" name="optDescription" ngModel
          id="optDescription" [(ngModel)]="optDescription" readonly>
      </div>

      <div *ngIf="(optItemTypeId === ItemType.Note)" class="mt-3">
        <label class="form-label" for="optSelection">Note:</label>
        <textarea class="form-control noteEntryClass" name="optSelection" ngModel id="optSelection"
          [(ngModel)]="optSelection" readonly></textarea>
      </div>

      <button type="submit" class="btn btn-sm btn-primary mt-3">{{modalButton}}</button>
    </form>
  </div>
</ng-template>


<!-- modal for edit in separate modal to simplify for a user -->
<ng-template #contentEdit let-c="close" let-d="dismiss">

  <div class="modal-header">

    <h2 class="modal-title">{{modalHeading}}</h2>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <form (ngSubmit)="c('Close click')" #changeNameForm="ngForm" novalidate>

      <!-- description is always set-->
      <div class="row">
        <label class="form-label col-sm-2" for="optDescription">Description:</label>
        <input type="text" class="form-control-sm col-sm-8 ms-2" name="optDescription" ngModel id="optDescription"
          [(ngModel)]="optDescription">
      </div>

      <div *ngIf='(jobitem.itemTypeId === ItemType.Detail) && (jobitem.selectionTypeId === SelectionType.Dropdown)'
        class="row mt-2">
        <label class="form-label col-sm-2" for="selection">Selection:</label>
        <div id="selection" class="col-sm-8 marginTop0">
          <!-- next is the selection item linked to the option list -->
          <div ngbDropdown class="dropdownclassModal marginTop0 ms-2" autoClose="outside" #selectionDrop3="ngbDropdown">
            <button class="btn-sm btn-outline-primary wrapText" id="dropdownBasic3" ngbDropdownToggle
              (click)="$event.preventDefault(); $event.stopPropagation();">
              <span [class.bold]="isBoldText" [class.red]="optionColour === optionColourEnum.Red"
                [class.green]="optionColour === optionColourEnum.Green" class="wrapText">{{optSelection}}</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="dropdownmenuclass">
              <!-- until service returns only the ones required test for Ids equal -->
              <div *ngFor="let opt of optionList">
                <button *ngIf="!opt.notSelectable" class="dropdown-item"
                  (click)='$event.preventDefault(); selectionDrop3.close(); setSelectionModal(opt)'>
                  <span [class.bold]="opt.isBoldText" [class.red]="opt.optionColour === optionColourEnum.Red"
                    [class.green]="opt.optionColour === optionColourEnum.Green"
                    class="wrapText">{{opt.description}}</span>
                </button>
                <div *ngIf="opt.notSelectable" class="dropdown-item"><span [class.bold]="opt.isBoldText"
                    [class.red]="opt.optionColour === optionColourEnum.Red"
                    [class.green]="opt.optionColour === optionColourEnum.Green"
                    class="wrapText">{{opt.description}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- If manual selection show the box -->
      <div *ngIf='(!isOriginalSelectionInOptionList)' class="row">
        <label class="form-label col-sm-2" for="manualSelectionId"></label>
        <textarea jsFocusElement class="form-control-sm col-sm-8 noteEntryClass ms-2" name="manualSelectionId"
          type="text" [(ngModel)]="manualSelection"></textarea>
      </div>
      <div *ngIf="warningNote && warningNote !== ''" class="warningNoteClass">Warning: {{warningNote}}</div>

      <!-- Check Box -->
      <div *ngIf='(optItemTypeId === ItemType.Detail) && (selectionTypeId === SelectionType.Checkbox)' class="row">
        <label class="form-label labelTextClass" for="selectionId">Selection:</label>
        <input class="form-control-sm modalCheckBoxClass" name="selectionId" id="selectionId" type="checkbox"
          [(ngModel)]="optSelectionCheckbox" />
      </div>

      <!-- if Note then can change note stored in the selection field -->
      <div *ngIf="(optItemTypeId === ItemType.Note)" class="form-group">
        <div>
          <label class="form-label" for="optSelection">Note:</label>
          <textarea jsFocusElement class="form-control noteEntryClass" name="optSelection" ngModel id="optSelection"
            [(ngModel)]="optSelection"></textarea>
        </div>
      </div>

      <!-- if note select colour and is bold -->
      <div *ngIf="(optItemTypeId === ItemType.Note)" class="row mt-2">
        <label class="form-label col-sm-2" for="selection">Text Colour:</label>
        <div id="selection" class="form-control-sm col-sm-2">
          <!-- next is the selection item linked to the option list -->
          <div ngbDropdown>
            <button class="btn-sm btn-outline-primary" id="dropdownBasic2" ngbDropdownToggle
              (click)="$event.preventDefault(); $event.stopPropagation();">{{optNoteColourName}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
              <div *ngFor="let colour of colours">
                <button class="dropdown-item"
                  (click)='$event.preventDefault(); setColour(colour.id)'>{{colour.name}}</button>
              </div>
            </div>
          </div>
        </div>

        <label class="form-label col-sm-2" for="optIsBoldNote">Bold Text:
          <input type="checkbox" name="optIsBoldNote" ngModel id="optIsBoldNote" [(ngModel)]="optIsBoldNote"
            class="ms-1 marginTop10">
        </label>
        <label class="form-label col-sm-3" for="optIsItalicNote">Italic Text:
          <input type="checkbox" name="optIsItalicNote" ngModel id="optIsItalicNote" [(ngModel)]="optIsItalicNote"
            class="ms-1 marginTop10">
        </label>
      </div>

      <!-- Quantity if required on the option list -->
      <div *ngIf="isQtyRequired && !jobitem.isSetUpLine && !jobitem.hasLinkedItems && !headingIsSetUpLine"
        class="row mt-2 mb-1">
        <label class="form-label col-sm-2" for="quantity">Quantity:</label>
        <input class="form-control-sm col-sm-2 ms-2 rightText" ngModel id="quantity" name="quantity"
          [(ngModel)]="quantity" type="number" autocomplete="off" />
      </div>


      <!-- Price - Provisional Sum if required -->
      <div *ngIf="!jobitem.isSetUpLine && !jobitem.hasLinkedItems && !jobitem.linkedJobItemId && !headingIsSetUpLine
        && !(jobitem.itemTypeId === ItemType.Detail && jobitem.selectionTypeId === SelectionType.Checkbox)"
        class="row mb-1">

        <!-- Cost Type -->
        <label class="form-label col-sm-2">Master Type:</label>
        <div class="form-control-sm col-sm-2">
          <div ngbDropdown>
            <button class="btn-sm btn-outline-primary" id="dropdownBasicNew" ngbDropdownToggle
              (click)="$event.preventDefault();">{{costTypeDesc}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasicNew" class="dropdownmenuclass">
              <!-- until service returns only the ones required test for Ids equal -->
              <div *ngFor="let c of costTypeDropdown">
                <button class="dropdown-item" (click)='$event.preventDefault(); setCostType(+c.id)'>{{c.name}}</button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="costTypeId === costTypeEnum.Note" class="form-control-sm col-sm-4">
          <label class="form-label mt-1" for="isDoNotPrint">Hide Item from PDFs?
            <input type="checkbox" name="isDoNotPrint" ngModel id="isDoNotPrint" [(ngModel)]="isDoNotPrint"
              class="marginleft5">
          </label>
        </div>
      </div>

      <!-- now we do the provisional sum if set -->
      <div *ngIf="costTypeId === costTypeEnum.ProvisionalSum" class="row mb-1 mt-2">
        <label class="form-label col-sm-2 pe-0" for="provisionalSum">
          <span *ngIf="isQtyRequired">PS Rate:</span>
          <span *ngIf="!isQtyRequired">Provisional Sum:</span>
        </label>
        <input class="form-control-sm col-sm-2 ms-2" ngModel id="provisionalSum" name="provisionalSum"
          [(ngModel)]="provisionalSum" currencyMask [options]="{ precision: 0, prefix: '' }" autocomplete="off" />
      </div>

      <div *ngIf="costTypeId === costTypeEnum.ProvisionalSum && isQtyRequired" class="row mb-1">
        <!-- If Qty we show a line total -->
        <!-- Provisional Sum: {{quantity * provisionalSum | number}} -->
        <label class="form-label col-sm-2 pe-0" for="provisionalSumTotal">Provisional Sum:</label>
        <input class="form-control-sm col-sm-2 ms-2" ngModel id="provisionalSumTotal" name="provisionalSumTotal"
          [ngModel]="quantity * provisionalSum" currencyMask [options]="{ precision: 0, prefix: '' }" readonly />
      </div>

      <!-- updated 8-10-19 so that PS items must have a selection -->
      <div class="mt-2">
        <button type="submit" class="btn btn-sm btn-primary"
          [disabled]="costTypeId === costTypeEnum.ProvisionalSum && jobitem.itemTypeId === ItemType.Detail
        && jobitem.selectionTypeId === SelectionType.Dropdown && optSelection === 'Please Select...'">{{modalButton}}</button>
        <button *ngIf="jobLineSetupWrite" type="button" class="btn btn-sm btn-secondary modal2ndButton"
          (click)="c('Setup')">Settings</button>

        <!-- only allow an attachment to a note line (for the moment to keep the edit variation simple) -->
        <button *ngIf="optItemTypeId === ItemType.Note || optItemTypeId === ItemType.Detail" type="button"
          class="btn btn-sm btn-secondary modal2ndButton" (click)="c('AttachFile')">Attach File
        </button>
      </div>
    </form>
  </div>
</ng-template>

<div class="containerClass" [ngStyle]="{'width': 'calc(' + itemDescriptionAndSeletionWidth + 'px' + ')'}"
  [class.yellowBackground]="!isJobLocked && jobitem.highlightColourId === backgroundColourEnum.Yellow"
  [class.pinkBackground]="!isJobLocked && jobitem.highlightColourId === backgroundColourEnum.Pink"
  [class.blueBackground]="!isJobLocked && jobitem.highlightColourId === backgroundColourEnum.Blue">

  <!-- if Detail line -->
  <p *ngIf=" !jobitem.isHistoryRecord && jobitem.itemDescription && jobitem.itemDescription !==''"
    class=" descriptionClass" [ngStyle]="{'width': 'calc(' + itemDescWidth + 'px' + ')',
    'margin-left': 'calc(' + descWidthDeduct + 'px' + ')'}" [class.bold]="jobitem.isBoldNote"
    [class.italic]="jobitem.isItalicNote" [class.redNote]="jobitem.noteColour === optionColourEnum.Red"
    [class.greenNote]="jobitem.noteColour === optionColourEnum.Green">{{jobitem.itemDescription}}</p>

  <p *ngIf="jobitem.isHistoryRecord && jobitem.itemDescription && jobitem.itemDescription !== ''"
    class="descriptionClass strikeclass" [ngStyle]="{'width': 'calc(' + itemDescWidth + 'px' + ')',
    'margin-left': 'calc(' + descWidthDeduct + 'px' + ')'}">{{jobitem.itemDescription}}</p>

  <p *ngIf="!jobitem.isHistoryRecord && (!jobitem.itemDescription || jobitem.itemDescription === '')"
    [ngStyle]="{'width': 'calc(' + selectionWidth + 'px' + ')','margin-left': 'calc(' + descWidthDeduct + 'px' + ')'}"
    [class.bold]="jobitem.isBoldNote" [class.italic]="jobitem.isItalicNote"
    [class.redNote]="jobitem.noteColour === optionColourEnum.Red"
    [class.greenNote]="jobitem.noteColour === optionColourEnum.Green" class="descriptionClass
    noDescClass">{{jobitem.selection}}</p>

  <p *ngIf="jobitem.isHistoryRecord && (!jobitem.itemDescription || jobitem.itemDescription === '')"
    [ngStyle]="{'width': 'calc(' + selectionWidth + 'px' + ')','margin-left': 'calc(' + descWidthDeduct + 'px' + ')'}"
    class="descriptionClass strikeclass noDescClass">{{jobitem.selection}}</p>

  <div *ngIf="jobitem.itemDescription && jobitem.itemDescription !== ''"
    [ngStyle]="{'width': 'calc(' + selectionWidth + 'px)'}" class="selectionClass">

    <div *ngIf='(jobitem.itemTypeId === ItemType.Detail) && (jobitem.selectionTypeId === SelectionType.Dropdown)'>

      <div *ngIf="!(jobitem.isHistoryRecord)">
        <div *ngIf="(showAddEditButtons || (jobItemSetupLinesOnlyPerm && (jobitem.isSetUpLine || headingIsSetUpLine)))">
          <!-- next is the selection item linked to the option list -->
          <div ngbDropdown (openChange)="toggled($event, jobitem)" autoClose="true" #selectionDrop="ngbDropdown"
            placement="bottom-left">
            <button *ngIf="(jobitem.selection && jobitem.selection !== 'Please Select...')"
              class="btn-sm btn-outline-primary dropdownclass wrapText" id="dropdownBasic1" ngbDropdownToggle>
              <span [class.bold]="jobitem.isBoldText" [class.red]="jobitem.optionColour === optionColourEnum.Red"
                [class.green]="jobitem.optionColour === optionColourEnum.Green">{{jobitem.selection}}
                <span *ngIf="isPrintedDescDifferent" class="red">**</span></span>
            </button>
            <button *ngIf="(!jobitem.selection || jobitem.selection === 'Please Select...')"
              class="btn-sm btn-outline-primary dropdownclass red" id="dropdownBasic1" ngbDropdownToggle>Please
              Select...</button>
            <div *ngIf="!waitingAPI" ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdownmenuclass">
              <!-- until service returns only the ones required test for Ids equal -->
              <div *ngFor="let opt of optionList">
                <button *ngIf="opt.description !== 'Other - Click here' && !opt.notSelectable" class="dropdown-item"
                  (click)='setSelection(jobitem, opt);'>
                  <span [class.bold]="opt.isBoldText" [class.red]="opt.optionColour === optionColourEnum.Red"
                    [class.green]="opt.optionColour === optionColourEnum.Green"
                    class="wrapText">{{opt.description}}</span>
                </button>
                <div *ngIf="opt.description !== 'Other - Click here' && opt.notSelectable" class="dropdown-item">
                  <span [class.bold]="opt.isBoldText" [class.red]="opt.optionColour === optionColourEnum.Red"
                    [class.green]="opt.optionColour === optionColourEnum.Green"
                    class="wrapText">{{opt.description}}</span>
                </div>
                <button *ngIf="opt.description === 'Other - Click here'" class="dropdown-item"
                  (click)="editItem(contentEdit, jobitem, true);">
                  ** Other - Click here **
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="(!(showAddEditButtons || (jobItemSetupLinesOnlyPerm && (jobitem.isSetUpLine || headingIsSetUpLine))))
        && jobitem.selection && jobitem.selection !== ''" class="paddingleft2">
          <span [class.bold]="jobitem.isBoldText" [class.redNote]="jobitem.optionColour === optionColourEnum.Red"
            [class.greenNote]="jobitem.optionColour === optionColourEnum.Green">{{jobitem.selection}}
            <span *ngIf="isPrintedDescDifferent" class="red">**</span></span>
        </div>
        <div
          *ngIf="(!(showAddEditButtons || (jobItemSetupLinesOnlyPerm && (jobitem.isSetUpLine || headingIsSetUpLine))) && (!jobitem.selection || jobitem.selection === ''))"
          class="paddingleft2 bold">***** NOT SELECTED *****</div>
      </div>
      <div *ngIf="(jobitem.isHistoryRecord)" class="paddingleft2">
        <div *ngIf="!jobitem.itemNotTaken
        && (jobitem.selection && jobitem.selection !== '')" class="strikeclass">{{jobitem.selection}}</div>
        <div *ngIf="!jobitem.itemNotTaken
        && !(jobitem.selection && jobitem.selection !== '')" class="strikeclass">Please Select...</div>
      </div>
    </div>

    <div *ngIf='(jobitem.itemTypeId === ItemType.Detail) && (jobitem.selectionTypeId === SelectionType.Checkbox)'>
      <div
        *ngIf="(showAddEditButtons || (jobItemSetupLinesOnlyPerm && (jobitem.isSetUpLine || headingIsSetUpLine))) && !(jobitem.isHistoryRecord)">
        <div class="checkbox checkboxclass">
          <label class="checkboxclass">
            <input name="selected" type="checkbox" [(ngModel)]="jobitem.selection"
              (change)="setCheckBox(jobitem, jobitem.selection)" class="checkboxclass" [disabled]="waitingAPI">
          </label>
        </div>
      </div>
      <div
        *ngIf="(!(showAddEditButtons || (jobItemSetupLinesOnlyPerm && (jobitem.isSetUpLine || headingIsSetUpLine)))) || (jobitem.isHistoryRecord)"
        class="paddingleft2">
        <div *ngIf="(jobitem.isHistoryRecord)" class="strikeclass">
          <div *ngIf="jobitem.selection === 'Yes'">{{jobitem.selection}}</div>
          <div *ngIf="jobitem.selection === ''">No</div>
        </div>
        <div *ngIf="!(jobitem.isHistoryRecord)">
          <div *ngIf="jobitem.selection === 'Yes'">{{jobitem.selection}}</div>
          <div *ngIf="jobitem.selection === ''">No</div>
        </div>
      </div>
    </div>

    <!-- for items added in a VO that are selected from the options list -->
    <div *ngIf='(jobitem.itemTypeId === ItemType.Detail) && (!jobitem.selectionTypeId)'>
      <div *ngIf='(jobitem.isHistoryRecord)' class="paddingleft2 strikeclass">{{jobitem.selection}}</div>
      <div *ngIf='!(jobitem.isHistoryRecord)' class="paddingleft2">
        <span [class.bold]="jobitem.isBoldText" [class.redNote]="jobitem.optionColour === optionColourEnum.Red"
          [class.greenNote]="jobitem.optionColour === optionColourEnum.Green">{{jobitem.selection}}
          <span *ngIf="isPrintedDescDifferent" class="red">**</span></span>
      </div>
    </div>

    <!-- if Note line -->
    <p *ngIf='(jobitem.itemTypeId === ItemType.Note) && (jobitem.isHistoryRecord)' class="noteClassStrike" readonly>
      {{jobitem.selection}}</p>
    <p *ngIf='(jobitem.itemTypeId === ItemType.Note) && !(jobitem.isHistoryRecord)' class="noteClass" readonly>
      <span [class.bold]="jobitem.isBoldNote" [class.italic]="jobitem.isItalicNote"
        [class.redNote]="jobitem.noteColour === optionColourEnum.Red"
        [class.greenNote]="jobitem.noteColour === optionColourEnum.Green">{{jobitem.selection}}</span>
    </p>

    <span *ngIf="!jobitem.isHistoryRecord && showImages && !phoneSize">
      <js-show-image
        *ngIf="jobitem.optionListAttachmentId && (!jobitem.attachmentId || jobitem.attachmentTypeId === attachmentTypeEnum.PDF)"
        [imageId]=jobitem.selectedOptionId [manualItem]=false [refreshImage]="refreshImage"
        (emitImageClick)="selectionDropDownOpen()">
      </js-show-image>
      <js-show-image *ngIf=" !jobitem.optionListAttachmentId && (!jobitem.attachmentId ||
        jobitem.attachmentTypeId===attachmentTypeEnum.PDF) && jobitem.optionImageId" [imageId]=jobitem.optionImageId
        [manualItem]=false [imageLibrary]=true [refreshImage]="refreshImage" (emitImageClick)="selectionDropDownOpen()">
      </js-show-image>
      <js-show-image *ngIf=" jobitem.attachmentId && jobitem.attachmentTypeId !==attachmentTypeEnum.PDF"
        [imageId]=jobitem.attachmentId [manualItem]=true [attachmentVariationId]=jobitem.attachmentVariationId
        [refreshImage]="refreshImage" (emitImageClick)="selectionDropDownOpen()">
      </js-show-image>
    </span>
  </div>

  <!-- show the quantity -->
  <div *ngIf="jobitem.isQtyRequired && !phoneSize && quantityWidth" class="qtyClass"
    [ngStyle]="{'width': 'calc(' + quantityWidth + 'px)'}" [class.strikeclass]="jobitem.isHistoryRecord">
    {{displayQuantity}}
  </div>

  <!-- Show PS Amounts -->
  <div *ngIf="!phoneSize && jobitem.costTypeId !== costTypeEnum.PSFinalised && jobitem.provisionalSum && !isAssociate"
    class="priceClass" [class.strikeclass]="jobitem.changeTypeId === changeTypeEnum.Delete">
    <span *ngIf="_authService.canSeeAmountsPermission">PS {{jobitem.provisionalSum | number}}</span>
    <span *ngIf="!_authService.canSeeAmountsPermission">PS Applicable</span>
  </div>
  <div *ngIf='!phoneSize && jobitem.costTypeId === costTypeEnum.PSFinalised && !isAssociate' class="priceClass">
    <span *ngIf='!jobitem.hideFixedPSum'>PS Fixed</span><span *ngIf='jobitem.hideFixedPSum'>Fixed-Hidden</span>
  </div>

  <div *ngIf='jobitem.costTypeId === costTypeEnum.ByOwner' class="priceClass">
    <span [class.strikeclass]="jobitem.isHistoryRecord">By Owner</span>
  </div>
  <div *ngIf='jobitem.costTypeId === costTypeEnum.Excluded' class="priceClass">
    <span [class.strikeclass]="jobitem.isHistoryRecord">Excluded</span>
  </div>

  <!-- if we have a qty we need to allow space to line things up -->
  <div *ngIf='showPriceSpace' class="priceClass"></div>

  <div class="buttonsClass" [ngStyle]="{'width': 'calc(' + buttonsWidth + 'px)'}">
    <div *ngIf="showAddEditButtons && !phoneSize && !jobitem.isHistoryRecord">
      <span *ngIf="jobitem.isDeleted">hidden</span>
      <button class='btn btn-sm btn-outline-primary btnline'
        (click)='$event.preventDefault(); $event.stopPropagation(); deleteItem(content, jobitem)'>
        <i class="material-icons">delete_forever</i>
      </button>
      <button class='btn btn-sm btn-outline-primary btnline'
        (click)='$event.preventDefault(); $event.stopPropagation(); editItem(contentEdit, jobitem, false)'>
        <i class="material-icons">create</i>
      </button>
      <button class='btn btn-sm btn-outline-primary btnline' (click)='showComment()'>
        <i class="material-icons" [class.orange]="jobitem.commentTypeId === jobItemCommentTypeEnum.Normal"
          [class.red]="jobitem.commentTypeId === jobItemCommentTypeEnum.Important">note</i>
      </button>

      <button *ngIf="!isJobLocked && !isClient && !isAssociate"
        class='btn btn-sm btn-outline-primary btnline titleBtn'
        [class.yellowButton]="!isJobLocked && jobitem.highlightColourId === backgroundColourEnum.Yellow"
        [class.pinkButton]="!isJobLocked && jobitem.highlightColourId === backgroundColourEnum.Pink"
        [class.blueButton]="!isJobLocked && jobitem.highlightColourId === backgroundColourEnum.Blue"
        (click)='$event.preventDefault(); $event.stopPropagation(); changeBackground()'>
        <i class="material-icons">flag</i>
      </button>

      <button *ngIf="jobitem.attachmentId" class='btn btn-sm btn-outline-primary btnline'
        (click)='$event.preventDefault(); $event.stopPropagation(); showAttachment(jobitem)'>
        <i class="material-icons">attach_file</i>
      </button>
    </div>

    <div
      *ngIf="jobitem.isHistoryRecord || showHistoryAsUnDeletedItem || (_jobItemService.showHistoryOfChanges && jobitem.changeTypeId === changeTypeEnum.Add)">
      <span class="btnline historyClass"
        (click)='clickToVariation(jobitem.changedByVO, jobitem.itemDescription, jobitem.selection)'>
        <span *ngIf="(jobitem.changeTypeId === changeTypeEnum.Delete)">-</span><span
          *ngIf="showHistoryAsUnDeletedItem || jobitem.changeTypeId === changeTypeEnum.Add">+</span>V#
        {{variationTypeChar}}
      </span>
    </div>

    <div *ngIf="!showAddEditButtons || phoneSize">
      <button *ngIf="(jobitem.commentTypeId || selectionsAdmin) && !isAssociate && !isClient"
        class='btn btn-sm btn-outline-primary btnline' (click)='showComment()'>
        <i class="material-icons material-icons-note"
          [class.orange]="jobitem.commentTypeId === jobItemCommentTypeEnum.Normal"
          [class.red]="jobitem.commentTypeId === jobItemCommentTypeEnum.Important">note</i>
      </button>

      <button *ngIf="(jobitem.attachmentId) && jobitem.attachmentTypeId === attachmentTypeEnum.PDF"
        class='btn btn-sm btn-outline-primary imageButton'
        (click)='$event.preventDefault(); $event.stopPropagation(); showAttachmentReadOnly(jobitem)'>
        <i class="material-icons">attach_file</i>
      </button>

      <button *ngIf="!(jobitem.isHistoryRecord) && isEstimatingAttachmentsExist && !phoneSize"
        class='btn btn-sm btn-outline-primary imageButton'
        (click)='clickToVariation(jobitem.changedByVO, jobitem.itemDescription, jobitem.selection)'
        matTooltip="Estimating Attachments Exist" matTooltipPosition="above" [matTooltipShowDelay]="500">
        <i class="material-icons orange">attach_file</i>
      </button>
    </div>
  </div>

  <div *ngIf="phoneSize" class="floatMiddle">
    <js-show-image
      *ngIf="!jobitem.isHistoryRecord && showImages && jobitem.optionListAttachmentId && (!jobitem.attachmentId || jobitem.attachmentTypeId === attachmentTypeEnum.PDF)"
      [imageId]=jobitem.selectedOptionId [manualItem]=false [refreshImage]="refreshImage">
    </js-show-image>
    <js-show-image
      *ngIf="!jobitem.isHistoryRecord && showImages && !jobitem.optionListAttachmentId && (!jobitem.attachmentId || jobitem.attachmentTypeId === attachmentTypeEnum.PDF) && jobitem.optionImageId"
      [imageId]=jobitem.optionImageId [manualItem]=false [imageLibrary]=true [refreshImage]="refreshImage">
    </js-show-image>
    <js-show-image
      *ngIf="!jobitem.isHistoryRecord && showImages && jobitem.attachmentId && jobitem.attachmentTypeId !== attachmentTypeEnum.PDF"
      [imageId]=jobitem.attachmentId [manualItem]=true [attachmentVariationId]=jobitem.attachmentVariationId
      [refreshImage]="refreshImage">
    </js-show-image>
  </div>

</div>
