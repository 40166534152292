import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { IOptionListHeader } from '../../dtos/option-list-header';
import { GlobalService } from '../global.service';
import { IOptionList } from '../../dtos/option-list';
import { JobService } from './job.service';
import { CostTypeEnum } from '../../dtos/cost-type.enum';
import { HttpService } from '../http.service';


@Injectable()
export class OptionListService {

  private treeOptionNodesSetup = false;
  private treeOptionNodes: IOptionListHeader[] = [];

  private treeHouseOptionNodes: any[] = [];
  private treeHouseOptionJob: string;
  private costTypeEnum = CostTypeEnum;
  optionListsRaw: IOptionListHeader[] = []; // this will be the new method of getting option lists

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private _jobService: JobService,
    private globalService: GlobalService) { }

  setCurrentOptionNodes(treeOptionNodes: IOptionListHeader[]) {
    this.treeOptionNodes = treeOptionNodes;

    // 17-7-19 GH if sales date we can't have Included so we treat as priced
    if (treeOptionNodes && this._jobService.currentJob && this._jobService.currentJob.salesDate) {
      this.treeOptionNodes.forEach(element => {
        if (element.salesPriceTypeIfAddedId === this.costTypeEnum.Included) {
          element.salesPriceTypeIfAddedId = null;
        }
        if (element.salesPriceTypeIfChangedInSameListId === this.costTypeEnum.Included) {
          element.salesPriceTypeIfChangedInSameListId = null;
        }
        if (element.children) {
          this.setIncludedToNull(element.children);
        }
      });
    }
    this.treeOptionNodesSetup = true;
  }

  private setIncludedToNull(childNodes: IOptionListHeader[]) {
    childNodes.forEach(element => {
      if (element.salesPriceTypeIfAddedId === this.costTypeEnum.Included) {
        element.salesPriceTypeIfAddedId = null;
      }
      if (element.salesPriceTypeIfChangedInSameListId === this.costTypeEnum.Included) {
        element.salesPriceTypeIfChangedInSameListId = null;
      }
      if (element.children) {
        this.setIncludedToNull(element.children);
      }
    });
  }

  getCurrentOptionNodes() {
    if (!this.treeOptionNodesSetup) {
      return null;
    } else {
      return this.treeOptionNodes;
    }
  }

  resetCurrentOptionNodes() {
    this.treeOptionNodesSetup = false;
  }

  setCurrentHouseOptionNodes(job: string, treeOptionNodes: any[]) {
    this.treeHouseOptionNodes = treeOptionNodes;
    this.treeHouseOptionJob = job;
  }

  getCurrentHouseOptionNodes(job: string) {
    if (this.treeHouseOptionJob !== job) {
      return null;
    } else {
      return this.treeHouseOptionNodes;
    }
  }

  getOptionListChildrenOnly(id: number, getChildren: boolean,
    getHouseOptions: boolean | null, houseTypeId: number = null): Observable<IOptionListHeader[]> {
    this.globalService.setIsGetChildren(getChildren);

    let url = this.globalService.getApiUrl() + '/option-lists?includeInactive=false';

    if (id !== 0) {
      url += '&optionListIdAbove=' + id;
    }
    if (getChildren) {
      url += '&includeChildren=true';
    }
    if (getHouseOptions !== null) {
      url += '&getHouseOptions=' + getHouseOptions;
    }
    if (houseTypeId !== null) {
      url += '&houseTypeId=' + houseTypeId;
    }

    return this._http.get<IOptionListHeader[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getOptionListChildren(id: number, getChildren: boolean,
    getHouseOptions: boolean | null, houseTypeId: number = null): Observable<IOptionListHeader[]> {
    return forkJoin(
      [this.getOptionListChildrenOnly(id, getChildren, getHouseOptions, houseTypeId),
      this.getOptionListsRaw(true)]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  // To remove - can use getOptionListChildren() instead
  getOptionListWithoutChildren(id): Observable<IOptionList[]> {
    this.globalService.setIsGetChildren(true);
    return this._http.get<IOptionList[]>(this.globalService.getApiUrl() +
      '/option-lists?optionListIdAbove=' + id + '&includeChildren=false',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }


  // To remove - can use getOptionListChildren() instead
  getOptionListsRaw(activeOnly: boolean = true): Observable<IOptionListHeader[]> {
    var url = this.globalService.getApiUrl() + '/option-lists/raw?activeOnly=' + activeOnly;

    if (this._jobService.currentJob.houseTypeId) {
      url += '&houseTypeId=' + this._jobService.currentJob.houseTypeId;
    }

    return this._http.get<IOptionListHeader[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.optionListsRaw = res;
      }),
      catchError(this.handleError));
  }

  getOption(id) {
    this.globalService.setIsGetChildren(false); // purely used to set correct header? should switch between options in this service only
    return this._http.get<IOptionListHeader>(this.globalService.getApiUrl() + '/option-lists/' + id,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addOption(option: any) {
    const url = this.globalService.getApiUrl() + '/option-lists';
    return this._http.post<IOptionListHeader>(url, JSON.stringify(option), this.httpService.getHttpOptions());
  }

  updateOption(option: any) {
    const url = this.globalService.getApiUrl() + '/option-lists/' + option.id;
    return this._http.patch(url, option, this.httpService.getHttpOptions());
  }

  moveOption(id, parent, index) {
    let url = null;
    if (parent) {
      url = this.globalService.getApiUrl() + '/option-lists/move/' + id + '?optionListIdAbove=' + parent + '&orderNo=' + index;
    } else {
      url = this.globalService.getApiUrl() + '/option-lists/move/' + id + '?&orderNo=' + index;
    }
    const params = {
      optionListIdAbove: parent,
      orderNo: index
    };
    return this._http.patch(url, params, this.httpService.getHttpOptions());
  }

  deleteOption(optionId: number) {
    const url = this.globalService.getApiUrl() + '/option-lists/' + optionId;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }



  getHouseOptionList(): Observable<IOptionList[]> {
    return this._http.get<IOptionList[]>(this.globalService.getApiUrl() + '/option-lists/house-options-export', this.httpService.getHttpOptions());
  }


  private handleError(err: HttpErrorResponse) {
    console.log(err);
    return observableThrowError(err);
  }
}
