import { VariationSplitsService } from './../services/felixApi/variation-splits.service';
import { CompanyService } from './../services/felixApi/company.service';
import { UserService } from './../services/felixApi/user.service';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalService } from '../services/global.service';
import { JobService } from '../services/felixApi/job.service';
import { VariationService } from '../services/felixApi/variation.service';
import { VariationStatusEnum } from '../dtos/variation-status.enum';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobSearchTypeEnum } from '../dtos/job-search-type.enum';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { JobItemService } from '../services/felixApi/job-item.service';
import { IJob } from '../dtos/job';
import { CompanyActivity } from '../dtos/company-activity';
import { User } from '../dtos/user';
import { Variation } from '../dtos/variation';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { SelectionReportModalComponent } from '../selections/selection-modals/selection-report-modal.component';
import { ShowPdfComponent } from '../shared/show-pdf.component';
import { JobRolesComponent } from '../shared/job-roles/job-roles.component';
import { StatementComponent } from '../jobs/job-modals/statement/statement.component';

@Component({
  selector: 'js-variationlist',
  templateUrl: './variationlist.component.html',
  styleUrls: ['./variationlist.component.scss']
})
export class VariationlistComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  formErrorMessage: string;
  jobString: string;
  variationStatus = VariationStatusEnum;
  loading = false;
  grandTotal: number;
  salesVariationsExist = false;
  salesVariationTotal = 0;
  salesPrice: number;
  preContractVariationsExist = false;
  preContractVariationTotal = 0;
  contractPrice: number;
  postContractVariationsExist = false;
  postContractVariationTotal = 0;
  jobSearchTypeEnum = JobSearchTypeEnum; // when we do a job search we can specify if we want to get the templates or all jobs initially
  closeResult = '';
  // permissionLevel = '';
  permissionWrite = false;
  isRestrictedForm = false; // if Client or Trade or the screen width is too small then we restrict the form
  innerWidth: number; // width of form
  isAssociate = false; // are we a trade
  basePrice: number;
  canSeeAmountsPerm: boolean;
  isPendingVariations: boolean;
  isSalesVariationsPending: boolean;
  isPreContractVariationsPending: boolean;
  isPostContractVariationsPending: boolean;
  showBackgroundLogo: boolean;
  salesPermissionLevel: string;
  preContractPermissionLevel: string;
  postContractPermissionLevel: string;
  salesReadPermission: boolean;
  preContractReadPermission: boolean;
  postContractReadPermission: boolean;
  salesVariationsActive = false;
  canLockJob = false;
  currentJob: IJob;
  isClient: boolean;
  tableMaxHeight: number;
  activities: CompanyActivity[];

  statuses = [
    { id: 1, description: 'Open' },
    { id: 2, description: 'Estimating' },
    { id: 5, description: 'Pending Approval' },
    { id: 6, description: 'Cancelled' },
    { id: 7, description: 'Approved' },
    { id: 8, description: 'Actioned' },
    { id: 9, description: 'Invoiced' }
  ];
  users: User[];
  variations: Variation[];
  isConstructionActivitiesUsed: boolean;
  showAddButton = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.calcIsRestrictedForm();
  }

  constructor(private _jobService: JobService,
    private companyService: CompanyService,
    private userService: UserService,
    public _variationService: VariationService,
    private variatonSplitService: VariationSplitsService,
    public _jobItemService: JobItemService,
    private _authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private _globalService: GlobalService,
    private notiService: NotificationService) {
    this.openHeader = this.openHeader.bind(this);
    this.getClaimActivity = this.getClaimActivity.bind(this);
    this.gotoSelections = this.gotoSelections.bind(this);
  }

  ngOnInit() {
    this._variationService.currentVariations = [];
    this.isAssociate = this._authService.isAssociate();
    this.isClient = this._authService.isClient();

    if (this._globalService.getCompanyConfigValue(ConfigurationEnum.ConstructionActivitiesUsed) === 1) {
      this.isConstructionActivitiesUsed = true;
    }

    this.innerWidth = window.innerWidth;
    this.getData();
    this.calcIsRestrictedForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getData() {
    this.subscriptions = this.subscriptions.concat(
      this.userService.getUsersAndDivisions(false).subscribe({
        next: () => {
          this.activities = this.companyService.activities;
          this.users = this.userService.users;
        },
        error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  calcIsRestrictedForm() {
    if (this._authService.isClient() || this._authService.isAssociate() || this.innerWidth < 600) {
      this.isRestrictedForm = true;
    } else {
      this.isRestrictedForm = false;
    }

    if (this.currentJob?.warningNote && this.currentJob?.warningNote !== '') {
      this.tableMaxHeight = window.innerHeight - 187;
    } else {
      this.tableMaxHeight = window.innerHeight - 147;
    }

    if (this.salesVariationsActive && this.canLockJob) {
      this.tableMaxHeight -= 50;
    }

    this.showAddButton = true;
    if (this._authService.isClient() || this._authService.isAssociate() || window.innerHeight < 500) {
      this.tableMaxHeight += 40;
      this.showAddButton = false;
    }

    if (this.currentJob?.masterJobId && this.currentJob?.useMasterJobAddenda) {
      this.tableMaxHeight += 40;
      this.showAddButton = false;
      this.notiService.showInfo('This job is using the master job addenda. Variations are not allowed.');
    }
  }

  calcPermission() {
    if (this._globalService.getCurrentCompany().salesVariationsActive) {
      this.salesVariationsActive = true;
    }
    this.canLockJob = false; // reset in case of job number change
    let permissionJobLineSetup = this._authService.getSelectionsPermissions('JobLineSetup');
    if (permissionJobLineSetup === 'Admin' || this._authService.isAdminOrSuperUser()) {
      if (!this._jobService.currentJob.isLocked && this._jobService.currentJob.salesDate) {
        this.canLockJob = true;
      }
    }
    permissionJobLineSetup = this._authService.getSelectionsPermissions('JobItemSetupLinesOnly');
    if (permissionJobLineSetup === 'Admin') {
      if (!this._jobService.currentJob.isLocked && this._jobService.currentJob.salesDate) {
        this.canLockJob = true;
      }
    }

    if (this._authService.isAdminOrSuperUser()) {
      this.salesReadPermission = true;
      this.preContractReadPermission = true;
      this.postContractReadPermission = true;
      this.permissionWrite = true;
      this.canSeeAmountsPerm = true;
    } else if (this._authService.isClient()) {
      this.salesReadPermission = true;
      this.preContractReadPermission = true;
      this.postContractReadPermission = true;
      this.canSeeAmountsPerm = true;
    } else if (this._authService.isAssociate()) {
      this.preContractReadPermission = true;
      this.postContractReadPermission = true;
    } else {

      // Sales Permission
      this.salesPermissionLevel = this._authService.getSelectionsPermissions('SalesVariations');
      if (this.salesPermissionLevel === 'Admin' || this.salesPermissionLevel === 'Write') {
        if (!this._jobService.currentJob.salesDate) {
          this.permissionWrite = true;
        }
        this.salesReadPermission = true;
      } else if (this.salesPermissionLevel === 'Read') {
        this.salesReadPermission = true;
      }

      // Pre-Contract Permission
      this.preContractPermissionLevel = this._authService.getSelectionsPermissions('PreContractVariations');
      if (this.preContractPermissionLevel === 'Admin' || this.preContractPermissionLevel === 'Write') {
        if (this._jobService.currentJob.salesDate && !this._jobService.currentJob.isLocked) {
          this.permissionWrite = true;
        }
        this.preContractReadPermission = true;
      } else if (this.preContractPermissionLevel === 'Read') {
        this.preContractReadPermission = true;
      }

      // Post-Contract Permission
      this.postContractPermissionLevel = this._authService.getSelectionsPermissions('Variations');
      if (this.postContractPermissionLevel === 'Admin' || this.postContractPermissionLevel === 'Write') {
        if (this._jobService.currentJob.isLocked) {
          this.permissionWrite = true;
        }
        this.postContractReadPermission = true;
      } else if (this.postContractPermissionLevel === 'Read') {
        this.postContractReadPermission = true;
      }

      // Permission to see amounts
      if (this.permissionWrite) {
        this.canSeeAmountsPerm = true;
      } else {
        const canSeeAmountsPermission = this._authService.getSelectionsPermissions('CanReadAmounts');
        if (canSeeAmountsPermission !== 'none') {
          this.canSeeAmountsPerm = true;
        } else {
          this.canSeeAmountsPerm = false;
        }
      }
    }
  }

  refreshJobNumber(jobNo: string) {
    this.clearValues();
    if (jobNo) {
      if (this._jobService.currentJob) {
        this.currentJob = this._jobService.currentJob;
        this.calcPermission();
        this.getVariations();
      }
    } else {
      this.showBackgroundLogo = true;
    }
  }

  clearValues() {
    this.currentJob = null;
    this.salesVariationTotal = 0;
    this.salesPrice = 0;
    this.salesVariationsExist = false;
    this.preContractVariationTotal = 0;
    this.preContractVariationsExist = false;
    this.contractPrice = 0;
    this.postContractVariationTotal = 0;
    this.postContractVariationsExist = false;
    this.isPendingVariations = false;
    this.isSalesVariationsPending = false;
    this.isPreContractVariationsPending = false;
    this.isPostContractVariationsPending = false;
    this.grandTotal = 0;
    this._variationService.currentVariations = [];
    this.showBackgroundLogo = false;
  }

  pad(offset, length): string {
    let str = '' + offset;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

  getVariations() {
    this.loading = true;
    this.currentJob = this._jobService.currentJob;
    this.basePrice = this._jobService.currentJob.basePrice;

    this.calcIsRestrictedForm();

    this.subscriptions = this.subscriptions.concat(
      this.variatonSplitService.getVariationsWithSplits(this.currentJob.jobNumber, this.currentJob.id).subscribe({
        next: (variations) => {
          this._variationService.currentVariations = variations;
          if (!this._variationService.currentVariations.length) {
            this.formErrorMessage = 'No variations found for given job';
            this.showBackgroundLogo = false;
          } else {
            this.formErrorMessage = null;
            this.showBackgroundLogo = false;
            for (let i = 0; i < this._variationService.currentVariations.length; i++) {
              if (this._variationService.currentVariations[i].statusId !== this.variationStatus.Cancelled) {
                if (this._variationService.currentVariations[i].variationType > 10
                  && this._variationService.currentVariations[i].variationType < 20) {
                  this.salesVariationTotal += this._variationService.currentVariations[i].variationTotal;
                }
                if (this._variationService.currentVariations[i].variationType > 20) {
                  this.preContractVariationTotal += this._variationService.currentVariations[i].variationTotal;
                }
                if (this._variationService.currentVariations[i].variationType < 10) {
                  this.postContractVariationTotal += this._variationService.currentVariations[i].variationTotal;
                }
                this.basePrice += this._variationService.currentVariations[i].includedItemsTotal;

                if (this._variationService.currentVariations[i].statusId < this.variationStatus.Cancelled) {
                  this.isPendingVariations = true;
                }
              }

              if (this._variationService.currentVariations[i].variationType > 10
                && this._variationService.currentVariations[i].variationType < 20) {
                this.salesVariationsExist = true;
                if (this._variationService.currentVariations[i].statusId < this.variationStatus.Cancelled) {
                  this.isSalesVariationsPending = true;
                }
              }

              if (this._variationService.currentVariations[i].variationType > 20) {
                this.preContractVariationsExist = true;
                if (this._variationService.currentVariations[i].statusId < this.variationStatus.Cancelled) {
                  this.isPreContractVariationsPending = true;
                }
              }

              if (this._variationService.currentVariations[i].variationType < 10) {
                this.postContractVariationsExist = true;
                if (this._variationService.currentVariations[i].statusId < this.variationStatus.Cancelled) {
                  this.isPostContractVariationsPending = true;
                }
              }
            }
            this.salesPrice = this.basePrice + this.salesVariationTotal;
            this.contractPrice = this.salesPrice + this.preContractVariationTotal;
            this.grandTotal = this.contractPrice + this.postContractVariationTotal;

            this.variations = [];

            if (!this.isAssociate) {
              this.variations.push(new Variation(0, 'Base Price', this.basePrice));

              if (this.salesVariationsExist) {
                this.variations.push(new Variation(-1, 'Sales Variations', 0));
                this.variations = this.variations
                  .concat(this._variationService.currentVariations.filter(i => i.variationType > 10 && i.variationType < 20));
                this.variations.push(new Variation(0, 'Sales Variations', this.salesVariationTotal));

                this.variations.push(new Variation(0, 'Sales Price', this.salesPrice));
              }

              if (this.preContractVariationsExist) {
                this.variations.push(new Variation(-1, 'Pre-Contract Variations', 0));
                this.variations = this.variations
                  .concat(this._variationService.currentVariations.filter(i => i.variationType > 20));
                this.variations.push(new Variation(0, 'Pre-Contract Variations', this.preContractVariationTotal));
              }

              if (this._jobService.currentJob.salesDate) {
                this.variations.push(new Variation(0, 'Contract Price', this.contractPrice));
              }
            }

            if (this.postContractVariationsExist) {
              this.variations.push(new Variation(-1, 'Post-Contract Variations', 0));
              this.variations = this.variations
                .concat(this._variationService.currentVariations.filter(i => i.variationType < 10));
              if (!this.isAssociate) {
                this.variations.push(new Variation(0, 'Post-Contract Variations', this.postContractVariationTotal));
              }
            }

            if (!this.isAssociate && this.postContractVariationsExist) {
              this.variations.push(new Variation(0, 'Grand Total', this.grandTotal));
            }
          }
          this.loading = false;
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  addVariation() {
    if (!this._jobService.currentJob) {
      this.notiService.showWarning('No valid job selected.');
    } else if (this._jobService.currentJob.salesQuoteSignedDate && !this._jobService.currentJob.salesDate) {
      this.notiService.showWarning('Sales Quote is signed but no Sales date entered.');
    } else if (this._jobService.currentJob.contractSignedDate && !this._jobService.currentJob.isLocked) {
      this.notiService.showWarning('Contract signed but job not locked. Lock job to then be able to create post-contract variations');
    } else {
      this._jobService.currentVariationId = 0;
      this.router.navigate(['variationdetail']);
    }
  }

  openVariation(cellInfo: any) {
    // const variation = this._variationService.currentVariations.find(i => i.displayedVariationNumber === varNum);
    this._jobService.currentVariationId = cellInfo.data.variationNumber;
    this.router.navigate(['variationdetail']);
  }

  gotoSelections(cellInfo: any) {
    // goto selections for this VO
    this._jobService.currentVariationId = cellInfo.data.variationNumber;
    this._jobItemService.currentVariation = cellInfo.data;
    this.router.navigate(['selections']);
  }

  createContract() {
    // route to the process to accept items into the contract and lock it
    // all variations need to be approved
    let foundUnApprovedVO = false;
    this._variationService.currentVariations.forEach(vo => {
      if (vo.statusId < this.variationStatus.Cancelled) {
        foundUnApprovedVO = true;
      }
    });

    if (foundUnApprovedVO) {
      // alert('There are unapproved variations. Please approve or cancel.');
      this.notiService.showWarning('There are unapproved variations. Please approve or cancel.');
    } else if (!this._jobService.currentJob.salesDate) {
      this.notiService.showWarning('Please enter a sales date before locking the job and creating the contract version.');
    } else {
      this.router.navigate(['acceptIntoContract']);
    }
  }

  openHeader(e) {
    this.openVariation(e.row);
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      if (e.data.statusId <= VariationStatusEnum.Estimating) {
        // open in green
        e.rowElement.style.backgroundColor = '#d4e9d4';
      } else if (e.data.statusId === VariationStatusEnum.PendingApproval) {
        // pending in pale yellow
        e.rowElement.style.backgroundColor = 'rgb(243, 243, 197)';
      } else if (e.data.statusId === VariationStatusEnum.Actioned) {
        // light orange
        e.rowElement.style.backgroundColor = 'rgb(249, 242, 234)';
      } else if (e.data.statusId === VariationStatusEnum.Invoiced) {
        // light blue
        e.rowElement.style.backgroundColor = 'rgb(240, 247, 253)';
      }
    }
  }

  onCellPrepared(e) {
    if (e.rowType === 'data') {
      if (e.data.variationNumber <= 0) {
        if (e.data.variationNumber === 0 && e.column.dataField === 'title') {
          e.cellElement.style.textAlign = 'end';
        }
        e.cellElement.style.color = 'black';
        e.cellElement.style.fontWeight = 'bold';
        e.cellElement.style.fontSize = '9pt';
      } else if (e.data.statusId === VariationStatusEnum.Cancelled) {
        if (e.column.dataField === 'variationTotal') {
          e.cellElement.style.textDecoration = 'line-through';
        } else if (e.column.dataField === 'statusId') {
          e.cellElement.style.color = 'red';
        }
      }

      if (e.column.dataField === 'variationTotal' && e.data.variationTotal < 0) {
        e.cellElement.style.color = 'red';
      }
    }
  }

  isEditIconVisible = (e) => {
    return e.row?.data?.variationNumber > 0;
  }

  getClaimActivity(cellInfo) {
    if (!cellInfo?.data?.variationType || cellInfo.data.variationType > 10) {
      // only for post contract
      return '';
    }

    const splits = this.variatonSplitService.variationSplitsForJob?.filter(i => i.jobVariationId === cellInfo.data.id);
    if (splits && splits.length) {
      return '* Splits *';
    } else {
      return this.activities?.find(i => i.id === cellInfo.data.companyActivityId)?.description;
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    if (!this.isClient && !this.isAssociate && this.canSeeAmountsPerm) {
      if (this.canSeeAmountsPerm) {
        toolbarItems.push({
          location: 'before',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            text: 'Sales Quote',
            onClick: this.getSalesQuote.bind(this)
          }
        });

        if (this.currentJob.salesDate) {
          toolbarItems.push({
            location: 'before',
            locateInMenu: 'auto',
            widget: 'dxButton',
            options: {
              text: 'Contract Quote',
              onClick: this.getContractQuote.bind(this)
            }
          });
        }
      }

      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            text: 'Statement',
            onClick: this.getStatement.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            icon: 'user',
            onClick: this.showJobRoles.bind(this)
          }
        });
    } else if (this.isClient) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            text: 'Statement',
            onClick: this.getStatement.bind(this)
          }
        });
    }
  }

  getSalesQuote() {
    this.getSalesQuotePDF(true);
  }

  getContractQuote() {
    this.getSalesQuotePDF(false);
  }

  getSalesQuotePDF(isSalesQuote: boolean) {
    const modalRef = this.modalService.open(SelectionReportModalComponent);
    modalRef.componentInstance.exportToExcel = false;
    if (isSalesQuote) {
      modalRef.componentInstance.isSalesQuote = true;
      modalRef.componentInstance.isContractQuote = false;
    } else {
      modalRef.componentInstance.isSalesQuote = false;
      modalRef.componentInstance.isContractQuote = true;
    }

    modalRef.result.then((returnResult) => {
      this.closeResult = 'Closed';
      if (returnResult.runReport) {
        // show the attachment but read only so open full screen
        const modalRef1 = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
        modalRef1.componentInstance.printImages = returnResult.printImages;
        modalRef1.componentInstance.printPrices = returnResult.printPrices;
        modalRef1.componentInstance.printNotApplicable = returnResult.printNotApplicable;
        modalRef1.componentInstance.printNonPrintItems = returnResult.printNonPrintItems;
        modalRef1.componentInstance.printConnectedTogether = returnResult.printConnectedTogether;
        modalRef1.componentInstance.printVONumber = returnResult.printVONumber;
        modalRef1.componentInstance.exportToExcel = false;
        modalRef1.componentInstance.printProvisionalSums = returnResult.printProvisionalSums;
        modalRef1.componentInstance.isSalesQuote = true;
        modalRef1.componentInstance.printWholeAddendum = returnResult.printWholeAddendum;
        modalRef1.componentInstance.printItemsNotTaken = returnResult.printItemsNotTaken;
        modalRef1.componentInstance.printChangeHistory = returnResult.printChangeHistory;
        modalRef1.componentInstance.printIncludedAmounts = returnResult.printIncludedAmounts;
        modalRef1.componentInstance.printSalesQuoteOnly = returnResult.printSalesQuoteOnly;
        modalRef1.componentInstance.printPdfAttachments = returnResult.printPdfAttachments;
        modalRef1.componentInstance.printConfigAttachments = returnResult.printConfigAttachments;
        modalRef1.componentInstance.printFromSalesPriceOnwards = returnResult.printFromSalesPriceOnwards;
        modalRef1.componentInstance.quoteDate = returnResult.quoteDate;
        modalRef1.componentInstance.quoteExpiryDate = returnResult.quoteExpiryDate;
        modalRef1.componentInstance.exportToXML = returnResult.exportToXML;
        modalRef1.componentInstance.outputFileName = returnResult.outputFileName;
        modalRef1.componentInstance.isPriceGuide = returnResult.isPriceGuide;
        modalRef1.componentInstance.showSignatureSection = returnResult.showSignatureSection;

        modalRef1.result.then(() => {
          this.closeResult = 'Closed';
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  showJobRoles() {
    // use modal to set the users to the roles
    const modalRef = this.modalService.open(JobRolesComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.jobNumber = this.currentJob.jobNumber;

    modalRef.result.then(() => {
    }, () => {
    });
  }

  getStatement() {
    const modalRef1 = this.modalService.open(StatementComponent, { windowClass: 'modal-1000' });

    modalRef1.result.then(() => {
    }, () => {
    });
  }
}
