import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { Recipe } from '../../dtos/recipe';
import { IJobVarCostItem } from '../../dtos/job-var-cost-item';
import { RecipeLine } from '../../dtos/recipe-line';
import { IJobVarCost } from '../../dtos/job-var-cost';
import { JobEstimatingItem } from '../../dtos/job-estimating-item';
import { SelectedRecipes } from '../../dtos/selectedRecipes';
import { IJobVarCostAttachment } from '../../dtos/job-var-cost-attachment';
import { PriceFileItemVendorRate } from '../../dtos/price-file-item-vendor-rate';
import { District } from '../../dtos/district';
import { RecipeTypeEnum } from '../../dtos/recipe-type.enum';
import { RecipeRate } from '../../dtos/recipeRate';
import { PriceFileItem } from '../../dtos/price-file-item';
import { PriceFileItemTypeEnum } from '../../dtos/price-file-item-type.enum';
import { JobEstimatingCheck } from '../../dtos/job-estimating-check';
import { UnitOfMeasure } from '../../dtos/unitOfMeasure';
import { HttpService } from '../http.service';
import { UtilsService } from '../utils.service';
import { JobVarItemCost } from '../../dtos/job-var-item-cost';
import { JobMarkup } from '../../dtos/job-markup';
import { AllEstimatingItem } from '../../dtos/all-estimating-item';
import { VariationService } from './variation.service';
import { Variation } from '../../dtos/variation';
import { Phase } from '../../dtos/phase';

@Injectable({
  providedIn: 'root'
})
export class EstimatingService {
  _jobItemUrl: string;
  districts: District[];
  cachCompanyDistricts: string;
  cacheEffectiveDate: string;
  cacheDistrictId: number;
  priceFileEffectiveDate: string;
  priceFileDistrictId: number;
  recipesAndItems: Recipe[]; // recipes only for combined lookup
  recipeGroups: Recipe[]; // classes only
  allRecipes: Recipe[];
  allRecipeLines: RecipeLine[];
  allRecipeRates: RecipeRate[];
  allRecipeLinesMap: Map<number, RecipeLine[]>;
  cacheCompanyRecipes: string;
  cacheCompanyRecipeLines: string;
  cacheCompanyRecipeRates: string;
  cacheCompanyPriceFileItems: string;
  allPriceFileItems: PriceFileItem[];
  costCentresAndSubGroups: PriceFileItem[];
  cacheCompanyPriceFileItemVendorRates: string;
  unitOfMeasures: UnitOfMeasure[];
  cachCompanyUnitOfMeasures: string;
  gstRate: number;
  cachCompanyGSTRate: string;
  currentEffectiveDateString: string;
  currentDistrictId: number;
  currentPriceFileItemVendorRates: PriceFileItemVendorRate[] = [];
  currentCompanyForEstimating: string;
  estimatingCostingDateString: any;
  priceFileBookId: number;
  jobVarCosts: IJobVarCost[] = [];
  priceFileItemVendorRatesMap: Map<number, PriceFileItemVendorRate[]>;
  allRecipesMap: Map<number, Recipe>;
  cachCompanyPhases: string;
  phases: Phase[];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private utils: UtilsService,
    private userService: UserService,
    private jobVariationService: VariationService,
  ) { }


  getCurrentGSTRate(useCache: boolean): Observable<number> {
    if (useCache && this.cachCompanyGSTRate === this.globalService.getCurrentCompanyId()) {
      return of(this.gstRate);
    } else {
      return this._http.get<number>(this.globalService.getApiUrl()
        + '/gst/current', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.gstRate = res; this.cachCompanyGSTRate = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  getUnitOfMeasures(useCache: boolean = true): Observable<UnitOfMeasure[]> {
    const url = this.globalService.getApiUrl() + '/company-units';

    if (useCache && this.cachCompanyUnitOfMeasures === this.globalService.getCurrentCompanyId()) {
      return of(this.unitOfMeasures);
    } else {
      return this._http.get<UnitOfMeasure[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.unitOfMeasures = res; this.cachCompanyUnitOfMeasures = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getCurrentGST(useCache: boolean): Observable<number> {
    return forkJoin(
      [
        this.getCurrentGSTRate(useCache),
        this.getUnitOfMeasures(useCache)
      ]
    )
      .pipe(map(
        ([gstRate]) => {
          return gstRate;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getEstimatingData(useCache: boolean): Observable<number> {
    return forkJoin(
      [
        this.getCurrentGST(useCache),
        this.getDistricts(useCache),
        this.getRecipes(useCache),
        this.getAllRecipeLines(useCache),
        this.userService.getDivisions(useCache),
        this.getAllPriceFileItems(useCache),
        this.getPriceFileItemVendorRates(useCache),
        this.getPhases(useCache)
      ]
    )
      .pipe(map(
        ([gstRate]) => {
          return gstRate;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getPhases(useCache: boolean): Observable<Phase[]> {
    const url = this.globalService.getApiUrl() + '/phases';

    if (useCache && this.cachCompanyPhases === this.globalService.getCurrentCompanyId()) {
      return of(this.phases);
    } else {
      return this._http.get<Phase[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.phases = res; this.cachCompanyPhases = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getDistricts(useCache: boolean): Observable<District[]> {
    const url = this.globalService.getApiUrl() + '/districts';

    if (useCache && this.cachCompanyDistricts === this.globalService.getCurrentCompanyId()) {
      return of(this.districts);
    } else {
      return this._http.get<District[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.districts = res; this.cachCompanyDistricts = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getRecipeGroups(): Observable<Recipe[]> {
    const url = this.globalService.getApiUrl() + '/recipes?recipeTypeId=' + RecipeTypeEnum.Group + '&includeRates=false';

    return this._http.get<Recipe[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getRecipes(useCache: boolean): Observable<Recipe[]> {
    if (useCache && this.cacheCompanyRecipes === this.globalService.getCurrentCompanyId()) {
      return of(this.allRecipes);
    } else {
      const url = this.globalService.getApiUrl() + '/recipes?includeRates=false';

      return this._http.get<Recipe[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.allRecipes = res.filter(i => i.recipeTypeId === RecipeTypeEnum.Recipe);
          this.recipeGroups = res.filter(i => i.recipeTypeId === RecipeTypeEnum.Group);

          // set classes and sub-groups
          this.allRecipes.forEach(recipe => {
            recipe.masterGroupCostCentre = '000000;RECIPES';
          });

          this.calcRecipeSubGroupDescriptions(null, '000000;');
          this.cacheCompanyRecipes = this.globalService.getCurrentCompanyId();

          this.allRecipesMap = new Map<number, Recipe>();
          res.forEach(item => {
            this.allRecipesMap.set(item.id, item);
          });
        }),
        catchError(this.handleError));
    }
  }

  calcRecipeSubGroupDescriptions(recipeParentId: number, subGroupItemDesc: string) {
    this.recipeGroups.filter(i => i.recipeParentId === recipeParentId).forEach(recipeGroup => {
      const thislevelDesc = subGroupItemDesc + recipeGroup.description;

      this.allRecipes.filter(i => i.recipeParentId === recipeGroup.id).forEach(recipe => {
        recipe.subGroupItemDesc = thislevelDesc;
      });

      this.calcRecipeSubGroupDescriptions(recipeGroup.id, thislevelDesc + ' - ');
    });
  }

  getAllRecipeLines(useCache: boolean): Observable<RecipeLine[]> {
    if (useCache && this.cacheCompanyRecipeLines === this.globalService.getCurrentCompanyId()) {
      return of(this.allRecipeLines);
    } else {
      const url = this.globalService.getApiUrl() + '/recipes/all-lines';
      return this._http.get<RecipeLine[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.allRecipeLines = res; this.cacheCompanyRecipeLines = this.globalService.getCurrentCompanyId();
          this.allRecipeLinesMap = new Map<number, RecipeLine[]>();
          res.forEach(item => {
            const items = this.allRecipeLinesMap.get(item.recipeId);
            if (items) {
              items.push(item);
            } else {
              this.allRecipeLinesMap.set(item.recipeId, [item]);
            }
          });
        }),
        catchError(this.handleError));
    }
  }

  getRecipesForDateAndDistrict(effectiveDate: Date, districtId: number): Recipe[] {
    const effectiveDateString = effectiveDate.getFullYear() + '-'
      + ('0' + (effectiveDate.getMonth() + 1)).toString().slice(-2) + '-'
      + ('0' + effectiveDate.getDate()).slice(-2);

    const district = this.districts.find(i => i.id === districtId);

    let currentTime = new Date().getTime();
    console.log('starting');

    // reset so we know which ones are already done
    this.allRecipes.forEach(recipe => {
      recipe.rate = null;
    });

    this.allRecipes.forEach(recipe => {
      // we deep calc the rate for this recipe
      recipe.rate = this.calcRecipeRate(recipe.id, district, effectiveDateString, true);
    });

    currentTime = Math.round((new Date().getTime() - currentTime) / 1000);
    console.log('done in ' + currentTime + ' seconds');

    const newList = [...this.allRecipes]; // so the pointers don't cause an issue with the original list
    return newList;
  }

  addRecipe(dataRecord: any): Observable<Recipe> {
    const url = this.globalService.getApiUrl() + '/recipes';
    return this._http.post<Recipe>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  getJobVarCost(jobVarItemId: number): Observable<IJobVarCost> {
    return this._http.get<IJobVarCost>(this.globalService.getApiUrl()
      + '/jobvaritem/' + jobVarItemId + '/jobvarcost', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addJobVarCost(dataRecord: any): Observable<IJobVarCost> {
    const url = this.globalService.getApiUrl() + '/jobvarcost';
    return this._http.post<IJobVarCost>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.jobVarCosts.push(res);
      }),
      catchError(this.handleError));
  }

  updateJobVarCost(id: number, itm: any) {
    const url = this.globalService.getApiUrl() + '/jobvarcost/' + id;
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  getJobVarCostItems(jobVarItemId: number): Observable<IJobVarCostItem[]> {
    return this._http.get<IJobVarCostItem[]>(this.globalService.getApiUrl()
      + '/jobvaritem/' + jobVarItemId + '/jobvarcostitems', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addJobVarCostItem(dataRecord: any): Observable<IJobVarCostItem> {
    const url = this.globalService.getApiUrl() + '/jobvarcostitems';
    return this._http.post<IJobVarCostItem>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  addJobVarCostItemsFromSelectedRecipes(jobVarItemId: number, selectedRecipes: SelectedRecipes): Observable<IJobVarCostItem> {
    const url = this.globalService.getApiUrl() + '/jobvarcostitems/' + jobVarItemId + '/multiple-recipes';
    return this._http.post<IJobVarCostItem>(url, JSON.stringify(selectedRecipes), this.httpService.getHttpOptions());
  }

  updateJobVarCostItem(id: string, itm: any) {
    const url = this.globalService.getApiUrl() + '/jobvarcostitems/' + id;
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateJobVarCostItemsSetMargin(jobVarItemId: number, previousMarkup: number, markup: number) {
    const url = this.globalService.getApiUrl() + '/jobvarcostitems/' + jobVarItemId + '/set-markup?markup=' + markup
      + '&previousMarkup=' + previousMarkup;
    return this._http.patch(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  deleteJobVarCostItem(id: string) {
    const url = this.globalService.getApiUrl() + '/jobvarcostitems/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  explodeJobVarCostItem(id: string) {
    const url = this.globalService.getApiUrl() + '/jobvarcostitems/' + id + '/explode';
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  copyJobVarCostItemsToRecipe(jobVarItemId: number, recipeData: any) {
    const url = this.globalService.getApiUrl() + '/jobvaritem/' + jobVarItemId + '/copy-to-recipe';
    return this._http.post(url, JSON.stringify(recipeData), this.httpService.getHttpOptions());
  }

  copyEstimateItemsToRecipe(recipeData: any) {
    const url = this.globalService.getApiUrl() + '/recipes/copy-estimate-items';
    return this._http.post(url, JSON.stringify(recipeData), this.httpService.getHttpOptions());
  }

  getJobEstimatingItems(jobId: number): Observable<JobEstimatingItem[]> {
    return this._http.get<JobEstimatingItem[]>(this.globalService.getApiUrl()
      + '/jobs/' + jobId + '/job-estimate-items', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobEstimatingItemCheck(jobId: number): Observable<JobEstimatingCheck> {
    return this._http.get<JobEstimatingCheck>(this.globalService.getApiUrl()
      + '/jobs/' + jobId + '/job-estimate-items/house-type-check', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addJobEstimatingItem(dataRecord: any): Observable<JobEstimatingItem> {
    const url = this.globalService.getApiUrl() + '/job-estimate-items';
    return this._http.post<JobEstimatingItem>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateJobEstimatingItem(id: string, itm: any) {
    const url = this.globalService.getApiUrl() + '/job-estimate-items/' + id;
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteJobEstimatingItem(id: string) {
    const url = this.globalService.getApiUrl() + '/job-estimate-items/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  deleteAllJobEstimatingItems(jobId: number) {
    const url = this.globalService.getApiUrl() + '/job/' + jobId + '/delete-estimate-items';
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  explodeJobEstimatingItem(id: string) {
    const url = this.globalService.getApiUrl() + '/job-estimate-items/' + id + '/explode';
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  revalueJobEstimatingItems(jobId: number) {
    const url = this.globalService.getApiUrl() + '/job/' + jobId + '/estimate-items/revalue';
    return this._http.patch(url, '', this.httpService.getHttpOptions());
  }

  updateMarginsForJobEstimatingItems(jobId: number, previousMargin: number, newMargin: number) {
    const url = this.globalService.getApiUrl() + '/job/' + jobId + '/estimate-items/change-markup?previousMargin='
      + previousMargin + '&newMargin=' + newMargin;
    return this._http.patch(url, '', this.httpService.getHttpOptions());
  }

  addJobEstimatingItemsFromSelectedRecipes(jobId: number, selectedRecipes: SelectedRecipes): Observable<IJobVarCostItem> {
    const url = this.globalService.getApiUrl() + '/job-estimate-items/' + jobId + '/multiple-recipes';
    return this._http.post<IJobVarCostItem>(url, JSON.stringify(selectedRecipes), this.httpService.getHttpOptions());
  }

  getJobVarCostAttachments(jobVarCostId: number): Observable<IJobVarCostAttachment[]> {
    return this._http.get<IJobVarCostAttachment[]>(this.globalService.getApiUrl()
      + '/jobvarcost-attachments/' + jobVarCostId, this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addJobVarCostAttachment(jobVarCostId: number, jobVarCostAttachment: any) {
    this._jobItemUrl = this.globalService.getApiUrl() + '/jobvarcost-attachments/' + jobVarCostId;
    return this._http.post(this._jobItemUrl, jobVarCostAttachment, this.httpService.getHttpFileOptions());
  }

  deleteJobVarCostAttachment(jobVarCostId: number, attachment: string) {
    this._jobItemUrl = this.globalService.getApiUrl() + '/jobvarcost-attachments/' + jobVarCostId + '/delete';
    return this._http.patch(this._jobItemUrl, JSON.stringify(attachment), this.httpService.getHttpOptions());
  }

  // getJobVarCostAttachmentsExist(jobVarItemId: number): Observable<number> {
  //   return this._http.get<number>(this.globalService.getApiUrl()
  //     + '/jobvaritem/' + jobVarItemId + '/jobvarcost-attachments-exist', this.httpService.getHttpOptions()).pipe(
  //       catchError(this.handleError));
  // }

  getJobVarCostsForVariation(jobVariationId: number): Observable<IJobVarCost[]> {
    this.jobVarCosts = [];
    return this._http.get<IJobVarCost[]>(this.globalService.getApiUrl()
      + '/jobvarcost/for-variation?jobVariationId=' + jobVariationId, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobVarCosts = res;
        }),
        catchError(this.handleError));
  }

  getJobVarCostsForJob(jobId: number): Observable<IJobVarCost[]> {
    this.jobVarCosts = [];
    return this._http.get<IJobVarCost[]>(this.globalService.getApiUrl()
      + '/jobvarcost/for-job?jobId=' + jobId, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobVarCosts = res;
        }),
        catchError(this.handleError));
  }

  // get cost items for margin calcs
  getJobVarItemCosts(jobVariationId: number): Observable<JobVarItemCost[]> {
    return this._http.get<JobVarItemCost[]>(this.globalService.getApiUrl()
      + '/job-variation/' + jobVariationId + '/jobvarcosts', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  updateJobVarItemCost(jobVarItemId: string, itm: any) {
    const url = this.globalService.getApiUrl() + '/jobvaritem/' + jobVarItemId + '/jobvarcost';
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  getJobMarkup(jobId: number, effectiveDateString: string, districtId: number, ignoreEstimatingExtra: boolean): Observable<JobMarkup[]> {
    return this._http.get<JobMarkup[]>(this.globalService.getApiUrl()
      + '/job/' + jobId + '/markup?effectiveDateString=' + effectiveDateString + '&districtId=' + districtId
      + '&ignoreEstimatingExtra=' + ignoreEstimatingExtra,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getAllJobEstimatingItems(jobId: number): Observable<AllEstimatingItem[]> {
    return this._http.get<AllEstimatingItem[]>(this.globalService.getApiUrl()
      + '/job/' + jobId + '/all-estimating-items',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }



  getAllPriceFileItems(useCache: boolean): Observable<PriceFileItem[]> {
    if (useCache && this.cacheCompanyPriceFileItems === this.globalService.getCurrentCompanyId()) {
      return of(this.allPriceFileItems);
    } else {
      const url = this.globalService.getApiUrl() + '/price-file-items';

      return this._http.get<PriceFileItem[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.costCentresAndSubGroups = res.filter(i => i.priceFileItemTypeId === PriceFileItemTypeEnum.Group);
          this.allPriceFileItems = res.filter(i => i.priceFileItemTypeId === PriceFileItemTypeEnum.Item);
          this.cacheCompanyPriceFileItems = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getPriceFileItemVendorRates(useCache): Observable<any> {
    if (useCache && this.cacheCompanyPriceFileItemVendorRates === this.globalService.getCurrentCompanyId()) {
      return of([]); // return empty as we don't use the returned values
    } else {
      const url = this.globalService.getApiUrl() + '/price-file-item-vendor-rates';
      return this._http.get<PriceFileItemVendorRate[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.cacheCompanyPriceFileItemVendorRates = this.globalService.getCurrentCompanyId();

          // sort to get latest rate first for find queries to work
          res = res
            .sort(this.utils.sortBy('priceFileItemId', this.utils.sortBy('effectiveDate', false, false)));

          // for this app we add the estimating extra to the rate
          // this.priceFileItemVendorRates.forEach(itemRec => {
          //   itemRec.rate = (itemRec.rate ? itemRec.rate + (itemRec.estimatingExtra ? itemRec.estimatingExtra : 0)
          //     : itemRec.estimatingExtra ? itemRec.estimatingExtra : 0);
          // });

          this.priceFileItemVendorRatesMap = new Map<number, PriceFileItemVendorRate[]>();
          res.forEach(item => {
            if (!this.priceFileItemVendorRatesMap.has(item.priceFileItemId)) {
              // get the items for all price books
              const items = res.filter(i => i.priceFileItemId === item.priceFileItemId && i.preferredVendorId === i.priceFileVendorId && i.isActive && i.effectiveDate);
              if (items && items.length > 0) {
                items.forEach(i => {
                  i.rate = (i.rate ? i.rate + (i.estimatingExtra ? i.estimatingExtra : 0)
                    : i.estimatingExtra ? i.estimatingExtra : 0);
                });
                this.priceFileItemVendorRatesMap.set(item.priceFileItemId, items);
              }
            }
          });
        }),
        catchError(this.handleError));
    }
  }

  getDataForAllEstimatingItems(): Observable<Variation[]> {
    return forkJoin(
      [
        this.jobVariationService.getVariations(),
        this.getEstimatingData(true)
      ]
    )
      .pipe(map(
        ([data]) => {
          return data;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getItemsForDateAndDistrict(effectiveDate: Date, districtId: number, useCache: boolean): PriceFileItemVendorRate[] {
    if (useCache && this.currentEffectiveDateString === effectiveDate.toString()
      && this.currentDistrictId === districtId
      && this.currentCompanyForEstimating === this.globalService.getCurrentCompanyId()) {

      return this.currentPriceFileItemVendorRates;
    } else {
      this.currentEffectiveDateString = effectiveDate.toString();
      this.currentDistrictId = districtId;
      this.currentCompanyForEstimating = this.globalService.getCurrentCompanyId();

      const effectiveDateString = effectiveDate.getFullYear() + '-'
        + ('0' + (effectiveDate.getMonth() + 1)).toString().slice(-2) + '-'
        + ('0' + effectiveDate.getDate()).slice(-2);

      const district = this.districts.find(i => i.id === districtId);

      this.currentPriceFileItemVendorRates = [];

      this.allPriceFileItems.forEach(item => {
        // we deep calc the rate for this recipe
        const itemWithRate = this.getItemWithRate(item.id, district, effectiveDateString);

        if (itemWithRate) {
          const priceFileItem = this.costCentresAndSubGroups.find(i => i.id === item.priceFileItemParentId);
          if (priceFileItem) {
            itemWithRate.subGroupItemDesc =
              priceFileItem.priceFileCode ? priceFileItem.priceFileCode + ' - ' + priceFileItem.description : priceFileItem?.description;
            itemWithRate.subGroupOrderNumber = priceFileItem.orderNumber;

            const costCentre = this.costCentresAndSubGroups.find(i => i.id === priceFileItem.priceFileItemParentId);
            if (costCentre) {
              itemWithRate.masterGroupCostCentre =
                costCentre.priceFileCode ? costCentre.priceFileCode + ' - ' + costCentre.description : costCentre.description;
              itemWithRate.groupOrderNumber = costCentre.orderNumber;
            }
          }
          this.currentPriceFileItemVendorRates.push(itemWithRate);
        }
      });

      return this.currentPriceFileItemVendorRates;
    }
  }

  getItemWithRate(priceFileItemId: number, district: District, effectiveDateString: string): PriceFileItemVendorRate {
    const itemRec = this.priceFileItemVendorRatesMap.get(priceFileItemId)?.find(i => i.districtId === district.id
      && i.effectiveDate.toString().slice(0, 10) <= effectiveDateString);

    if (!itemRec && district.districtParentId) {
      // we go up to the district parent
      const parentDistrict = this.districts.find(i => i.id === district.districtParentId);
      return (this.getItemWithRate(priceFileItemId, parentDistrict, effectiveDateString));
    } else if (!itemRec) {
      return null;
    } else {
      return itemRec;
    }
  }

  calcRecipeRate(recipeId: number, district: District, effectiveDateString: string, useScaling: boolean): number {
    let rate = 0;
    let itemRate = 0;
    let validRate = true;

    const recipe = this.allRecipesMap.get(recipeId);
    if (!recipe || !recipe.isActive) {
      return null;
    }
    if (recipe.rate && recipe.districtCostedId === district.id) {
      return recipe.rate;
    }

    const recipeLinesForRecipe = this.allRecipeLinesMap.get(recipeId);

    if (recipeLinesForRecipe && recipeLinesForRecipe.length) {
      // we calc the price
      recipeLinesForRecipe.forEach(recipeLine => {
        if (validRate) {
          if (recipeLine.priceFileItemId) {
            // we find the rate for the item
            if (recipeLine.quantity != null && recipeLine.quantity !== 0) {
              itemRate = this.getDistrictPreferredRate(district,
                recipeLine.priceFileItemId, effectiveDateString);
              if (itemRate === null) {
                validRate = false;
              } else if (recipeLine.unitOfMeasureId) {
                const unitOfMeasure = this.unitOfMeasures.find(i => i.id === recipeLine.unitOfMeasureId);
                if (unitOfMeasure && unitOfMeasure.costIsPer) {
                  itemRate /= unitOfMeasure.costIsPer;
                }
              }
            }
          } else if (recipeLine.recipeItemId) {
            // we have a sub - recipe
            itemRate = this.calcRecipeRate(recipeLine.recipeItemId, district, effectiveDateString, false);
            if (itemRate === null) {
              validRate = false;
            } else {
              // we get the scaling - divided by...
              const lineRecipe = this.allRecipesMap.get(recipeLine.recipeItemId);
              if (lineRecipe) {
                itemRate = lineRecipe.scale ? (itemRate / lineRecipe.scale) : itemRate;
              }
              const unitOfMeasure = this.unitOfMeasures.find(i => i.description === lineRecipe.unitOfMeasure);
              if (unitOfMeasure && unitOfMeasure.costIsPer) {
                itemRate /= unitOfMeasure.costIsPer;
              }
            }
          } else {
            // we already have the rate so we use that
            itemRate = recipeLine.rate;
            if (recipeLine.unitOfMeasureId) {
              const unitOfMeasure = this.unitOfMeasures.find(i => i.id === recipeLine.unitOfMeasureId);
              if (unitOfMeasure && unitOfMeasure.costIsPer) {
                itemRate /= unitOfMeasure.costIsPer;
              }
            }
          }

          // now calc
          if (validRate && itemRate && recipeLine.quantity) {
            rate += itemRate * recipeLine.quantity;
          }
        }
      });
    } else {
      // return the default rate for now
    }

    if (validRate) {
      if (useScaling) {
        // we get the scaling - divided by...
        if (recipe) {
          rate = recipe.scale ? (rate / recipe.scale) : rate;
        }
      }

      recipe.districtCostedId = district.id // so we don't have to recost
      return rate; // this.utilsService.roundEven((rate + Number.EPSILON) * 100) / 100;
    } else {
      return null;
    }
  }

  getDistrictPreferredRate(district: District, priceFileItemId: number, currentCostingDateString: string): number {
    const itemRec = this.priceFileItemVendorRatesMap.get(priceFileItemId)?.find(i => i.districtId === district.id
      && i.effectiveDate.toString().slice(0, 10) <= currentCostingDateString);

    if (!itemRec && district.districtParentId) {
      // we go up to the district parent
      const parentDistrict = this.districts.find(i => i.id === district.districtParentId);
      return (this.getDistrictPreferredRate(parentDistrict, priceFileItemId, currentCostingDateString));
    } else if (!itemRec || (itemRec.expiryDate && itemRec.expiryDate.toString().slice(0, 10) < currentCostingDateString)) {
      return null;
    } else {
      return itemRec.rate;
    }
  }

  getRecipeLinesForRecipe(id: number, currentDistrict: District): RecipeLine[] {
    // deep copy the array as we want to change rates for the district
    const myRecipeLines = this.allRecipeLines.filter(recipeLine => recipeLine.recipeId === id).map(a => ({ ...a }));

    myRecipeLines.forEach(recipeLine => {
      if (!recipeLine.id) {
        console.log('Recipe Line has no id: ' + JSON.stringify(recipeLine));
      }
      if (recipeLine.recipeItemId) {
        // we deep calc the rate for this recipe
        recipeLine.rate = this.calcRecipeRate(recipeLine.recipeItemId, currentDistrict, this.estimatingCostingDateString, true);

        // get the units of measure
        const recipe = this.allRecipes.find(i => i.id === recipeLine.recipeItemId);
        recipeLine.unitOfMeasureId = this.unitOfMeasures.find(i => i.description === recipe.unitOfMeasure)?.id;

        recipeLine.recipeCode = recipe.recipeCode;
      } else if (recipeLine.priceFileItemId) {
        // we get the rate for this item for the date and district
        recipeLine.rate = this.getDistrictPreferredRate(currentDistrict, recipeLine.priceFileItemId, this.estimatingCostingDateString);

        // get the units of measure
        const priceFileItem = this.allPriceFileItems.find(i => i.id === recipeLine.priceFileItemId);
        const units = this.unitOfMeasures.find(i => i.id === priceFileItem?.unitOfMeasureId);
        recipeLine.unitOfMeasureId = units?.id;
        recipeLine.recipeCode = priceFileItem?.priceFileCode;
      } else {
        // we leave the rate as is as must be a manual item
      }
    });
    return myRecipeLines;
  }

  explodeRecipeInMemory(recipeId: number): Observable<RecipeLine[]> {
    const url = this.globalService.getApiUrl() + '/recipe/' + recipeId + '/explode-in-memory';
    return this._http.get<RecipeLine[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  addJobEstimateSummaries(jobId: number, costCentreIds: number[], totals: number[]) {
    this._jobItemUrl = this.globalService.getApiUrl() + '/job-estimate-summaries/' + jobId + '/all';
    return this._http.post(this._jobItemUrl,
      JSON.stringify({ costCentreIds: costCentreIds, totals: totals }),
      this.httpService.getHttpOptions());
  }

  getSalesUpsDowns(jobId: number): Observable<IJobVarCost[]> {
    const url = this.globalService.getApiUrl() + '/reports/sales-ups-downs?jobId=' + jobId;

    return this._http.get<IJobVarCost[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getEnterKeyActions() {
    return ['startEdit', 'moveFocus'];
  }
  getEnterKeyDirections() {
    return ['none', 'column', 'row'];
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
