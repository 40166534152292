import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { JobItemLinkService } from '../services/felixApi/job-item-link.service';
import { GlobalService } from '../services/global.service';
import { JobItemService } from '../services/felixApi/job-item.service';
import { OptionListService } from '../services/felixApi/option-list.service';
import { JobItemLink } from '../dtos/job-item-link';
import { IJobItem } from '../dtos/job-item';
import { ItemTypeEnum } from '../dtos/item-type.enum';
import { SelectionTypeEnum } from '../dtos/selection-type.enum';
import { IOptionListHeader } from '../dtos/option-list-header';
import { TreeComponent } from '@circlon/angular-tree-component';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-job-item-link',
  templateUrl: './job-item-link.component.html',
  styleUrls: ['./job-item-link.component.scss']
})
export class JobItemLinkComponent implements OnInit, OnDestroy {
  @Input() iJobItem: IJobItem;
  // @Output() closeclick: EventEmitter<boolean> = new EventEmitter<boolean>();

  COMPONENT_NAME = 'job-item-link';
  subscriptions: Subscription[] = [];

  equalToVals = [
    { name: 'Is Equal To', id: 0, value: true },
    { name: 'Is Not Equal To', id: 1, value: false }
  ];

  addEditSetupLine = '';
  errorMessage: any;
  updatedItem: any;
  oneJobItem: IJobItem[] = [];
  checkboxValue: boolean;
  setupLinksLinkedId: number; // id of jobItemLink.linkedJobItemId
  setupLinkedJobItemIsDetail: boolean;
  setupLinkedItemSelectionType: number;
  setupLinkedItemOptionListId: number;
  jobItemLinks: JobItemLink[] = [];
  ItemType = ItemTypeEnum;
  SelectionType = SelectionTypeEnum;
  setupLinkedJobItemId = 0; // for linking for setup to show/hide
  setupValue: string;
  setupValueOrig: string; // holding the original value to check on update
  optionList: IOptionListHeader[] = [];
  manualSelection = ''; // form for entering a manual selection in a dropdown
  treeJobItemNodes: any[] = [];
  treeJobItemOptions = {};
  optSelection = '';
  optSelectionCheckbox: boolean;
  // canEnterManualSelection: boolean;
  loading = true; // loading spinner
  loadingSetupLinks = true;
  loadingSelectedDetails = false; // used when we select an item and are loading it's selections
  showSpinner = false; // stop spinner showing if not adding or editing
  isEqualTo: boolean;
  origIsEqualTo: boolean;
  treeOptionNodes: any[] = [];
  isConnectedToThisItemInVO: boolean;
  andORSetupLinks: string; // tells us if we ALL or only one condition need to be true to show the line
  descriptionWidth: number;

  constructor(
    private _jobItemLinkService: JobItemLinkService,
    private _jobItemService: JobItemService,
    private _optionListService: OptionListService,
    private notiService: NotificationService,
    private _globalService: GlobalService,
    private _activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    if (!this.iJobItem.setUpTags || this.iJobItem.setUpTags === 'OR') {
      this.andORSetupLinks = 'OR';
    } else {
      this.andORSetupLinks = 'AND';
    }
    this.descriptionWidth = innerWidth < 710 ? innerWidth - 210 : 500;

    this.setSetupLinkedItemModal(this.iJobItem.id);
    this.setupJobItemNodes();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  // set the linked job item modal info
  setSetupLinkedItemModal(id) {
    this.addEditSetupLine = ''; // not adding or editing one yet
    // read existing linked lines
    this.loadingSetupLinks = true;
    this.subscriptions = this.subscriptions.concat(
      this._jobItemLinkService.getJobItemLinks(id)
        .subscribe({
          next: (jobItemLinks) => {
            this.jobItemLinks = jobItemLinks;
            this.loadingSetupLinks = false;
          },
          error: (err) => {
            this.loadingSetupLinks = false;
            this.notiService.notify(err);
          }
        })
    );
  }

  // set the linked job item when clicked on from tree
  setSetupLinkedItemId(id) {
    if (id === this.iJobItem.id) {
      this.setupLinkedJobItemIsDetail = false;
    } else if (this.setupLinkedJobItemId !== id) {
      this.loadingSelectedDetails = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobItemService.getOneJobItem(this._globalService.getCurrentJob(), id, false)
          .subscribe({
            next: (oneJobItem) => {
              this.oneJobItem = oneJobItem;

              if (this.oneJobItem[0].itemTypeId !== this.ItemType.Detail) {
                // alert('Please select a detail line');
                this.setupLinkedJobItemIsDetail = false;
                this.loadingSelectedDetails = false;
              } else {
                this.setupLinkedJobItemId = id;
                this.setupLinkedJobItemIsDetail = true;
                this.setupLinkedItemSelectionType = this.oneJobItem[0].selectionTypeId;

                if (this.setupLinkedItemSelectionType === this.SelectionType.Checkbox) {
                  this.setupValue = '';
                  this.loadingSelectedDetails = false;
                } else {
                  this.setupLinkedItemOptionListId = this.oneJobItem[0].optionListId;
                  this.setupValue = 'Please select...';
                  this.getOptionListForDropdown(this.oneJobItem[0].optionListId);
                }
              }
            },
            error: (err) => {
              this.loadingSelectedDetails = false;
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  cancelSetupLinks() {
    this.addEditSetupLine = '';
  }

  deleteLinkedSetupLine(id) {
    this.loadingSetupLinks = true;
    this.subscriptions = this.subscriptions.concat(
      this._jobItemLinkService.deleteJobItemLink(id).subscribe({
        next: () => {
          this.setSetupLinkedItemModal(this.iJobItem.id);
        },
        error: (err) => {
          this.loadingSetupLinks = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  // set up the tree nodes for the add/edit item
  setupJobItemNodes() {
    this.treeJobItemOptions = {
      idField: 'id',
      displayField: 'itemDescription',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };
    this.loading = true;
    // start at item 0 to get all lines with children = true and get hidden lines = true
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.getJobItems(this._globalService.getCurrentJob(), 1, 0, true, true, false, 0, false, false)
        .subscribe({
          next: (treeJobItemNodes) => {
            this.treeJobItemNodes = treeJobItemNodes;
            this.loading = false;
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
    );
  }

  addLinkedSetupLine() {
    this.showSpinner = true;
    this.setupLinkedJobItemId = 0;
    this.addEditSetupLine = 'add';
    this.setupLinkedItemSelectionType = null; // reset for add.
    this.setupLinkedJobItemIsDetail = false; // will check if the linked line is a detail line
    this.setupLinkedItemOptionListId = null;
    this.setupValue = '';
    this.isEqualTo = true;
    this.checkboxValue = false;
    this.isConnectedToThisItemInVO = false;
  }

  editLinkedSetupLine(jobitemlink) {
    this.showSpinner = true;
    this.setupLinksLinkedId = jobitemlink.id;
    this.setupLinkedJobItemId = jobitemlink.linkedJobItemId;
    this.addEditSetupLine = 'edit'; // tell DOM that we are editing
    this.setupLinkedJobItemIsDetail = true; // would have to be true
    this.setupLinkedItemSelectionType = jobitemlink.linkedJobItem.selectionTypeId;
    this.setupLinkedItemOptionListId = jobitemlink.linkedJobItem.optionListId;
    this.setupValue = jobitemlink.setupValue;
    this.setupValueOrig = jobitemlink.setupValue;
    if (this.setupValue === 'Yes') {
      this.checkboxValue = true;
    } else {
      this.checkboxValue = false;
    }
    this.isEqualTo = jobitemlink.isEqualTo;
    this.origIsEqualTo = jobitemlink.isEqualTo;

    if (this.iJobItem.connectedJobItemId === jobitemlink.linkedJobItemId) {
      this.isConnectedToThisItemInVO = true;
    } else {
      this.isConnectedToThisItemInVO = false;
    }
    this.getOptionListForDropdown(this.setupLinkedItemOptionListId);
  }

  setSetupSelection(opt) {
    this.setupValue = opt.description;
  }

  updateSetupLinks() {
    if (this.addEditSetupLine === 'edit') {
      // Patch change
      this.updatedItem = { id: this.setupLinksLinkedId, linkedJobItemId: this.setupLinkedJobItemId };
      if (this.setupLinkedItemSelectionType === this.SelectionType.Checkbox) {
        if (this.checkboxValue && this.setupValue !== 'Yes') {
          this.updatedItem.setupValue = 'Yes';
        } else if (!this.checkboxValue && this.setupValue !== 'No') {
          this.updatedItem.setupValue = 'No';
        }
      } else if (this.setupValue !== this.setupValueOrig) {
        this.updatedItem.setupValue = this.setupValue;
      }
      if (this.isEqualTo !== this.origIsEqualTo) {
        this.updatedItem.isEqualTo = this.isEqualTo;
      }

      this.loadingSetupLinks = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobItemLinkService.updateJobItemLink(this.updatedItem).subscribe({
          next: () => {
            this.updateConnectedJobItemID(this.setupLinkedJobItemId);
          },
          error: (err) => {
            this.loadingSetupLinks = false;
            this.notiService.notify(err);
          }
        })
      );
    } else if (this.addEditSetupLine === 'add') {
      // Add new linked item line
      this.updatedItem = {
        id: this.setupLinksLinkedId, jobItemId: this.iJobItem.id,
        linkedJobItemId: this.setupLinkedJobItemId,
        isEqualTo: this.isEqualTo
      };
      if (this.setupLinkedItemSelectionType === this.SelectionType.Checkbox) {
        if (this.checkboxValue) {
          this.updatedItem.setupValue = 'Yes';
        } else if (!this.checkboxValue) {
          this.updatedItem.setupValue = 'No';
        }
      } else {
        this.updatedItem.setupValue = this.setupValue;
      }

      this.loadingSetupLinks = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobItemLinkService.addJobItemLink(this.updatedItem).subscribe({
          next: () => {
            this.updateConnectedJobItemID(this.setupLinkedJobItemId);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  getOptionListForDropdown(optionListId) {
    this.treeOptionNodes = this._optionListService.getCurrentOptionNodes();
    if (this.treeOptionNodes === null) {
      this.subscriptions = this.subscriptions.concat(
        this._optionListService.getOptionListChildren(0, true, false)
          .subscribe({
            next: (treeOptionNodes) => {
              this.treeOptionNodes = treeOptionNodes;
              this._optionListService.setCurrentOptionNodes(this.treeOptionNodes);
              this.optionList = this._jobItemService.getOptionListItems(optionListId);
              this.loadingSelectedDetails = false;
            },
            error: (err) => {
              this.loadingSelectedDetails = false;
              this.notiService.notify(err);
            }
          })
      );
    } else {
      this.optionList = this._jobItemService.getOptionListItems(optionListId);
      this.loadingSelectedDetails = false;
    }
  }

  // when the job item tree is initialised set the focused node to the one already selected.
  onSetupItemTreeInit(tree: TreeComponent) {
    if (this.setupLinkedJobItemId !== 0 && this.setupLinkedJobItemId !== undefined && this.setupLinkedJobItemId !== null) {
      tree.treeModel.getNodeById(this.setupLinkedJobItemId)?.setActiveAndVisible();
    }
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
    this.loading = false;
  }

  updateConnectedJobItemID(id: number) {
    let updateYes = false;
    if (this.isConnectedToThisItemInVO && this.iJobItem.connectedJobItemId !== id) {
      this.updatedItem = { id: this.iJobItem.id, connectedJobItemId: id };
      updateYes = true;
    } else if (!this.isConnectedToThisItemInVO && this.iJobItem.connectedJobItemId !== null) {
      this.updatedItem = { id: this.iJobItem.id, connectedJobItemId: null };
      updateYes = true;
    }

    if (updateYes) {
      this.subscriptions = this.subscriptions.concat(
        this._jobItemService.updateJobItem(this.updatedItem).subscribe({
          next: () => {
            this.setSetupLinkedItemModal(this.iJobItem.id);
            this.iJobItem.connectedJobItemId = this.updatedItem.connectedJobItemId;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.setSetupLinkedItemModal(this.iJobItem.id);
          }
        })
      );
    } else {
      this.setSetupLinkedItemModal(this.iJobItem.id);
    }
  }

  closeClick() {
    this.iJobItem.setUpTags = this.andORSetupLinks;
    this._activeModal.close(this.iJobItem);
  }
}
