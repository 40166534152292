<div class="recipeLinesBorder">
  <dx-data-grid #recipeLineGrid id="recipeLineGrid" [dataSource]="dataSource" width="100%"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onCellPrepared)="onCellPrepared($event)"
    [ngStyle]="{'max-height': 'calc(' + gridMaxHeight + 'px)'}">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>

    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="true" expandMode="rowClick">
    </dxo-grouping>

    <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="Cost Centre"
      [groupIndex]="0" groupCellTemplate="groupCellTemplate" allowEditing="false">
    </dxi-column>

    <dxi-column dataField="subGroupItemDesc" dataType="string" caption="Sub-Group" [groupIndex]="1"
      groupCellTemplate="groupCellTemplate" allowEditing="false">
    </dxi-column>

    <dxi-column dataField="recipeItemId" dataType="number" [visible]='false'> </dxi-column>

    <dxi-column dataField="recipeCode" dataType="string" caption="Item/Recipe" [width]="110" sortOrder="asc">
    </dxi-column>

    <dxi-column dataField="description" dataType="string" [minWidth]="100" cellTemplate="wrapTemplate">
    </dxi-column>

    <dxi-column dataField="unitOfMeasureId" dataType="string" caption="Units" [width]="80" [hidingPriority]="5"
      [editorOptions]="{ wrapItemText: true }">
      <dxo-lookup [dataSource]="unitOfMeasures" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="quantityString" caption="Measured Qty" dataType="string" [width]="quantityStringWidth">
    </dxi-column>

    <dxi-column dataField="quantity" caption="Qty" dataType="number" [width]="80" allowEditing="false"
      format="#,###.####">
    </dxi-column>

    <dxi-column dataField="rate" dataType="number" [width]="100" format="#,###.00" [calculateCellValue]="getRate">
    </dxi-column>

    <dxi-column caption="Total" [calculateCellValue]="calculateLineTotal" dataType="number" [width]="100"
      format="#,###.00" allowEditing="false">
    </dxi-column>

    <dxi-column dataField="marginOverridePercent" caption='Mark-Up Override%' dataType="number" [width]="80"
      format="#,###.00" alignment="center" [hidingPriority]="4">
    </dxi-column>

    <dxi-column dataField="phaseId" caption="Phase" dataType="number" [width]="90" [hidingPriority]="3">
      <dxo-lookup [dataSource]="phases" displayExpr="phaseCode" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isToBeChecked" caption="To Be Checked" dataType="boolean" [width]="75" [hidingPriority]="2">
    </dxi-column>

    <dxi-column dataField="comment" dataType="string" [width]="135" [hidingPriority]="1"> </dxi-column>

    <dxi-column dataField="modifiedDate" dataType="date" format="d/M/yy" [visible]="false" [allowEditing]="false"
      alignment="center" [width]="90">
    </dxi-column>

    <dxi-column dataField="modifiedUserId" dataType="number" caption="Modified By" [visible]="false"
      [allowEditing]="false" [width]="120">
      <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
      </dxo-lookup>
    </dxi-column>


    <dxi-column type="buttons" [fixed]="true" [width]="35">
      <dxi-button hint="open recipe" icon="folder" [visible]="isOpenIconVisible" [onClick]="openRecipe">
      </dxi-button>
    </dxi-column>

    <div *dxTemplate="let cellInfo of 'wrapTemplate'">
      <div id="wrapComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      {{ getGroupTitle(data) }}
    </div>

    <dxo-summary>
      <dxi-total-item column="Total" summaryType="sum" [valueFormat]="{ type: 'fixedPoint', precision: 2 }"
        displayFormat="{0}">
      </dxi-total-item>
    </dxo-summary>
  </dx-data-grid>
</div>

<dx-popup class="popup" [width]="300" [height]="475" [showTitle]="true" [dragEnabled]="true"
  [hideOnOutsideClick]="false" [(visible)]="lengthPopupVisible" titleTemplate="title">

  <div *dxTemplate="let data of 'content'">
    <dx-data-grid id="lengthsDataGrid" class="m-auto" [dataSource]="lengthsDataSource" [height]="340" [width]="250"
      [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
      [rowAlternationEnabled]="false" [wordWrapEnabled]="true">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-paging [enabled]="false"></dxo-paging>

      <dxi-column dataField="quantity">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="length">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>
    </dx-data-grid>
    <dx-button id="button" class="mt-1 ml-1" text="Close" type="default" (onClick)="closeLengthPopup()"></dx-button>
  </div>

  <div *dxTemplate="let data of 'title'">
    <div class="qtyPopuptitle">{{recipeLineDescription}}</div>
  </div>
</dx-popup>

<!-- loading spinner - DevExtreme -->
<dx-load-panel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="false" [hideOnOutsideClick]="false">
</dx-load-panel>