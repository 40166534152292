import { Component, OnInit, Input, OnDestroy, OnChanges, ViewChild, HostListener } from '@angular/core';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { Subscription } from 'rxjs';
import { RecipeLine } from '../../dtos/recipe-line';
import { Recipe } from '../../dtos/recipe';
import { District } from '../../dtos/district';
import { Phase } from '../../dtos/phase';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { DxDataGridComponent } from 'devextreme-angular';
import mathString from 'math-string';
import { UnitOfMeasure } from '../../dtos/unitOfMeasure';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubRecipeComponent } from '../sub-recipe/sub-recipe.component';
import { Workbook } from 'exceljs';
import { GridService } from '../../services/grid.service';
import { UserService } from '../../services/felixApi/user.service';
import { User } from '../../dtos/user';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-recipe-lines',
  templateUrl: './recipe-lines.component.html',
  styleUrls: ['./recipe-lines.component.scss']
})
export class RecipeLinesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() recipe: Recipe;
  @Input() isSubRecipe: boolean;

  @ViewChild('recipeLineGrid') dataGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  currentDistrict: District;
  itemToExplode: number;
  popupVisible: boolean;
  phases: Phase[] = [{ id: 0, orderNo: 0, phaseCode: 'Default', description: 'Default', isShowOnPO: false, centralCompanyId: null }];
  rowKey: any;
  gridString: string;
  lengthPopupVisible = false;
  lengthsDataSource: CustomStore;
  recipeLineId: number;
  hasSizes: boolean;
  recipeLineQuantity: any;
  recipeLineDescription: any;
  unitOfMeasures: UnitOfMeasure[];
  descriptionWidth: number;
  recipeParentId: number;
  explodeTitle: string;
  gridMaxHeight: number;
  quantityStringWidth = 120;
  quantityForExplode: any;
  recipeItemId: number;
  ignoreEmptyRecipes: boolean;
  loading: boolean;
  updateOptionLists: boolean;
  reCostingDate: Date;
  lengthKeyId = 0; // in case we add lengths
  users: User[];

  @HostListener('window:resize') onResize() {
    this.setWidths();
  }

  constructor(private estimatingService: EstimatingService,
    private modalService: NgbModal,
    private userService: UserService,
    public gridService: GridService) {
    this.setQtyCellValue = this.setQtyCellValue.bind(this);
    this.calculateLineTotal = this.calculateLineTotal.bind(this);
    this.openRecipe = this.openRecipe.bind(this);
    this.getRate = this.getRate.bind(this);
  }

  ngOnInit() {
    this.users = this.userService.users;
  }

  ngOnChanges() {
    this.setWidths();
    this.setupData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setWidths() {
    this.descriptionWidth = window.innerWidth < 1900 ? 350 : window.innerWidth - 1550;
    this.gridMaxHeight = window.innerHeight - 350;

    this.quantityStringWidth = window.innerWidth < 2000 ? 120 : window.innerWidth < 2400 ? 240 : 360;
  }

  setupData() {
    this.currentDistrict = this.estimatingService.districts.find(i => i.id === this.recipe.districtId);
    this.phases = this.phases.concat(this.estimatingService.phases);
    this.unitOfMeasures = this.estimatingService.unitOfMeasures;
    this.setUpDataSet();
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: () => this.estimatingService.getRecipeLinesForRecipe(this.recipe.id, this.currentDistrict)
    });
  }

  getRate(recipeLine: RecipeLine) {
    if (recipeLine.rate) {
      return recipeLine.rate;
    }
    let rate = recipeLine.rate;
    if (recipeLine.recipeItemId) {
      rate = this.estimatingService.calcRecipeRate(recipeLine.recipeItemId, this.currentDistrict, this.estimatingService.estimatingCostingDateString, true);
    } else if (recipeLine.priceFileItemId) {
      rate = this.estimatingService.getDistrictPreferredRate(this.currentDistrict, recipeLine.priceFileItemId, this.estimatingService.estimatingCostingDateString);
    }
    if (recipeLine.recipeCode && recipeLine.recipeCode !== '' && rate === null) {
      return 'Invalid';
    }
    recipeLine.rate = rate;
    return rate;
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'rate' && e.data.rate === null && e.data.recipeCode && e.data.recipeCode !== '') {
      e.cellElement.style.color = 'red';
    }
    if (e.rowType === 'data' && e.column.dataField === 'quantityString' && e.data.quantityString) {
      try {
        const calcCheck = mathString(this.sanitizeQty(e.data.quantityString));
        if (isNaN(+calcCheck)) {
          e.cellElement.classList.add('redWhite');
        }
      } catch (err) {
        e.cellElement.classList.add('redWhite');
      }
    }
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data?.key?.split(';')[1];
  }

  calculateGrouptitle(data) {
    return data.masterGroupCostCentre.split(';')[1];
  }

  calculateSubGrouptitle(data) {
    return data.subGroupItemDesc.split(';')[1];
  }

  calcGroupSortValue(data) {
    return data.masterGroupCostCentre.split(';')[0];
  }

  calculateSubGroupSortValue(data) {
    return data.subGroupItemDesc.split(';')[0];
  }

  isOpenIconVisible = (e) => {
    if (e.row.data.recipeItemId) {
      return true;
    }
    return false;
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Recipe',
      { pageSetup: { paperSize: 9, orientation: 'landscape', showGridLines: true } });

    worksheet.columns = [
      { width: 20 }, { width: 60 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 },
      { width: 15 }, { width: 15 }, { width: 15 }, { width: 10 }, { width: 25 }
    ];
    worksheet.headerFooter.oddFooter = '&L' + this.recipe.description + '&CPage &P of &N';
    worksheet.headerFooter.evenFooter = '&L' + this.recipe.description + '&CPage &P of &N';

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      keepColumnWidths: false,
      // topLeftCell: { row: 2, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'group') {
          this.gridString = gridCell.value;
          excelCell.value = this.gridString?.split(';')[1];

          if (gridCell.groupIndex === 0) {
            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } };
            excelCell.alignment = { wrapText: false };
          }
          if (gridCell.groupIndex === 1) {
            excelCell.alignment = { wrapText: false };
          }
        }
      }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Recipe ' + this.recipe.description + '.xlsx');
      });
    });
    e.cancel = true;
  }

  closeLengthPopup() {
    this.lengthPopupVisible = false;
  }

  sanitizeQty(quantityString): string {
    if (quantityString) {
      quantityString = quantityString.replace(/(^\s+|\s+$)/g, '');
      quantityString = quantityString.replace(/=/g, '');
      if (quantityString.search(' ') >= 0) {
        quantityString = quantityString.replace(/\s/g, '');
      }
    }
    return quantityString;
  }

  calcQtyFromString(quantityString): number {
    let validQty = true;
    let quantity = 0;
    let quantityStringNew = '';

    if (quantityString) {
      try {
        quantityStringNew = mathString(quantityString);
      } catch (err) {
        validQty = false;
        quantity = 0;
      }

      if (isNaN(quantity)) {
        validQty = false;
        quantity = 0;
      }
    }

    if (validQty) {
      return +quantityStringNew;
    } else {
      return null;
    }
  }

  setQtyCellValue(rowData, value) {
    if (value) {
      rowData.quantityString = value;
      rowData.quantity = this.calcQtyFromString(this.sanitizeQty(value));
    }
  }

  calculateLineTotal = (rowData) => {
    if (rowData) {
      let rate = rowData.rate;
      if (rowData.recipeItemId) {
        rate = this.estimatingService.calcRecipeRate(rowData.recipeItemId, this.currentDistrict, this.estimatingService.estimatingCostingDateString, true);
      } else if (rowData.priceFileItemId) {
        rate = this.estimatingService.getDistrictPreferredRate(this.currentDistrict, rowData.priceFileItemId, this.estimatingService.estimatingCostingDateString);
      }

      let qty = !rowData.quantity || isNaN(rowData.quantity) ? 0 : rowData.quantity;
      rate = !rate || isNaN(rate) ? 0 : rate;

      const unitOfMeasure = this.estimatingService.unitOfMeasures.find(i => i.id === rowData.unitOfMeasureId);
      if (unitOfMeasure && unitOfMeasure.costIsPer) {
        qty /= unitOfMeasure.costIsPer;
      }

      return qty * rate; // this.utilsService.roundEven(qty * rate);
    } else {
      return 0;
    }
  }

  openRecipe(e) {
    this.openRecipeGo(e.row.data.recipeItemId);
  }

  openRecipe2() {
    this.popupVisible = false;
    this.openRecipeGo(this.recipeItemId);
  }

  openRecipeGo(recipeItemId: number) {
    const modalRef = this.modalService.open(SubRecipeComponent, { windowClass: 'modal-1500' });
    modalRef.componentInstance.recipeId = recipeItemId;
    modalRef.componentInstance.isSubRecipe = true;

    modalRef.result.then(() => { }, () => { });
  }
}
