<!-- modal for delete -->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Delete Job</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form class="form-horizontal" (ngSubmit)="c('Close click')" novalidate [formGroup]="deleteForm">
    <div class="modal-body">
      <div class="form-group row">
        <label class="col-sm-3 righttext" for="contractNameId">Contract Name</label>
        <dx-text-box id="contractNameId" class="col-sm-8 px-0" formControlName="contractName"
          readOnly="true"></dx-text-box>
      </div>
      <br>
      <div class="form-group row">
        <label class="col-sm-11 righttext">Ok to delete any Attachments or Information records?&nbsp;
          <dx-check-box formControlName="deleteAttachments"></dx-check-box>
        </label>
      </div>
      <div *ngIf="estimatingItemsExist" class="form-group row">
        <label class="col-sm-11 righttext">Ok to delete estimating records?&nbsp;
          <dx-check-box formControlName="deleteEstimatingItems"></dx-check-box>
        </label>
      </div>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <button type="submit" class="btn btn-primary" [disabled]="checkingIfEstimatingItemsExist || !deleteForm.get('deleteAttachments').value
          || (estimatingItemsExist && !deleteForm.get('deleteEstimatingItems').value)">Delete</button>
      </div>
    </div>
  </form>
</ng-template>

<form *ngIf="!jobDeleted" class="form-horizontal jobForm" novalidate (ngSubmit)="save()" [formGroup]="jobForm">
  <fieldset>

    <!-- Warning Note -->
    <p *ngIf="(job && job.warningNote && job.warningNote !== '')" class="warningClass">
      WARNING: {{job.warningNote}}
    </p>

    <mat-accordion>
      <mat-expansion-panel class="m-t-10 m-b-16" expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-weight: bold">
            Basic Details
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div class="form-group row">
            <label class="col-sm-2 righttext" for="jobNumberId">Job Number
              <span class="required"> *</span>
            </label>
            <dx-text-box id="jobNumberId" class="col-sm-2 px-0" formControlName="jobNumber" placeholder="(required)"
              [readOnly]="(jobNum !== '0' && jobNum !== '') || (isSalesRep && defaultDivisionId && !jobsAdminPermission)"></dx-text-box>

            <label class="col-sm-2 righttext" for="divisionId">
              <span style="width:100%">
                <span
                  *ngIf="jobForm.get('jobNumber').invalid && jobForm.get('jobNumber').dirty && jobForm.get('jobNumber').value !== ''"
                  class="jobNumberValidation red"
                  matTooltip="Only letters numbers _ and - allowed. Spaces are allowed except at the start and end"
                  matTooltipPosition="above" [matTooltipShowDelay]="500">
                  **
                </span>
                <span>Division</span>
                <span class="required"> *</span>
              </span>
            </label>

            <dx-select-box [dataSource]="divisions" class="col-sm-2 ms-0 px-0" id="divisionId" valueExpr="id"
              displayExpr="description" formControlName="divisionId" searchEnabled="true"
              (onSelectionChanged)="setDivision($event)"></dx-select-box>

            <button *ngIf="(jobNum === '0' || jobNum === '') && (jobsWrite || jobsAdmin)"
              class="btn btn-sm btn-primary getNextNumberBtn ms-1" type="button" (click)="getNextJobNumber()">Get Next
              Number</button>

            <label *ngIf="(jobNum !== '0' && jobNum !== '')" class="col-sm-3 righttext pe-0" for="isActive">Active Job?
              <dx-check-box id="isActive" class="ms-1" formControlName="isActive" tabindex="-1"></dx-check-box>
            </label>
          </div>

          <div class="form-group row">
            <label *ngIf="salesConsultant" class="col-sm-2 righttext #salesConsultant" for="salesConsultant">Sales
              Consultant</label>
            <dx-text-box *ngIf="salesConsultant" id="salesConsultant" class="col-sm-2 px-0" [value]="salesConsultant"
              readOnly="true"></dx-text-box>
            <label *ngIf="!salesConsultant" class="col-sm-4 righttext" for="salesConsultant"></label>

            <label class="col-sm-2 righttext" for="isTemplate">Type
              <span class="required"> *</span>
            </label>
            <dx-select-box [dataSource]="jobTypes" class="col-sm-2 ms-0 px-0" id="jobTypeId" valueExpr="id"
              displayExpr="desc" formControlName="jobTypeId" searchEnabled="true"
              (onSelectionChanged)="setJobType($event)"></dx-select-box>

            <label *ngIf="!job?.isMasterJob" class="col-sm-1 righttext" for="masterJobId">Master</label>
            <dx-select-box *ngIf="!job?.isMasterJob" [dataSource]="jobs" class="col-sm-2 ms-0 px-0" id="masterJobId"
              valueExpr="id" displayExpr="jobNumber" formControlName="masterJobId" searchEnabled="true"
              [showClearButton]="true"></dx-select-box>

            <label *ngIf="job?.isMasterJob" class="col-sm-3 righttext pe-0" for="masterJobId">Is Master Job</label>
          </div>

          <div *ngIf="jobForm.get('masterJobId').value" class="form-group row">
            <label *ngIf="(jobNum !== '0' && jobNum !== '')" class="col-sm-11 righttext pe-0" for="isActive">
              Use master job {{addendumName}}?
              <dx-check-box id="useMasterJobAddenda" class="ms-1" formControlName="useMasterJobAddenda"
                [disabled]="!jobsAdminPermission" tabindex="-1"></dx-check-box>
            </label>
          </div>

          <div *ngIf="jobForm.get('masterJobId').value" class="form-group row mt-0">
            <label *ngIf="(jobNum !== '0' && jobNum !== '')" class="col-sm-11 righttext pe-0 mt-0" for="isActive">
              Use master job Documents?
              <dx-check-box id="useMasterJobDocuments" class="ms-1" formControlName="useMasterJobDocuments"
                [disabled]="!jobsAdminPermission" tabindex="-1"></dx-check-box>
            </label>
          </div>

          <div *ngIf="(isActiveCampaignActive || isHubSpotActive) && (jobsWrite || jobsAdmin)" class="form-group row">
            <label class="col-sm-2"></label>
            <div class="form-control-sm col-sm-9 px-0 pt-0 pb-0">
              <button *ngIf="!leadManagementId" class="btn btn-sm btn-outline-primary" type="button"
                (click)="getLeadManagementLink()">
                Connect to <span *ngIf="(!job || !job.salesDate)">& import from </span>
                <span *ngIf="isActiveCampaignActive">Active Campaign</span>
                <span *ngIf="isHubSpotActive">HubSpot</span>
              </button>
              <button *ngIf="!leadManagementId && allowCreateDeal" class="btn btn-sm btn-outline-primary ms-1"
                type="button" (click)="createLeadManagementDeal()">
                Create
                <span *ngIf="isActiveCampaignActive">Active Campaign</span>
                <span *ngIf="isHubSpotActive">HubSpot</span>
                Deal
              </button>
              <button *ngIf="isAdmin && leadManagementId" class="btn btn-sm btn-outline-primary" type="button"
                (click)="getLeadManagementFields()">
                <span *ngIf="isActiveCampaignActive">Active Campaign</span>
                <span *ngIf="isHubSpotActive">HubSpot</span>
                Deal Information
              </button>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="contractNameId">Contract Name
              <span class="required"> *</span>
            </label>
            <dx-text-area id="contractNameId" class="form-control-sm col-sm-9 px-0 textareaClass"
              formControlName="contractName" placeholder="Client Name (required)"></dx-text-area>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="salutation">Salutation</label>
            <dx-text-box id="salutation" class="col-sm-9 px-0" formControlName="salutation"
              placeholder="Dear ..."></dx-text-box>
          </div>

          <!-- house type & contract dates and amounts -->
          <div *ngIf="isSalesVariationsActive" class="form-group row">
            <label class="col-sm-2 righttext" for="houseTypeId">House Type
              <span *ngIf="isRequired(jobForm.get('houseTypeId'))" class="required"> *</span>
            </label>

            <dx-select-box [dataSource]="houseTypes" class="col-sm-3 ms-0 px-0" id="houseTypeId" valueExpr="id"
              displayExpr="description" formControlName="houseTypeId" searchEnabled="true"
              (onSelectionChanged)="setHouseType($event)"></dx-select-box>

            <label *ngIf="canReadAmountsPermission" class="col-sm-4 righttext" for="budgetAmount">Budget</label>
            <dx-number-box *ngIf="canReadAmountsPermission" id="budgetAmount" class="col-sm-2 px-0 dxnumber-righttext"
              formControlName="budgetAmount" format="$ #,###" autocomplete="off"></dx-number-box>
          </div>

          <div *ngIf="isSalesVariationsActive" class="form-group row">
            <label class="col-sm-2 righttext" for="houseModificationDescription">Modifications</label>
            <dx-select-box [dataSource]="houseModifications" class="col-sm-3 px-0" id="houseModificationDropdown"
              valueExpr="description" displayExpr="description" formControlName="houseModificationDescription"
              [acceptCustomValue]="true" placeholder="" searchEnabled="true"
              (onCustomItemCreating)="addCustomHouseModificationDescription($event)"
              [showClearButton]="true"></dx-select-box>
          </div>

          <div *ngIf="isSalesVariationsActive" class="form-group row">
            <label *ngIf="jobNum !== '0' && jobNum !== ''" class="col-sm-2 righttext" for="salesDateId">Sales
              Date</label>
            <div *ngIf="jobNum !== '0' && jobNum !== ''" class="form-control-sm col-sm-5 px-0 pt-0 pb-0">
              <dx-date-box id="salesDateId" formControlName="salesDate" type="date" (onBlur)="checkSaleDate($event)"
                [readOnly]="!jobsAdminPermission" [showClearButton]="true"
                [calendarOptions]="{ showTodayButton: true }"></dx-date-box>

              <span *ngIf="showContractsRequiredByField && showContractsRequiredByDate">
                <span class="ms-2" for="contractsRequiredByDate">
                  Contracts Required By
                </span>
                <dx-date-box id="contractsRequiredByDate" formControlName="contractsRequiredByDate" type="date"
                  [showClearButton]="true"></dx-date-box>
              </span>
            </div>

            <!-- sales date cannot be entered as we will go on to try and create the sales variations -->
            <label *ngIf="jobNum === '0' || jobNum === ''" class="col-sm-7 control-label-sm"></label>

            <label *ngIf="canReadAmountsPermission" class="col-sm-2 righttext" for="basePrice">Base Price</label>

            <dx-number-box *ngIf="jobsAdminPermission || canReadAmountsPermission" id="basePrice"
              class="col-sm-2 px-0 dxnumber-righttext" formControlName="basePrice" format="$ #,###" autocomplete="off"
              [readOnly]="!jobsAdminPermission" [class.red]="!priceAsPerMasterHouseType"></dx-number-box>

            <button *ngIf="estimatingReadPermission && variationPriceEstimatingEnabled && !phoneSize"
              class='btn btn-sm btn-outline-primary calcButton displayContents'
              (click)='$event.preventDefault(); estimatePrice()'><i class="material-icons">keyboard</i></button>
          </div>

          <div *ngIf="isSalesVariationsActive" class="form-group row">
            <label *ngIf="jobNum !== '0' && jobNum !== ''" class="col-sm-2 righttext"
              for="contractSignedDateId">Contract Signed</label>
            <div *ngIf="jobNum !== '0' && jobNum !== ''" class="form-control-sm col-sm-3 px-0 pt-0 pb-0">
              <dx-date-box id="contractSignedDateId" formControlName="contractSignedDate" type="date"
                (onBlur)="checkContractSigned($event)" [readOnly]="!jobsAdminPermission" [showClearButton]="true"
                [calendarOptions]="{ showTodayButton: true }"></dx-date-box>
            </div>

            <label *ngIf="jobNum === '0' || jobNum === ''" class="col-sm-5 control-label-sm"></label>

            <label *ngIf="canReadAmountsPermission" class="col-sm-4 righttext" for="depositAmount">Deposit</label>
            <dx-number-box *ngIf="canReadAmountsPermission" id="depositAmount" class="col-sm-2 px-0 dxnumber-righttext"
              formControlName="depositAmount" format="$ #,###" autocomplete="off"></dx-number-box>
          </div>

          <div *ngIf="!isSalesVariationsActive" class="form-group row">
            <label class="col-sm-2 righttext" for="contractSignedDateId">Contract Signed</label>
            <div class="form-control-sm col-sm-3 px-0 pt-0 pb-0">
              <dx-date-box id="contractSignedDateId" formControlName="contractSignedDate" type="date"
                (onBlur)="checkContractSigned($event)" [readOnly]="!jobsAdminPermission" [showClearButton]="true"
                [calendarOptions]="{ showTodayButton: true }"></dx-date-box>
            </div>

            <label *ngIf="canReadAmountsPermission" class="col-sm-4 righttext" for="basePrice">Contract Price</label>
            <dx-number-box *ngIf="jobsAdminPermission || canReadAmountsPermission" id="basePrice"
              class="col-sm-2 px-0 dxnumber-righttext" formControlName="basePrice" format="$ #,###" autocomplete="off"
              [readOnly]="!jobsAdminPermission"></dx-number-box>
          </div>

          <!-- If we are using the 2 deposit field -->
          <div *ngIf="isSecondDepositVisible" class="form-group row">
            <label class="col-sm-9 righttext" for="depositAmount2">2nd Deposit</label>
            <dx-number-box id="depositAmount2" class="col-sm-2 px-0 dxnumber-righttext" formControlName="depositAmount2"
              format="$ #,###" autocomplete="off"></dx-number-box>
          </div>

          <!-- If we are using the deposit paid fields we show them -->
          <div *ngIf="isDepositPaidActive && canReadAmountsPermission" class="form-group row">
            <label class="col-sm-2 righttext" for="completionDate">
              <span *ngIf="jobNum !== '0' && jobNum !== ''">Practical Completion</span>
            </label>
            <div class="form-control-sm col-sm-3 px-0 pt-0 pb-0">
              <dx-date-box *ngIf="jobNum !== '0' && jobNum !== ''" id="completionDate" formControlName="completionDate"
                type="date" readOnly="true"></dx-date-box>
            </div>

            <label class="col-sm-4 righttext" for="depositPaid">Deposit Paid</label>
            <dx-number-box id="depositPaid" class="col-sm-2 px-0 dxnumber-righttext" formControlName="depositPaid"
              format="$ #,###" autocomplete="off"></dx-number-box>
          </div>

          <div *ngIf="isDepositPaidActive && canReadAmountsPermission" class="form-group row">
            <label *ngIf="jobExtra && jobExtra.completionDate" class="col-sm-2 righttext" for="handoverDate">
              Key Handover
            </label>
            <div *ngIf="jobExtra && jobExtra.completionDate" class="form-control-sm col-sm-3 px-0 pt-0 pb-0">
              <dx-date-box id="handoverDate" formControlName="handoverDate" type="date" readOnly="true"></dx-date-box>
            </div>

            <label *ngIf="jobExtra && jobExtra.completionDate" class="col-sm-4 righttext" for="depositBalanceDue">
              Deposit Due
            </label>
            <label *ngIf="!jobExtra || !jobExtra.completionDate" class="col-sm-9 righttext" for="depositBalanceDue">
              Deposit Due
            </label>
            <div class="form-control-sm col-sm-2 px-0 pt-0 pb-0">
              <dx-date-box id="depositBalanceDue" formControlName="depositBalanceDue" type="date"
                [showClearButton]="true" [calendarOptions]="{ showTodayButton: true }"></dx-date-box>
            </div>
          </div>

          <div class="form-group row">
            <label *ngIf="jobExtra && jobExtra.completionDate" class="col-sm-2 righttext" for="maintenanceCompleteDate">
              Maintenance Complete
            </label>
            <div *ngIf="jobExtra && jobExtra.completionDate" class="form-control-sm col-sm-3 px-0 pt-0 pb-0">
              <dx-date-box id="maintenanceCompleteDate" formControlName="maintenanceCompleteDate" type="date"
                readOnly="true"></dx-date-box>
              <button *ngIf="!phoneSize && jobsAdminPermission" class="btn btn-sm btn-outline-primary ms-1"
                type="button" (click)="maintenanceComplete()">Complete
              </button>
            </div>

            <label *ngIf="isSalesQuoteSignedDateShown && jobExtra && jobExtra.completionDate" class="col-sm-4 righttext"
              for="salesQuoteSignedDate">
              Sales Quote Signed
            </label>
            <label *ngIf="isSalesQuoteSignedDateShown && (!jobExtra || !jobExtra.completionDate)"
              class="col-sm-9 righttext" for="salesQuoteSignedDate">
              Sales Quote Signed
            </label>
            <div *ngIf="isSalesQuoteSignedDateShown" class="form-control-sm col-sm-2 px-0 pt-0 pb-0">
              <dx-date-box id="salesQuoteSignedDate" formControlName="salesQuoteSignedDate" type="date"
                [showClearButton]="true" [calendarOptions]="{ showTodayButton: true }"></dx-date-box>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="m-b-16">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-weight: bold">
            Job Address
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div formGroupName="jobAddress">
          <div *ngIf="isLandDatabaseActive && jobsWritePermission && landMasters && landMasters.length"
            class="form-group row">
            <label class="col-sm-2 righttext" for="estateId">Land#</label>
            <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="landMasters" formControlName="landId"
              displayExpr="landNumber" valueExpr="id" contentTemplate="contentTemplate" class="col-sm-2 px-0"
              [showClearButton]="true">
              <div *dxTemplate="let e of 'contentTemplate'">
                <dx-data-grid [dataSource]="landMasters" keyExpr="id" [remoteOperations]="false" height="99%"
                  [showBorders]="true" [focusedRowEnabled]="true"
                  [focusedRowKey]="jobForm.get('jobAddress.landId').value" [autoNavigateToFocusedRow]="true"
                  [hoverStateEnabled]="true" (onSelectionChanged)="onLandMasterChanged(e.component, $event)">

                  <dxo-search-panel visible="true" [width]="200"></dxo-search-panel>
                  <dxo-paging [enabled]="false"></dxo-paging>
                  <dxo-selection mode="single"></dxo-selection>

                  <dxi-column dataField="landNumber" [width]="150"></dxi-column>
                  <dxi-column dataField="address.lotNumber" caption="Lot" [width]="100"></dxi-column>
                  <dxi-column dataField="address.streetName1" caption="Street"></dxi-column>
                  <dxi-column dataField="address.suburbTown" caption="Suburb" [width]="150"></dxi-column>
                </dx-data-grid>
              </div>
            </dx-drop-down-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="stNumber">House#</label>
            <dx-text-box id="stNumber" class="col-sm-2 px-0" formControlName="streetNumber" placeholder="Enter Street#"
              (onValueChanged)="checkDuplicateAddress()"></dx-text-box>

            <label class="col-sm-2 righttext" for="lotNumberId">Lot#</label>
            <dx-text-box id="stNumber" class="col-sm-1 px-0" formControlName="lotNumber" placeholder="Enter Lot#"
              (onValueChanged)="checkDuplicateAddress()"></dx-text-box>

            <label class="col-sm-2 righttext" for="planNumber">Plan Number</label>
            <dx-text-box id="planNumber" class="col-sm-2 px-0" formControlName="planNumber" placeholder=""
              autocomplete="off" tabindex="2000"></dx-text-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="st1Id">Street Name</label>
            <dx-text-box id="st1Id" name="st1Id" class="col-sm-5 px-0" formControlName="streetName1"
              (onValueChanged)="checkDuplicateAddress()"></dx-text-box>

            <label class="col-sm-2 righttext" for="volume">Volume</label>
            <dx-text-box id="volume" class="col-sm-2 px-0" formControlName="volume" placeholder="" autocomplete="off"
              tabindex="2001"></dx-text-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2" for="stName2Id"></label>
            <dx-text-box id="stName2Id" class="col-sm-5 px-0" formControlName="streetName2">
            </dx-text-box>

            <label class="col-sm-2 righttext" for="folio">Folio</label>
            <dx-text-box id="folio" class="col-sm-2 px-0" formControlName="folio" placeholder="" autocomplete="off"
              tabindex="2002"></dx-text-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="suburbTownId">Suburb</label>
            <dx-text-box id="suburbTownId" name="suburbTownId" class="col-sm-3 px-0" formControlName="suburbTown"
              (onValueChanged)="checkDuplicateAddress()"></dx-text-box>

            <dx-text-box id="stateId" class="col-sm-1 px-0" formControlName="state" placeholder="State"></dx-text-box>

            <dx-text-box id="postCodeId" class="col-sm-1 px-0" formControlName="postCode"
              placeholder="Postcode"></dx-text-box>

            <label class="col-sm-2 righttext" for="council">Council</label>
            <dx-text-box id="council" class="col-sm-2 px-0" formControlName="council" placeholder="" autocomplete="off"
              tabindex="2003"></dx-text-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="estateId">Estate</label>
            <dx-select-box [dataSource]="estates" class="col-sm-3 px-0" id="estate" #estate valueExpr="name"
              displayExpr="name" formControlName="estate" [acceptCustomValue]="true" placeholder="" searchEnabled="true"
              (onCustomItemCreating)="addCustomItem($event)" [showClearButton]="true"></dx-select-box>

            <label class="col-sm-4 righttext" for="landType">Land Type</label>
            <dx-select-box [dataSource]="landTypes" class="col-sm-2 px-0" id="landType" valueExpr="id"
              displayExpr="description" formControlName="landTypeId" searchEnabled="true" [showClearButton]="true"
              tabindex="2004" placeholder=""></dx-select-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="landPrice">Land Price</label>
            <dx-number-box id="landPrice" class="col-sm-2 px-0" formControlName="landPrice" format="$ #,###"
              autocomplete="off"></dx-number-box>

            <label class="col-sm-5 righttext" for="zoning">Zoning</label>
            <dx-select-box [dataSource]="landZones" class="col-sm-2 px-0" id="landZoningId" valueExpr="id"
              displayExpr="description" formControlName="landZoningId" searchEnabled="true" [showClearButton]="true"
              tabindex="2005" placeholder=""></dx-select-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="latitude">Latitude</label>
            <dx-number-box id="latitude" class="col-sm-2 px-0" formControlName="latitude" format="#.########"
              autocomplete="off"></dx-number-box>

            <label class="col-sm-5 righttext" for="landTitleTypeId">Type of Title</label>
            <dx-select-box [dataSource]="landTitleTypes" class="col-sm-2 px-0" id="landTitleTypeId" valueExpr="id"
              displayExpr="description" formControlName="landTitleTypeId" searchEnabled="true" [showClearButton]="true"
              tabindex="2006" placeholder=""></dx-select-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="longitude">Longitude</label>
            <dx-number-box id="longitude" class="col-sm-2 px-0" formControlName="longitude" format="#.########"
              autocomplete="off"></dx-number-box>

            <label class="col-sm-5 righttext" for="titleDueDate">Title Due</label>
            <dx-date-box class="col-sm-2 px-0 pt-0 pb-0" id="titleDueDate" formControlName="titleDueDate" type="date"
              [showClearButton]="true" [calendarOptions]="{ showTodayButton: true }"></dx-date-box>
          </div>
        </div>

      </mat-expansion-panel>

      <mat-expansion-panel class="m-b-16">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-weight: bold">
            Client Address
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div formGroupName="contractAddress">
          <div class="form-group row">
            <label class="col-sm-2 righttext" for="stNumber2">House#</label>
            <dx-text-box id="stNumber2" class="col-sm-2 px-0" formControlName="streetNumber" placeholder="Enter Street#"
              autocomplete="off"></dx-text-box>

            <label class="col-sm-2 righttext" for="lotNumber2Id">Lot#</label>
            <dx-text-box id="lotNumber2Id" class="col-sm-1 px-0" formControlName="lotNumber" placeholder="Enter Lot#"
              autocomplete="off"></dx-text-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="stName1Id">Street Name</label>
            <dx-text-box id="stName1Id" class="col-sm-5 px-0" formControlName="streetName1"
              placeholder="Enter Street Name" autocomplete="off"></dx-text-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2" for="streetName2Id"></label>
            <dx-text-box id="streetName2Id" class="col-sm-5 px-0" formControlName="streetName2" placeholder=""
              autocomplete="off"></dx-text-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="suburbTown2Id">Suburb</label>
            <dx-text-box id="suburbTown2Id" class="col-sm-3 px-0" formControlName="suburbTown"
              placeholder="Enter Suburb" autocomplete="off"></dx-text-box>
            <dx-text-box id="state2Id" class="col-sm-1 px-0" formControlName="state" placeholder="State"
              autocomplete="off"></dx-text-box>
            <dx-text-box id="postCode2Id" class="col-sm-1 px-0" formControlName="postCode" placeholder="Postcode"
              autocomplete="off"></dx-text-box>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="m-b-16" (opened)="clientDetailsOpened()">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-weight: bold">
            Contact Details and Client Settings
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div class="form-group row">
            <label class="col-sm-2 righttext" for="phoneNumber1">Contact 1</label>
            <dx-text-box id="phoneNumber1" class="col-sm-2 px-0" formControlName="phoneNumber1"
              placeholder="Phone number" tabindex="3001"></dx-text-box>
            <dx-text-box id="firstName1" class="col-sm-2 px-0" formControlName="firstName1" placeholder="First name.."
              tabindex="3002"></dx-text-box>
            <dx-text-box id="lastName1" class="col-sm-2 px-0" formControlName="lastName1" placeholder="Last name.."
              tabindex="3003"></dx-text-box>
            <dx-text-box id="phoneNotes1" class="col-sm-3 px-0" formControlName="phoneNotes1"
              placeholder="Notes for this contact" tabindex="3004"></dx-text-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="phoneNumber2">Contact 2</label>
            <dx-text-box class="col-sm-2 px-0" id="phoneNumber2" formControlName="phoneNumber2"
              placeholder="Phone number" tabindex="3005"></dx-text-box>
            <dx-text-box class="col-sm-2 px-0" id="firstName2" formControlName="firstName2" placeholder="First name.."
              tabindex="3006"></dx-text-box>
            <dx-text-box class="col-sm-2 px-0" id="lastName2" formControlName="lastName2" placeholder="Last name.."
              tabindex="3007"></dx-text-box>
            <dx-text-box class="col-sm-3 px-0" id="phoneNotes2" formControlName="phoneNotes2"
              placeholder="Notes for this contact" tabindex="3008"></dx-text-box>
          </div>

          <div class="form-group row m-b-20">
            <label class="col-sm-2 righttext" for="clientEmailId">Email</label>
            <dx-text-area id="clientEmailId" class="col-sm-4 px-0" formControlName="clientEmail"
              placeholder="Enter client email address(s)" tabindex="3009"></dx-text-area>

            <label *ngIf="emailUpdatesToClientsActive" class="col-sm-5 righttext pe-0" for="isEmailClientUpdates">
              Notify client of task updates?
              <dx-check-box id="isEmailClientUpdates" class="ms-2 mt-1 me-0 pe-0" formControlName="isEmailClientUpdates"
                tabindex="3010"></dx-check-box>
            </label>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="warningNoteId">Warning Note</label>
            <dx-text-area id="warningNoteId" class="col-sm-9 px-0" formControlName="warningNote"
              placeholder="Enter any warning notes e.g. Overhead Power" tabindex="3011"></dx-text-area>
          </div>

          <div class="form-group row m-b-20">
            <label class="col-sm-2 righttext" for="generalNotesId">General Notes</label>
            <dx-text-area id="generalNotesId" class="col-sm-9 px-0" formControlName="generalNotes"
              [placeholder]="placeHolder" tabindex="3012"></dx-text-area>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="customerLoginEmail">Client Login</label>
            <dx-select-box id="customerLoginEmail" class="col-sm-4 px-0" formControlName="customerLoginEmail"
              [dataSource]="customerLogins" valueExpr="email" displayExpr="email" searchEnabled="true"
              (onSelectionChange)="customerLoginChanged($event)" [showClearButton]="true"></dx-select-box>

            <div class="col-sm-2">
              <dx-button text="Add" type="default" stylingMode='outlined' (onClick)="addClientUser()"> </dx-button>
            </div>

            <label class="col-sm-3 righttext p-r-0">
              <span *ngIf="showClientLastLoggedIn">
                <span class="m-r-5">Last logged in:</span>
                <span class="m-t-5 bold" [ngClass]="{'warn-red' : lastLoggedInErr}">
                  {{lastLoggedInErr ? 'Error' : clientLastLoggedIn ? (clientLastLoggedIn | date) : 'Never'}}
                </span>
              </span>
            </label>
          </div>

          <div *ngIf="truthEngineClientPortalActive" class="form-group row">
            <label class="col-sm-2 righttext p-r-0"> </label>
            <dx-check-box class="col-sm-9 px-0" formControlName="sendInfoUpdateEmail"
              text="Send email to client re updates to portal" [rtlEnabled]="false"></dx-check-box>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="lenderInformationEnabled && job" class="m-b-16">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-weight: bold">
            Lender Information
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div class="form-group row">
            <label class="col-sm-2 righttext" for="bankId">Lender</label>
            <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="banks" [value]="bankId"
              [displayExpr]="displayExpr" valueExpr="id" contentTemplate="contentTemplate" class="col-sm-7 px-0"
              [showClearButton]="true">
              <div *dxTemplate="let e of 'contentTemplate'">
                <dx-data-grid [dataSource]="banks" keyExpr="id" [remoteOperations]="false" height="99%"
                  [showBorders]="true" [focusedRowEnabled]="true" [focusedRowKey]="bankId"
                  [autoNavigateToFocusedRow]="true" [hoverStateEnabled]="true"
                  (onSelectionChanged)="onBankSelectionChanged(e.component, $event)">

                  <dxo-paging [enabled]="false"></dxo-paging>
                  <dxo-selection mode="single"></dxo-selection>

                  <dxi-column dataField="description"></dxi-column>
                  <dxi-column dataField="contactName" [width]="150"></dxi-column>
                  <dxi-column dataField="contactPhone"></dxi-column>
                  <dxi-column dataField="contactEmail"></dxi-column>
                </dx-data-grid>
              </div>
            </dx-drop-down-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="loanReference">Loan Reference</label>
            <dx-text-box id="loanReference" class="col-sm-6 px-0" [(value)]="loanReference"
              placeholder="Enter loan reference" autocomplete="off"></dx-text-box>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 righttext" for="loanAmount">Construction Loan</label>
            <dx-number-box id="loanAmount" class="col-sm-2 px-0 dxnumber-righttext" [(value)]="loanAmount"
              format="$ #,###" autocomplete="off"></dx-number-box>
          </div>

          <div class="form-group row mt-3">
            <label class="col-sm-2"></label>
            <dx-button text="Run Loan Shortfall Report" type="default" stylingMode='outlined' class="col-sm-3"
              (onClick)="runLoanShortfall()">
            </dx-button>
          </div>

        </div>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="trackingFieldGroups && trackingFieldGroups.length && job && showJobData && isNotRepOrIsRepForJob"
        class="m-b-55">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-weight: bold">
            Job Data
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <js-job-fields [jobId]="job.id" [saveJobData]="saveJobData" [reload]="reloadJobData" (saveDone)="saveDone()">
          </js-job-fields>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="submit-row">
      <button *ngIf="jobsWrite || jobsAdmin" class="btn btn-sm btn-primary ms-2" type="submit"
        tabindex="9001">Save</button>
      <ng-container *ngIf="!xtraSmlSize">
        <button
          *ngIf="jobNum !== '0' && jobNum !== '' && job && (job.jobTypeId === jobTypeEnum.StandardJob || job.jobTypeId === jobTypeEnum.TestJob)"
          type="button" class="btn btn-sm btn-secondary ms-2" (click)='assignUsers()' tabindex="9002">Roles</button>
        <button
          *ngIf="jobsAdmin && jobNum !== '0' && jobNum !== '' && job && (job.jobTypeId === jobTypeEnum.StandardJob || job.jobTypeId === jobTypeEnum.TestJob)"
          type="button" class="btn btn-sm btn-secondary ms-2" (click)='tradeAccess()' tabindex="9003">Trades</button>
        <button
          *ngIf="claimScheduleEnabled && claimsReadPermission && jobNum !== '0' && jobNum !== '' && job?.salesDate"
          type="button" class="btn btn-sm btn-secondary ms-2" (click)='claimLines()' tabindex="9006">Claims</button>
      </ng-container>
      <button *ngIf="isAdmin && jobNum !== '0' && jobNum !== '' && !phoneSize" type="button"
        class="btn btn-sm btn-secondary ms-2" (click)='changeJobNumber()' tabindex="9005">Change Job Number</button>
      <button *ngIf="jobsAdmin && jobNum !== '0' && jobNum !== '' && !job?.isLocked" type="button"
        class="btn btn-sm btn-danger ms-2" (click)='deleteJob(content)' tabindex="9004">Delete</button>
    </div>
  </fieldset>
</form>

<!-- Price check - not as per house type -->
<dx-popup class="popup" [width]="410" [height]="130" [showTitle]="true" title="Price Check" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="showPriceCheckPopup">
  <div *dxTemplate="let data of 'content'">
    Base price is different to master house type
    <dx-button text="Accept" type="default"
      (onClick)="showPriceCheckPopup = false; priceAsPerMasterHouseType = true; save()">
    </dx-button>
  </div>
</dx-popup>

<!-- Price check - after contract has been signed -->
<dx-popup class="popup" [width]="440" [height]="130" [showTitle]="true" title="Price Check" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="showPriceAfterDate">
  <div *dxTemplate="let data of 'content'">
    Base price has been changed after signed date
    <dx-button text="Accept" type="default"
      (onClick)="showPriceAfterDate = false; priceAsPerMasterHouseType = true; saveGo()">
    </dx-button>
  </div>
</dx-popup>

<!-- check when marking a job inactive -->
<dx-popup class="popup" [width]="280" [height]="170" [showTitle]="true" title="Check" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [(visible)]="cancelOpenVariationsPopupVisible">
  <div *dxTemplate="let data of 'content'">
    <div>Mark open variations as cancelled?</div>
    <br>
    <dx-button text="Accept & Continue" type="default" (onClick)="updateJobGo(true)">
    </dx-button>
  </div>
</dx-popup>

<!-- check when opening contact details -->
<dx-popup class="popup" [width]="warningPopupWidth" [height]="200" [showTitle]="true" title="Warning"
  [dragEnabled]="true" [hideOnOutsideClick]="true" [(visible)]="showWarningNotePopup" [shading]="false">
  <div *dxTemplate="let data of 'content'">
    <dx-text-area [value]="job.warningNote" [height]="100" class="warningClass" [disabled]="true"></dx-text-area>
  </div>
</dx-popup>

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel [(visible)]="loading" [showIndicator]="true" [showPane]="true" [shading]="false"
  [hideOnOutsideClick]="false">
</dx-load-panel>
