<div class="card">
  <div #header class="card-header">

    <!-- enter job number -->
    <js-job-number (refreshJobNumber)='refreshJobNumber($event)'></js-job-number>

    <div *ngIf="(jobNum !== '') && !loading && !loadingJob && (_jobItemService.selectionsMode === 'Master')"
      class="rowMaster">
      <a *ngIf="openVariations" class="openVariationsClass" [routerLink]="['/variations']">WARNING:
        There are variations waiting finalisation!</a>
      <div *ngIf="_jobItemService.isLocked && !openVariations && !useUploadedAddenda" class="approvedVarClass">No
        variations are waiting approval</div>
      <div *ngIf="useUploadedAddenda && !openVariations" class="openVariationsClass">
        Replacement PDF loaded !!
      </div>

      <span ngbDropdown placement="bottom-right" class="settingsbutton">
        <button class="btn-sm btn-outline-primary dropdownclass2 dropdownHeight31" id="configmenu1" ngbDropdownToggle>
          <i class="material-icons">
            settings
          </i>
        </button>
        <div ngbDropdownMenu aria-labelledby="configmenu1">
          <button class="dropdown-item"
            (click)='_jobItemService.showHistoryOfChanges = !_jobItemService.showHistoryOfChanges; showHistoryClicked()'>
            Show history of changes
            <i *ngIf="(_jobItemService.showHistoryOfChanges)" class="material-icons">done</i>
            <i *ngIf="(!_jobItemService.showHistoryOfChanges)" class="material-icons">clear</i>
          </button>
          <button *ngIf="(!isClient && !isAssociate)" class="dropdown-item" (click)='allowPanelsChanged()'>
            Keep panels open
            <i *ngIf="(!closeOtherPanels)" class="material-icons">done</i>
            <i *ngIf="(closeOtherPanels)" class="material-icons">clear</i>
          </button>
          <button *ngIf="(!(_jobItemService.isLocked) || isAdminOrSuperUser) && !isClient && !isAssociate"
            class="dropdown-item"
            (click)='_jobItemService.showSetupLines = !_jobItemService.showSetupLines ; showSetupLinesFilter()'>
            Show set-up lines
            <i *ngIf="(_jobItemService.showSetupLines)" class="material-icons">done</i>
            <i *ngIf="(!_jobItemService.showSetupLines)" class="material-icons">clear</i>
          </button>
          <button *ngIf="!(_jobItemService.isLocked) && !isClient && !isAssociate" class="dropdown-item"
            (click)='_jobItemService.showHiddenLines = !_jobItemService.showHiddenLines ; showSetupLinesFilter()'>
            Show hidden lines
            <i *ngIf="(_jobItemService.showHiddenLines)" class="material-icons">done</i>
            <i *ngIf="(!_jobItemService.showHiddenLines)" class="material-icons">clear</i>
          </button>
          <button *ngIf="(!isClient && !isAssociate)" class="dropdown-item" (click)='showImages = !showImages'>
            Show images
            <i *ngIf="(showImages)" class="material-icons">done</i>
            <i *ngIf="(!showImages)" class="material-icons">clear</i>
          </button>
          <button *ngIf="(!isClient && !isAssociate)" class="dropdown-item"
            (click)='_jobItemService.showNotPrintedItems = !_jobItemService.showNotPrintedItems; showNotPrintedClicked()'>
            Show items 'Not Printed'
            <i *ngIf="(_jobItemService.showNotPrintedItems)" class="material-icons">done</i>
            <i *ngIf="(!_jobItemService.showNotPrintedItems)" class="material-icons">clear</i>
          </button>

          <button *ngIf="(!isClient && !isAssociate)" class="dropdown-item"
            (click)='_jobItemService.showNotApplicableItems = !_jobItemService.showNotApplicableItems; showNotPrintedClicked()'>
            Show items 'Not Applicable'
            <i *ngIf="(_jobItemService.showNotApplicableItems)" class="material-icons">done</i>
            <i *ngIf="(!_jobItemService.showNotApplicableItems)" class="material-icons">clear</i>
          </button>

          <button *ngIf="!_jobItemService.isLocked && jobLineSetupWrite" class="dropdown-item"
            (click)='addJobItemBoard()'>
            Apply selection board</button>
          <button *ngIf="!_jobItemService.isLocked && !anyVariations && jobLineSetupWrite" class="dropdown-item"
            (click)='copyJob(true)'>
            Copy job
          </button>
          <button
            *ngIf="jobLineSetupAdmin && !_jobItemService.isLocked && (!_jobItemService.currentJobItems || !_jobItemService.currentJobItems.length)"
            class="dropdown-item" (click)="copyFromFinalJob()">
            Copy From a FINAL Job
          </button>
          <button class="dropdown-item" (click)='getPDF()'>
            Get {{addendumName}} PDF
          </button>
          <button *ngIf="!isClient && !isAssociate && canSeeAmountsPermission" class="dropdown-item"
            (click)='getSalesQuotePDF(true)'>
            Get Sales Quote
          </button>
          <button *ngIf="currentJob?.salesDate && !isClient && !isAssociate && canSeeAmountsPermission"
            class="dropdown-item" (click)='getSalesQuotePDF(false)'>
            Get Contract Quote
          </button>
          <button *ngIf="(!isClient && !isAssociate) && !currentJob?.isLocked" class="dropdown-item"
            (click)="gotoHouseOptions()">
            House Options
          </button>
          <button *ngIf="jobLineSetupAdmin" class="dropdown-item" (click)='getExcelExport()'>
            Export to Excel
          </button>
          <button *ngIf="_jobItemService.isLocked && isAdminOrSuperUser" class="dropdown-item"
            (click)='adminShowHideButtons()'>
            Turn Edit Buttons On
          </button>
          <button *ngIf="(jobNum !== '') && jobLineSetupAdmin" class="dropdown-item" (click)='checkShowRulesReport()'>
            Check Show/Hide Rules
          </button>
          <button *ngIf="(jobNum !== '') && (!isClient && !isAssociate)" class="dropdown-item"
            (click)="gotoVariations()">
            Go to Variations
          </button>
          <button *ngIf="(!isClient && !isAssociate)" class="dropdown-item" (click)="showJobRoles()">
            Job Roles
          </button>
          <button *ngIf="(jobNum !== '') && !_jobItemService.isLocked && !isClient && !isAssociate"
            class="dropdown-item" (click)="clearJobItemHighlighting()">
            Clear Highlighting
          </button>
          <button *ngIf="(jobNum !== '') && (jobLineSetupAdmin || jobLinesSetupOnlyAdmin)" class="dropdown-item"
            (click)="loadAddendaPdf()">
            {{addendumName}} Replacement
          </button>
          <button *ngIf="(jobNum !== '') && !_jobItemService.isLocked && (jobLineSetupAdmin || jobLinesSetupOnlyAdmin)"
            class="dropdown-item" (click)="lockJob()">
            Lock Master
          </button>
          <button
            *ngIf="(jobNum !== '') && _jobItemService.isLocked && haveCheckedForVariations && !anyPostContractVOs && (jobLineSetupAdmin || jobLinesSetupOnlyAdmin)"
            class="dropdown-item" (click)="unlockJob()">
            Unlock Master
          </button>
        </div>
      </span>

      <form *ngIf="!isRestrictedForm" [formGroup]="filterForm" novalidate class="form-inline settingsbutton">
        <div class="form-group searchRow">

          <button *ngIf="!isClient && !isAssociate && innerWidth > 900" type="button"
            class="btn btn-sm btn-outline-primary me-2" (click)="showNotPrintedClicked(true)">
            <span *ngIf="(_jobItemService.showNotPrintedItems)">Show {{addendumName}} only</span>
            <span *ngIf="!(_jobItemService.showNotPrintedItems)">Show Sections 'Not Printed'</span>
          </button>

          <button type="button" class="btn btn-sm btn-outline-primary unfoldButton" (click)="collapsePanels()"
            matTooltip="Collapse All" matTooltipPosition="above" [matTooltipShowDelay]="400">
            <i class="material-icons">unfold_less</i>
          </button>
          <button type="button" class="btn btn-sm btn-outline-primary unfoldButton" (click)="openPanels()"
            matTooltip="Expand All" matTooltipPosition="above" [matTooltipShowDelay]="400">
            <i class="material-icons">unfold_more</i>
          </button>

          <label for="filterText" class="col-form-label filterLabelClass"></label>
          <input id="filterText" class="form-control form-control-sm filterTextClass"
            [class.yellowBackground]="_jobItemService.filterText !== ''" type="text" placeholder="Word search..."
            formControlName="filterText" (keyup)="runFilter()" />

          <span *ngIf="!isAssociate && !isClient" ngbDropdown placement="bottom-right">
            <button class="btn-sm btn-outline-primary dropdownHeight31" id="configfilter" ngbDropdownToggle></button>
            <div ngbDropdownMenu aria-labelledby="configfilter">
              <button class="dropdown-item" (click)='filterByPSItems()'>
                PS Items
              </button>
              <button class="dropdown-item" (click)='filterByUnSelected()'>
                Un-Selected Items
              </button>
            </div>
          </span>
          <button type="button" class="btn btn-sm btn-primary" (click)="clearFilter()">Clear</button>

        </div>
      </form>
    </div>

    <div *ngIf="(jobNum !== '') && !loading && !loadingJob && (_jobItemService.selectionsMode === 'Variations')"
      class="rowMaster">
      <js-select-variation [variationNumber]=variationNumber (variation)='setVariationNum($event)'>
      </js-select-variation>

      <span *ngIf="_jobItemService.currentVariation" ngbDropdown placement="bottom-right" class="settingsbutton">
        <button class="btn-sm btn-outline-primary dropdownclass2 dropdownHeight31" id="configmenu2" ngbDropdownToggle>
          <i class="material-icons">settings</i>
        </button>
        <div ngbDropdownMenu aria-labelledby="configmenu2">
          <button *ngIf="!isClient && !isAssociate && isVariationOpen" class="dropdown-item"
            (click)='_jobItemService.showOnlyAddsChanges = !_jobItemService.showOnlyAddsChanges; showOnlyThisVOClicked()'>
            Show the items in this variation only
            <i *ngIf="(_jobItemService.showOnlyAddsChanges)" class="material-icons">done</i>
            <i *ngIf="(!_jobItemService.showOnlyAddsChanges)" class="material-icons">clear</i>
          </button>
          <button *ngIf="(!isClient && !isAssociate)" class="dropdown-item" (click)='allowPanelsChanged()'>
            Keep panels open
            <i *ngIf="(!closeOtherPanels)" class="material-icons">done</i>
            <i *ngIf="(closeOtherPanels)" class="material-icons">clear</i>
          </button>
          <button class="dropdown-item" (click)='showImages = !showImages'>
            Show images
            <i *ngIf="(showImages)" class="material-icons">done</i>
            <i *ngIf="(!showImages)" class="material-icons">clear</i>
          </button>
          <button class="dropdown-item" (click)='showNumberingClicked()'>
            Show numbering
            <i *ngIf="(showNumbering)" class="material-icons">done</i>
            <i *ngIf="(!showNumbering)" class="material-icons">clear</i>
          </button>

          <button *ngIf="(!isClient && !isAssociate)" class="dropdown-item"
            (click)='_jobItemService.showNotPrintedItems = !_jobItemService.showNotPrintedItems; showNotPrintedClicked()'>
            Show items 'Not Printed'
            <i *ngIf="(_jobItemService.showNotPrintedItems)" class="material-icons">done</i>
            <i *ngIf="(!_jobItemService.showNotPrintedItems)" class="material-icons">clear</i>
          </button>

          <button *ngIf="(!isClient && !isAssociate)" class="dropdown-item"
            (click)='_jobItemService.showNotApplicableItems = !_jobItemService.showNotApplicableItems; showNotPrintedClicked()'>
            Show items 'Not Applicable'
            <i *ngIf="(_jobItemService.showNotApplicableItems)" class="material-icons">done</i>
            <i *ngIf="(!_jobItemService.showNotApplicableItems)" class="material-icons">clear</i>
          </button>

          <button *ngIf="isVariationOpen && (variationsWrite)" class="dropdown-item" (click)='addJobItemBoard()'>
            Apply selection board
          </button>
          <button
            *ngIf="selectionsAdmin || (currentVariation && (currentVariation.variationType < 10 || currentVariation.variationType > 20))"
            class="dropdown-item" (click)='getPDF()'>
            Get Variation PDF
          </button>
          <button *ngIf="!isClient && !isAssociate && canSeeAmountsPermission" class="dropdown-item"
            (click)='getSalesQuotePDF(true)'>
            Get Sales Quote
          </button>
          <button *ngIf="currentJob?.salesDate && !isClient && !isAssociate && canSeeAmountsPermission"
            class="dropdown-item" (click)='getSalesQuotePDF(false)'>
            Get Contract Quote
          </button>
          <button *ngIf="(!isClient && !isAssociate) && !currentJob?.isLocked" class="dropdown-item"
            (click)="gotoHouseOptions()">
            House Options
          </button>
          <button *ngIf="(!isClient && !isAssociate)" class="dropdown-item" (click)='getExcelExport()'>
            Export to Excel
          </button>
          <button *ngIf="variationsWrite && isVariationOpen" class="dropdown-item" (click)='moveItemsToNewVariation()'>
            Move items to a new variation
          </button>
          <button *ngIf="isVariationOpen && variationsWrite" class="dropdown-item" (click)='copyFromAnotherVariation()'>
            Copy items from another variation
          </button>
          <button *ngIf="variationsWrite && currentVariation" class="dropdown-item" (click)='clearHighlighting()'>
            Clear all highlighting
          </button>
          <button *ngIf="selectionsAdmin && currentVariation" class="dropdown-item" (click)='checkAll()'>
            Mark All items as checked
          </button>
          <button *ngIf="(!isClient && !isAssociate)" class="dropdown-item" (click)="gotoMaster()">
            Go to Master
          </button>
          <button *ngIf="(!isClient && !isAssociate)" class="dropdown-item" (click)="showJobRoles()">
            Job Roles
          </button>
          <button *ngIf="variationsWrite && currentVariation && currentVariation.statusId === variationStatusEnum.Open"
            class="dropdown-item" (click)='submitToEstimating()'>
            Submit to Estimating
          </button>
          <button
            *ngIf="variationsAdmin && currentVariation && currentVariation.statusId < variationStatusEnum.Cancelled"
            class="dropdown-item" (click)='approveVariation()'>
            Approve Variation
          </button>
          <button *ngIf="selectionsAdmin && currentVariation" class="dropdown-item" (click)='costItemsCheck()'>
            Check Item Costs
          </button>
          <button *ngIf="isConstructiveEnabled && variationsWrite && currentVariation && currentVariation.statusId === variationStatusEnum.Open
           && (currentVariation.variationType < 10 || currentVariation.variationType > 20)" class="dropdown-item"
            (click)='importConstructiveSelections()'>
            Import Constructive Selections
          </button>
          <button *ngIf="isAdminOrSuperUser && currentVariation" class="dropdown-item" (click)='changeVariationType()'>
            Change Type
          </button>
        </div>
      </span>

      <!-- note don't allow clients to filter in a VO as it doesn't work when they are allocated a VO to change -->
      <form *ngIf="!isRestrictedForm && !isClient" [formGroup]="filterForm" novalidate
        class="form-inline settingsbutton">
        <div class="form-group searchRow">

          <button type="button" class="btn btn-sm btn-outline-primary unfoldButton" (click)="collapsePanels()"
            matTooltip="Collapse All" matTooltipPosition="above" [matTooltipShowDelay]="400">
            <i class="material-icons">unfold_less</i>
          </button>
          <button type="button" class="btn btn-sm btn-outline-primary unfoldButton" (click)="openPanels()"
            matTooltip="Expand All" matTooltipPosition="above" [matTooltipShowDelay]="400">
            <i class="material-icons">unfold_more</i>
          </button>

          <label for="filterText" class="col-form-label filterLabelClass"></label>
          <input id="filterText" class="form-control form-control-sm filterTextClass"
            [class.yellowBackground]="_jobItemService.filterText !== ''" type="text" placeholder="Word search..."
            formControlName="filterText" (keyup)="runFilter()" />

          <span *ngIf="!isAssociate && !isClient" ngbDropdown placement="bottom-right">
            <button class="btn-sm btn-outline-primary dropdownHeight31" id="configfilter" ngbDropdownToggle></button>
            <div ngbDropdownMenu aria-labelledby="configfilter">
              <button class="dropdown-item" (click)='filterByPSItems()'>
                PS Items
              </button>
              <button class="dropdown-item" (click)='filterByUnChecked()'>
                Un-Checked Items
              </button>
              <button class="dropdown-item" (click)='filterByUnSelected()'>
                Un-Selected Items
              </button>
              <button *ngIf="!currentJob?.salesDate && !isClient && !isAssociate" class="dropdown-item"
                (click)='filterByUnSelectedByConsultant()'>
                Un-Selected By Consultant
              </button>
              <button *ngIf="currentVariation && currentVariation.canBeModifiedByClient" class="dropdown-item"
                (click)='filterByClientUpdatable()'>
                Items Client can change
              </button>
            </div>
          </span>
          <button type="button" class="btn btn-sm btn-primary" (click)="clearFilter()">Clear</button>
        </div>
      </form>
    </div>
  </div>

  <div class="card-body">

    <!-- Warning Note -->
    <p *ngIf="showWarning && currentJob && currentJob.warningNote" class="warningClass">
      WARNING: {{currentJob.warningNote}}
    </p>

    <!-- Heading for the price change - only when in a VO -->
    <div *ngIf="(jobNum !== '') && (_jobItemService.selectionsMode === 'Variations')
      && _jobItemService.currentVariation && canSeeAmountsPermission">
      <div class="priceheadingclass" [ngStyle]="{'margin-right': 'calc(' + buttonsWidth + 'px)'}">
        <span
          *ngIf="showQty && variationType !== variationTypeEnum.Office && variationType !== variationTypeEnum.PreContractExFromContract"
          class="priceRateClass">Qty</span>
        <div [ngStyle]="{'width': 'calc(' + PRICE_WIDTH + 'px)'}" class="priceRateClass">Price</div>
      </div>
    </div>

    <!-- Loading Spinner -->
    <js-loading-spinner *ngIf="loading || loadingJob || loadingOptions"></js-loading-spinner>

    <div #card *ngIf="jobNum !== '' && !loading && !loadingJob && !loadingOptions" class="scroll"
      [style.height.px]="scrollHeight">
      <div *ngIf="(!_jobItemService.currentJobItems || !_jobItemService.currentJobItems.length)
        && (_jobItemService.selectionsMode === 'Master' || currentVariation)" class="marginleft5">
        No items exist.
        <span
          *ngIf="_jobItemService.filterText !== '' || _jobItemService.filterByNotSelected
        || _jobItemService.filterByUnChecked || _jobItemService.filterByPSItems || _jobItemService.filterByClientUpdatable"
          class="redNote">
          Filter is on
        </span>
      </div>

      <!-- We will assume that first level only contains headers !! and so the accordian panels work! -->
      <div *ngIf='_jobItemService.currentJobItems && _jobItemService.currentJobItems.length'>
        <!-- start the accordian panel -->
        <ngb-accordion [closeOthers]="closeOtherPanels" [animation]="false" #acc="ngbAccordion" #ngbAccordion
          class="titleClass">
          <ngb-panel *ngFor='let jobitem of _jobItemService.currentSectionHeadings' id="acc-{{jobitem.id}}">
            <ng-template ngbPanelTitle
              *ngIf="(jobitem.isHistoryRecord) && (_jobItemService.showHistoryOfChanges || jobitem.changedByVO === variationNumber)">
              <div class="upDownArrow">
                <i *ngIf="isOpenPanel(acc, jobitem.id)" class="material-icons">keyboard_arrow_up</i>
                <i *ngIf="!isOpenPanel(acc, jobitem.id)" class="material-icons">keyboard_arrow_down</i>
              </div>
              <div class="titleBox" [ngStyle]="{'max-width': 'calc(' + headingTextWidth + 'px)'}">
                <span class="strikeclass leftClass">
                  {{jobitem.itemDescription}}</span>
                <span *ngIf="(jobitem.changedByVO !== variationNumber) && (jobitem.changeTypeId === 1)"
                  class="normalclass leftClass">:
                  Changed in Vn {{jobitem.changedByVO}}</span>
                <span *ngIf="(jobitem.changedByVO !== variationNumber) && (jobitem.changeTypeId === 2)"
                  class="normalclass leftClass">:
                  Deleted in Vn {{jobitem.changedByVO}}</span>
                <button *ngIf="showAddEditButtons && jobitem.changeTypeId === changeTypeEnum.Delete"
                  class='btn btn-sm btn-outline-primary titleBtn buttonGroup'
                  (click)='$event.preventDefault(); $event.stopPropagation(); editItem(jobitem)'>
                  <i class="material-icons md-18">delete_forever</i>
                </button>
              </div>
            </ng-template>

            <ng-template ngbPanelTitle *ngIf="!(jobitem.isHistoryRecord)">
              <div class="custom-header">
                <div class="upDownArrow">
                  <i *ngIf="isOpenPanel(acc, jobitem.id)" class="material-icons">keyboard_arrow_up</i>
                  <i *ngIf="!isOpenPanel(acc, jobitem.id)" class="material-icons">keyboard_arrow_down</i>
                </div>
                <div class="titleBox" [ngStyle]="{'max-width': 'calc(' + headingTextWidth + 'px)'}">
                  <!-- <div class="titleBox"> -->
                  <span *ngIf="(jobitem.isSetUpLine)" class="setupclass">
                    {{jobitem.itemDescription}}</span>
                  <span *ngIf="!(jobitem.isSetUpLine)" class="normalclass">
                    {{jobitem.itemDescription}}
                    <span *ngIf="jobitem.isDoNotPrint" class='notPrintedClass'>
                      Not printed on {{addendumName}}
                    </span>
                    <span
                      *ngIf="_jobItemService.filterByClientUpdatable && doesHeaderHaveMissingClientSelections(jobitem)"
                      class="selections-required m-l-10">
                      Selections Required
                    </span>
                  </span>
                </div>

                <!-- only show edit buttons if job is NOT locked -->
                <span *ngIf="showAddEditButtons" class="buttonGroup">
                  <span *ngIf="jobitem.isDeleted">hidden</span>
                  <button *ngIf="_jobItemService.selectionsMode === 'Master'"
                    class='btn btn-sm btn-outline-primary btnline titleBtn'
                    (click)="$event.preventDefault(); $event.stopPropagation(); addItem(jobitem.orderNo + 1)">
                    <i class="material-icons">add</i>
                  </button>

                  <!-- add a var item line after this one -->
                  <button *ngIf="_jobItemService.selectionsMode === 'Variations'"
                    class='btn btn-sm btn-outline-primary btnline titleBtn'
                    (click)="$event.preventDefault(); $event.stopPropagation(); addVarItem(jobitem, jobitem.orderNo)">
                    <i class="material-icons">add</i>
                  </button>

                  <button class='btn btn-sm btn-outline-primary btnline titleBtn'
                    (click)='$event.preventDefault(); $event.stopPropagation(); editItem(jobitem)'>
                    <i class="material-icons">create</i>
                  </button>

                  <button *ngIf="showUpDownButtons" class='btn btn-sm btn-outline-primary btnline titleBtn'
                    (click)='$event.preventDefault(); $event.stopPropagation(); downItem(jobitem)'>
                    <i class="material-icons">arrow_downward</i>
                  </button>

                  <button *ngIf="showUpDownButtons" class='btn btn-sm btn-outline-primary btnline titleBtn'
                    (click)='$event.preventDefault(); $event.stopPropagation(); upItem(jobitem)'>
                    <i class="material-icons">arrow_upward</i>
                  </button>
                </span>
              </div>
            </ng-template>

            <ng-template ngbPanelContent *ngIf="(!jobitem.isHistoryRecord || _jobItemService.showHistoryOfChanges)">
              <!-- If heading then find any more levels below recursively -->
              <js-selection-heading [showAddEditButtons]=showAddEditButtons [iJobItem]=jobitem
                [variationNumber]=variationNumber [headingLevel]=0 [showImages]=showImages
                [isParentHiddenFromMaster]=jobitem.isHiddenFromMaster [selectionsAdmin]=selectionsAdmin
                [variationType]=variationType [jobLineSetupAdmin]=jobLineSetupAdmin [isVariationOpen]=isVariationOpen
                [headingIsSetUpLine]=jobitem.isSetUpLine [showNumbering]=showNumbering
                [itemsChangedFlag]="itemsChangedFlag">
              </js-selection-heading>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>

</div>

<div #footer *ngIf="(jobNum !== '') && !loading && !loadingJob && !loadingOptions && footerHeight
  && (_jobItemService.selectionsMode === 'Variations' || innerWidth >= SHOW_QTY_AT_SIZE)"
  class="card-footer footerClass" [style.height.px]="footerHeight">

  <!-- only show button if job is NOT locked -->
  <span *ngIf="!currentJob.masterJobId || !currentJob.useMasterJobAddenda">
    <button
      *ngIf="(showAddEditButtons || (!_jobItemService.isLocked && jobLinesSetupOnlyAdmin)) && _jobItemService.selectionsMode === 'Master'"
      class='btn btn-sm btn-outline-primary btnadd' (click)='addItem(0)'>Add</button>

    <!-- in a variation we don't want the Add button so we don't accidentily add a whole template to the bottom -->
    <button *ngIf="(showAddEditButtons) && _jobItemService.selectionsMode === 'Variations'
      && (!_jobItemService.currentJobItemsUnfiltered || !_jobItemService.currentJobItemsUnfiltered.length)"
      class='btn btn-sm btn-outline-primary btnadd' (click)='addVarItem(null, 0)'>Add</button>

    <button *ngIf="(innerWidth >= SHOW_QTY_AT_SIZE) && jobNum !== '' && _jobItemService.selectionsMode === 'Variations'
        && (currentJob?.salesDate || jobLineSetupAdmin)" class='btn btn-sm btn-outline-primary btnadd'
      (click)='gotoMaster()'>
      Go to Master
    </button>
  </span>

  <span *ngIf="isClient && _jobItemService.selectionsMode === 'Variations'
      && currentVariation.statusId === variationStatusEnum.Open && currentVariation.canBeModifiedByClient">
    <button *ngIf="_jobItemService.clientItemsSelected ===_jobItemService.clientItemsToSelectTotal"
      class='btn btn-sm btn-success m-t-5 m-l-5' (click)='submitToEstimating()'>
      Submit Selections
    </button>
    <button *ngIf="_jobItemService.clientItemsSelected !==_jobItemService.clientItemsToSelectTotal" disabled
      class='btn btn-sm btn-danger m-t-5 m-l-5'>
      {{_jobItemService.clientItemsSelected}} of {{_jobItemService.clientItemsToSelectTotal}} Selected
    </button>
  </span>

  <button *ngIf="(innerWidth >= SHOW_QTY_AT_SIZE) && jobNum !== '' && _jobItemService.selectionsMode === 'Master'
    && (currentJob?.salesDate || jobLineSetupAdmin)" class="btn btn-sm btn-primary btnQuote" (click)='getPDF()'>
    {{addendumName}} PDF
  </button>

  <button *ngIf="showQty && !currentJob?.contractSignedDate && _jobItemService.selectionsMode === 'Variations'
    && !currentJob?.salesDate && canSeeAmountsPermission" class="btn btn-sm btn-primary btnQuote"
    (click)='getSalesQuotePDF(true)'>
    Quote PDF
  </button>

  <button *ngIf="showQty && _jobItemService.selectionsMode === 'Variations'
    && currentJob?.salesDate" class="btn btn-sm btn-primary btnQuote" (click)='getPDF()'>
    Variation PDF
  </button>

  <!-- Summary for the price change - only when in a VO -->
  <div *ngIf="_jobItemService.selectionsMode === 'Variations'
      && (canSeeAmountsPermission || (isClient && _jobItemService.selectionsMode === 'Variations'
          && currentVariation.statusId === variationStatusEnum.Open && currentVariation.canBeModifiedByClient))
      && _jobItemService.currentVariation
      && variationType !== variationTypeEnum.Office
      && variationType !== variationTypeEnum.PreContractExFromContract
      && !isAssociate
      && (!_jobItemService.isTBARecords || innerWidth > 400)" class="priceFooterClass buttonGroup"
    [ngStyle]="{'margin-right': 'calc(' + footerMarginRight + 'px)'}">
    Total:
    <span *ngIf='_jobItemService.isTBARecords'>(Ex. TBA items)</span>
    &nbsp;&nbsp;&nbsp;
    <span *ngIf="(!_jobItemService.variationTotal || (_jobItemService.variationTotal === 0))">
      -
    </span>
    <span *ngIf="(_jobItemService.variationTotal && (_jobItemService.variationTotal >= 0))">
      <b>${{_jobItemService.variationTotal | number}}</b>
    </span>
    <span *ngIf="(_jobItemService.variationTotal && (_jobItemService.variationTotal < 0))">
      <span class="redClass"><b>${{_jobItemService.variationTotal | number}}</b></span>
    </span>
  </div>

</div>
